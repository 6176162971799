// only specify permissions for user roles with limited access
// unspecified user roles have access to all pages
// TBD: get permissions object from

import {
  ROUTE_DISCOUNT_CODE,
  ROUTE_NUMBER_DASHBOARD,
  ROUTE_ORDERS,
  ROUTE_USER_MANAGEMENT,
  ROUTE_COURSES,
  ROUTE_LESSONS,
  ROUTE_LESSON_PAGE,
  ROUTE_LESSON_MANAGEMENT,
  ROUTE_LESSON_DETAIL_ZOOM,
  ROUTE_LESSON_DETAIL_TENOPY_CLASSROOM,
  ROUTE_TLS,
  ROUTE_FEEDBACK,
  ROUTE_TICKETS_TRIAL,
  ROUTE_QUESTION_BANK
} from 'routes/paths'

export const checkActionPermitted = function (permissions, userRole, path, action) {
  if (Object.keys(permissions).includes(userRole)) {
    const allowedPaths = permissions[userRole].allowedPaths
    if (Object.keys(allowedPaths).includes(path)) {
      const allowedActions = allowedPaths[path]
      if (Object.keys(allowedActions).includes(action)) {
        return allowedActions[action]
      }
    }
  }
  return true
}

export default {
  DataAdmin: {
    allowedPaths: {
      [ROUTE_NUMBER_DASHBOARD]: {}
    }
  },
  SalesAdmin: {
    allowedPaths: {
      [ROUTE_NUMBER_DASHBOARD]: {},
      [ROUTE_DISCOUNT_CODE]: { canCreate: false },
      [ROUTE_ORDERS]: {
        canCreateOrder: true,
        allowedOrderType: ['TRIAL'],
        canCreateOrderByCSV: false,
        canCreateNormal: false,
        canCreateTrial: true,
        canCancelTrial: true,
        canCancelPurchase: false,
        canUpdateOrder: false
      },
      [ROUTE_USER_MANAGEMENT]: { canManage: false },
      [ROUTE_LESSON_MANAGEMENT]: { canManage: false, canSendNotification: false },
      [ROUTE_LESSON_DETAIL_ZOOM]: { canManagePoll: false },
      [ROUTE_LESSON_DETAIL_TENOPY_CLASSROOM]: { canManagePoll: false },
      [ROUTE_TICKETS_TRIAL]: {},
      [ROUTE_TLS]: { canCreate: false, canUpdate: false, canDelete: false }
    }
  },
  Partner: {
    allowedPaths: {
      [ROUTE_ORDERS]: {
        canCreateOrder: true,
        allowedOrderType: ['TRIAL'],
        canCreateOrderByCSV: false,
        canCreateNormal: false,
        canCreateTrial: true,
        canCancelTrial: true,
        canCancelPurchase: false,
        canUpdateOrder: false
      },
      [ROUTE_USER_MANAGEMENT]: {
        canManage: false,
        canManageCredit: false,
        showOnly: 'Oxkids' // NOTE: hard code for now, because oxkids is the onnly one need this
      },
      [ROUTE_LESSON_MANAGEMENT]: { canManage: false, canSendNotification: false },
      [ROUTE_LESSON_DETAIL_ZOOM]: { canManagePoll: false },
      [ROUTE_LESSON_DETAIL_TENOPY_CLASSROOM]: { canManagePoll: false }
    }
  },
  Agent: {
    allowedPaths: {
      [ROUTE_ORDERS]: {
        canCreateOrder: false,
        canViewAllOrders: false,
        canCancelTrial: true,
        canCancelPurchase: false,
        canUpdateOrder: false
      }
    }
  },
  CustService: {
    allowedPaths: {
      [ROUTE_NUMBER_DASHBOARD]: { canViewClassEnrolment: false, canViewTrialStat: false },
      [ROUTE_ORDERS]: { canCreateOrder: false, canCancelTrial: false, canCancelPurchase: false, canUpdateOrder: false },
      [ROUTE_USER_MANAGEMENT]: { canManage: true },
      [ROUTE_COURSES]: { canManage: false },
      [ROUTE_LESSONS]: { canManage: false },
      [ROUTE_LESSON_PAGE]: { canManage: false, canSendNotification: false },
      [ROUTE_LESSON_MANAGEMENT]: { canManage: false, canSendNotification: false },
      [ROUTE_LESSON_DETAIL_ZOOM]: { canManagePoll: true },
      [ROUTE_LESSON_DETAIL_TENOPY_CLASSROOM]: { canManagePoll: true },
      [ROUTE_TLS]: { canCreate: false, canUpdate: false, canDelete: false },
      [ROUTE_FEEDBACK]: {},
      [ROUTE_QUESTION_BANK]: {}
    }
  },
  Typist: {
    allowedPaths: {
      [ROUTE_QUESTION_BANK]: {}
    }
  }
}
