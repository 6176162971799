import React from 'react'
import Pluralize from 'react-pluralize'
import { Button, Card } from '@material-ui/core'
import _ from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'

class OrderItem extends React.Component {
  render() {
    const { order, canCancelTrial, canCancelPurchase, currentUser, canUpdateOrder } = this.props

    if (!order) {
      return null
    }

    let lessonList = order.detail.lessonList
    let numberOfLessons = lessonList.length
    let billable = !!order.detail.billable_lesson ? order.detail.billable_lesson : []
    let unbilled = _.difference(order.detail.lesson, billable)
    let inCol = Math.ceil(numberOfLessons / 3)
    lessonList = _.orderBy(lessonList, ['scheduled_on'], ['asc'])

    let chunkedLessons = _.chunk(lessonList, inCol)

    let payBy = ''
    if (order.payment) {
      if (order.payment.channel === 'offline') {
        payBy = 'Bank Transfer'
      } else {
        payBy = order.payment.platform.replaceAll('-', ' ')
      }
    }

    let allowCancel = false
    let allowUpdate = false
    if (order.type === 'PAYMENT' || order.type === 'PAYMENT_VIDEO') {
      if (canCancelPurchase) {
        allowCancel = true
      }

      if (canUpdateOrder) {
        if (!order.meta?.is_renewal || !order.agent) {
          allowUpdate = true
        }
      }
    } else if (order.type === 'TRIAL') {
      if (canCancelTrial) {
        allowCancel = true
      }
    }

    return (
      <Card className="p-3 mb-2">
        <div className="row">
          <div className="col-md-4 d-flex flex-column">
            <div className="mb-3">
              {currentUser._id === 'MxzTuxI-TAeJi1Yg0_6drg' && <div>{order._id}</div>}
              <div className="font-weight-semibold">{moment(order.created).format('D MMM YYYY, ddd, h:mma')}</div>
              <div className="text-capitalize">
                {order.type === 'PAYMENT' && (
                  <>
                    <span className={'d-inline-block px-3 border rounded text-muted mr-2'}>
                      {order.type.toLowerCase()}
                    </span>
                    {order.meta?.is_renewal && (
                      <span
                        className={'d-inline-block px-3 rounded mr-2'}
                        style={{ border: '1px solid #673ab7', color: '#673ab7' }}>
                        Renewal
                      </span>
                    )}
                    {payBy && (
                      <span className={'d-inline-block px-3 border rounded border-success text-success mr-2'}>
                        {payBy}
                      </span>
                    )}
                  </>
                )}
                {order.type === 'TRIAL' && (
                  <span className={'d-inline-block px-3 border rounded border-warning text-warning mr-2'}>
                    {order.type.toLowerCase()}
                  </span>
                )}
                {order.type === 'PAYMENT_VIDEO' && (
                  <span className={'d-inline-block px-3 border rounded border-info text-info mr-2'}>
                    {order.type.toLowerCase()}
                  </span>
                )}
              </div>
              <div
                className={
                  order.status === 'PROCESSED'
                    ? 'p-2 mt-3 text-center font-weight-semibold text-white bg-success'
                    : order.status === 'CANCELLED'
                    ? 'p-2 mt-3 text-center font-weight-semibold bg-light'
                    : 'p-2 mt-3 text-center font-weight-semibold text-white bg-danger'
                }>
                {order.status}
              </div>
              {order.payment?.completed_on && (
                <div className="text-muted">Paid on: {moment(order.payment.completed_on).format('D MMM YYYY')}</div>
              )}
              {order.type !== 'TRIAL' && (
                <div className="row my-3">
                  <div className="col-auto">
                    <span>$</span>
                  </div>
                  <div className="col-auto">
                    <div style={{ fontSize: '2rem' }}>{order.actual_price}</div>
                  </div>
                  {order.actual_price !== order.original_price && (
                    <div className="col-auto">
                      <div className="ml-3">
                        <div className="text-danger">
                          {Math.round(((order.original_price - order.actual_price) / order.original_price) * 100)}%
                        </div>
                        <div className="text-muted">
                          <del>${order.original_price}</del>
                        </div>
                      </div>
                      {!!order.detail && !!order.detail.discount && order.detail.discount.hasOwnProperty('code') && (
                        <div className="col-12 font-weight-semibold text-center">
                          (Promo Code: {order.detail.discount.code})
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>

            {order.remark && <div className="border-top border-bottom py-2">{order.remark}</div>}

            <div className="text-center mt-auto">
              <Button variant="text" size="small" color="primary" onClick={this.props.triggerUpdateOrder}>
                Update
              </Button>

              {order.status !== 'CANCELLED' && allowCancel && (
                <Button variant="outlined" onClick={this.props.triggerCancelOrder} fullWidth>
                  Cancel Order
                </Button>
              )}
            </div>
          </div>
          <div className="col-md-8">
            <Card className="p-3 mb-3 rounded-top-">
              <div className="text-muted">
                <small>Name</small>
              </div>
              <h3 className="font-weight-semibold mb-1">{order.created_by ? order.created_by.name : 'N/A'}</h3>
              <div className="row">
                <div className="col">
                  <div className="text-muted">
                    <small>Email</small>
                  </div>
                  {order.created_by ? order.created_by.email : 'NULL'}
                </div>
                <div className="col">
                  <div className="text-muted">
                    <small>Phone</small>
                  </div>
                  (+{order.created_by ? order.created_by.phone_country_code : 'NULL'})&nbsp;
                  {order.created_by ? order.created_by.phone : 'NULL'}
                </div>
                {order.created_by.lead_source && (
                  <div className="col">
                    <div className="text-muted">
                      <small>From</small>
                    </div>
                    {order.created_by.lead_source.source === 'Oxkids' ? (
                      'Oxkids'
                    ) : (
                      <>
                        {order.created_by.lead_source.source} - {order.created_by.lead_source.medium} (
                        {order.created_by.lead_source.campaign})
                        {order.created_by.lead_source.meta && (
                          <div>
                            {Object.entries(order.created_by.lead_source.meta).map(([key, value]) => {
                              return (
                                <div key={key}>
                                  <span className="text-muted">{key}:</span> {value.toString()}
                                </div>
                              )
                            })}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}
                {order.created_by.referred_by && (
                  <div className="col">
                    <div className="text-muted">
                      <small>Referrer</small>
                    </div>
                    {order.created_by.referred_by.name} ({order.created_by.referred_by.email})
                  </div>
                )}
                {order.agent && (
                  <div className="col">
                    <div className="text-muted">
                      <small>Agent</small>
                    </div>
                    {order.agent.name}
                  </div>
                )}
              </div>
            </Card>

            <div>
              <Pluralize singular={'lesson'} count={numberOfLessons} />
              {billable.length > 0 && <small className="text-success ml-2">({billable.length} Paid)</small>}
            </div>
            <h3>{order.detail.class_obj.name}</h3>
            <div className="row text-muted">
              {chunkedLessons.map((chunk, index) => {
                return (
                  <div key={index} className="col-md-4 border-right">
                    {chunk.map(lesson => {
                      return (
                        <div key={lesson._id} className="mb-2">
                          {lesson.name}
                          {unbilled.includes(lesson._id) && (
                            <span className={'d-inline-block px-3 border rounded border-warning text-warning ml-2'}>
                              Trial
                            </span>
                          )}
                          <div>
                            <small>{moment(lesson.scheduled_on).format('ddd h:ma, D MMM YYYY')}</small>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </Card>
    )
  }
}

OrderItem.propTypes = {
  order: PropTypes.object.isRequired
}

export default OrderItem
