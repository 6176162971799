import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormHelperText,
  IconButton,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Toolbar,
  Typography
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import _ from 'lodash'
import moment from 'moment'

import agent from 'agent'
import { CREATE_USER, UPDATE_USER } from 'constants/actionTypes'
import CKEditor from 'react-ckeditor-component'
import educationLevels from 'assets/education-level.json'

const mapStateToProps = state => ({
  isLoading: state.user.isLoading,
  error: state.user.error,
  userCreated: state.user.userCreated,
  userUpdated: state.user.userUpdated
})

const mapDispatchToProps = dispatch => ({
  createUser: userObj => dispatch({ type: CREATE_USER, payload: agent.User.createUser(userObj) }),
  updateUser: (userType, userId, userObj) =>
    dispatch({ type: UPDATE_USER, payload: agent.User.updateUser(userType, userId, userObj) })
})

class DialogUserEdit extends React.Component {
  state = {
    email: '',
    name: '',
    password: '',
    dateOfBirth: '',
    gender: 'nil',
    phoneCountryCode: 65,
    phone: '',
    level: '',
    othersLevel: '',
    showOthersLevel: false,
    school: '',
    firstLanguage: '',
    parentSalute: 'Mr',
    parentName: '',
    parentEmail: '',
    parentPhoneCountryCode: '',
    parentPhone: '',
    contactWhatsapp: false,
    contactWechat: false,
    contactWechatId: '',
    contactOthers: false,
    contactOthersChannel: '',
    studentBackground: '',
    profile_pic: '',
    desc: '',
    subject: '',
    year_of_exp: '',
    track_record: ''
  }

  componentDidMount() {
    const { userData } = this.props
    if (userData) {
      const { parent } = userData
      let displayLevel = '',
        showOthersLevel = false,
        othersLevel = ''
      if (userData.level) {
        let levelObj = _.find(educationLevels, function (o) {
          return o.code === userData.level
        })
        if (levelObj) {
          displayLevel = levelObj.code
        } else {
          showOthersLevel = true
          othersLevel = userData.level
          displayLevel = 'others'
        }
      }

      let parentPhoneCountryCode = 65
      if (parent?.phone_country_code) {
        parentPhoneCountryCode = parent.phone_country_code
      } else {
        parentPhoneCountryCode = userData.phone_country_code
      }

      this.setState({
        email: userData.email,
        name: userData.name ?? '',
        dateOfBirth: userData.date_of_birth ? moment(userData.date_of_birth).startOf('day').format('YYYY-MM-DD') : '',
        gender: userData.gender ?? 'nil',
        phoneCountryCode: userData.phone_country_code,
        phone: userData.phone ?? '',
        level: displayLevel,
        othersLevel,
        showOthersLevel,
        school: userData.school ?? '',
        firstLanguage: userData.first_language ?? '',
        parentSalute: parent?.salute ?? 'Mr',
        parentName: parent?.name ?? '',
        parentEmail: parent?.email ?? '',
        parentPhoneCountryCode,
        parentPhone: parent?.phone ?? '',
        contactWhatsapp: parent?.contact_preference?.whatsapp ? true : false,
        contactWechat: parent?.contact_preference?.wechat ? true : false,
        contactWechatId: parent?.contact_preference?.wechat_id ?? '',
        contactOthers: parent?.contact_preference?.others ? true : false,
        contactOthersChannel: parent?.contact_preference?.others ?? '',
        studentBackground: userData.meta?.background ?? '',
        profile_pic: userData.profile_pic ?? '',
        desc: userData.desc ?? '',
        subject: userData.subject ? userData.subject.join(', ') : '',
        year_of_exp: userData.credential?.year_of_exp ?? 0,
        track_record: userData.credential?.track_record ? userData.credential.track_record.join(', ') : ''
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLoading && !this.props.isLoading && (this.props.userCreated || this.props.userUpdated)) {
      this.props.onSave()
    }
  }

  handleChange = event => {
    const type = event.target.name
    const value = event.target.value
    this.setState({ [type]: value })

    if (type === 'level') {
      if (value === 'others') {
        this.setState({ showOthersLevel: true })
      } else {
        this.setState({ showOthersLevel: false })
      }
    }
  }

  handleCKEditorChange = targetName => event => {
    this.setState({
      [targetName]: event.editor.getData()
    })
  }

  saveChanges = () => {
    const { userData } = this.props
    const {
      email,
      name,
      password,
      dateOfBirth,
      gender,
      phoneCountryCode,
      phone,
      level,
      othersLevel,
      school,
      firstLanguage,
      parentSalute,
      parentName,
      parentEmail,
      parentPhoneCountryCode,
      parentPhone,
      contactWhatsapp,
      contactWechat,
      contactWechatId,
      contactOthersChannel,
      studentBackground,
      profile_pic,
      desc,
      subject,
      year_of_exp,
      track_record
    } = this.state

    let userDoc = {
      name,
      email,
      phone_country_code: phoneCountryCode,
      phone
    }

    if (password) {
      userDoc.password = password
    }

    if (userData?._type === 'Student') {
      userDoc.gender = gender
      userDoc.date_of_birth = moment(dateOfBirth).startOf('day').toISOString()
      userDoc.level = level === 'others' ? othersLevel : level
      userDoc.school = school
      userDoc.first_language = firstLanguage
      userDoc.parent = {
        salute: parentSalute,
        name: parentName,
        email: parentEmail,
        phone_country_code: parentPhoneCountryCode,
        phone: parentPhone,
        contact_preference: {
          whatsapp: contactWhatsapp,
          wechat: contactWechat,
          wechat_id: contactWechatId ?? '',
          other: contactOthersChannel ?? ''
        }
      }

      if (studentBackground) {
        userDoc['meta'] = {
          background: studentBackground
        }
      }
    } else if (userData?._type === 'Tutor' || !userData) {
      let subjectArray = !!subject.trim()
        ? subject.split(',').map(function (item) {
            return item.trim()
          })
        : []

      let trackArray = !!track_record.trim()
        ? track_record.split(',').map(function (item) {
            return item.trim()
          })
        : []

      userDoc.profile_pic = profile_pic
      userDoc.desc = desc
      userDoc.subject = subjectArray
      userDoc.credential = {
        year_of_exp,
        track_record: trackArray
      }
      userDoc._type = 'Tutor'
    }

    if (userData?._id) {
      this.props.updateUser(userData._type, userData._id, userDoc)
    } else {
      this.props.createUser(userDoc)
    }
  }

  render() {
    const { isOpen, toggle, userData, isLoading } = this.props
    const {
      name,
      email,
      dateOfBirth,
      gender,
      password,
      phoneCountryCode,
      phone,
      level,
      othersLevel,
      showOthersLevel,
      school,
      firstLanguage,
      parentEmail,
      parentSalute,
      parentName,
      parentPhoneCountryCode,
      parentPhone,
      contactWhatsapp,
      contactWechat,
      contactWechatId,
      contactOthers,
      contactOthersChannel,
      studentBackground,
      profile_pic,
      desc,
      subject,
      year_of_exp,
      track_record
    } = this.state

    const toolbarGroups = [
      { name: 'basicstyles', groups: ['basicstyles', 'Underline'] },
      { name: 'paragraph', groups: ['list', 'align', 'bidi', 'links'] }
    ]

    const CkConfig = {
      toolbarGroups
    }

    return (
      <Dialog disableEnforceFocus open={isOpen} onClose={toggle} fullScreen={true}>
        <AppBar className="position-relative">
          <Toolbar>
            <IconButton onClick={toggle} aria-label="Close" className="text-white">
              <CloseIcon />
            </IconButton>
            <Typography
              type="title"
              color="inherit"
              style={{
                flex: 1
              }}>
              {userData ? 'Edit User' : 'Add New Tutor'}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ padding: '24px' }}>
          <div className="row">
            <div className="col-md">
              <Paper elevation={2} className={'app-wrapper mb-3'}>
                <FormControl className={'mb-3 w-100'}>
                  <TextField label={'Name'} name={'name'} value={name} onChange={this.handleChange} />
                </FormControl>
                <FormControl className={'mb-3 w-100'}>
                  <TextField label={'Email'} name={'email'} value={email} onChange={this.handleChange} />
                </FormControl>
                <FormControl className={'mb-3 w-100'}>
                  <TextField label={'Password'} name={'password'} value={password} onChange={this.handleChange} />
                </FormControl>
                <FormControl className={'mb-3 w-100'}>
                  <div className="row">
                    <div className="col-2">
                      <TextField
                        type={'tel'}
                        label={'Country Code'}
                        name={'phoneCountryCode'}
                        value={phoneCountryCode}
                        onChange={this.handleChange}
                        fullWidth
                      />
                    </div>
                    <div className="col-10">
                      <TextField
                        type={'tel'}
                        label={'Phone'}
                        name={'phone'}
                        value={phone}
                        onChange={this.handleChange}
                        fullWidth
                      />
                    </div>
                  </div>
                </FormControl>
              </Paper>
            </div>

            {userData?._type === 'Student' && (
              <div className="col-12">
                <Paper elevation={2} className={'app-wrapper mb-3'}>
                  <div className="row">
                    <div className="col-auto mb-3">
                      <FormControl>
                        <FormLabel component="legend">Gender</FormLabel>
                        <RadioGroup row aria-label="gender" name="gender" value={gender} onChange={this.handleChange}>
                          <FormControlLabel value="f" control={<Radio color="primary" />} label="Female" />
                          <FormControlLabel value="m" control={<Radio color="primary" />} label="Male" />
                        </RadioGroup>
                      </FormControl>
                    </div>
                    <div className="col-auto mb-3">
                      <FormControl>
                        <FormLabel component="legend">Date of birth</FormLabel>
                        <TextField type="date" name="dateOfBirth" onChange={this.handleChange} value={dateOfBirth} />
                      </FormControl>
                    </div>
                    <div className="col-6 mb-3">
                      <FormControl className="w-100">
                        <FormLabel component="legend">First language</FormLabel>
                        <TextField
                          type="text"
                          name="firstLanguage"
                          onChange={this.handleChange}
                          value={firstLanguage}
                          fullWidth
                        />
                      </FormControl>
                    </div>
                    <div className="col-6 mb-3">
                      <FormLabel component="legend">School</FormLabel>
                      <TextField
                        className="w-100 mb-3"
                        type="text"
                        name="school"
                        onChange={this.handleChange}
                        value={school}
                      />
                    </div>
                    <div className="col-6 mb-3">
                      <div className="d-flex align-items-center">
                        <FormControl>
                          <FormLabel component="legend">Level</FormLabel>
                          <Select value={level} onChange={this.handleChange} name="level">
                            {educationLevels.map(level => {
                              return (
                                <MenuItem key={level.code} value={level.code}>
                                  {level.name}
                                </MenuItem>
                              )
                            })}
                          </Select>
                          <FormHelperText>Grade/ Level in School (as of January 2021)</FormHelperText>
                        </FormControl>

                        {showOthersLevel && (
                          <TextField
                            type="text"
                            placeholder="Please specify other level"
                            name="othersLevel"
                            onChange={this.handleChange}
                            defaultValue={othersLevel}
                            className="ml-2"
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <hr />

                  <div className="row align-items-center">
                    <div className="col-auto">
                      <Select value={parentSalute} onChange={this.handleChange} className="mt-0" name="parentSalute">
                        <MenuItem value="Mr">Mr</MenuItem>
                        <MenuItem value="Mrs">Mrs</MenuItem>
                        <MenuItem value="Ms">Ms</MenuItem>
                      </Select>
                    </div>
                    <div className="col">
                      <FormControl className="mb-3 w-100">
                        <TextField
                          label="Parent Name"
                          name="parentName"
                          value={parentName}
                          onChange={this.handleChange}
                        />
                      </FormControl>
                    </div>
                  </div>

                  <FormControl className="mb-3 w-100">
                    <TextField
                      label="Parent Email"
                      name="parentEmail"
                      value={parentEmail}
                      onChange={this.handleChange}
                    />
                  </FormControl>
                  <FormControl className="mb-3 w-100">
                    <div className="row">
                      <div className="col-2">
                        <TextField
                          type="tel"
                          label="Country Code"
                          name="parentPhoneCountryCode"
                          value={parentPhoneCountryCode}
                          onChange={this.handleChange}
                          fullWidth
                        />
                      </div>
                      <div className="col-10">
                        <TextField
                          type="tel"
                          label="Parent Phone"
                          name="parentPhone"
                          value={parentPhone}
                          onChange={this.handleChange}
                          fullWidth
                        />
                      </div>
                    </div>
                  </FormControl>

                  <div className="row">
                    <div className="col">
                      <FormLabel component="legend">Contact Preference</FormLabel>
                    </div>
                    <div className="col-12 ml-3">
                      <div className="row">
                        <div className="col-12">
                          <FormControlLabel
                            control={
                              <Radio
                                color="primary"
                                checked={contactWhatsapp}
                                onChange={() => {
                                  this.setState({
                                    contactWhatsapp: true,
                                    contactWechat: false,
                                    contactOthers: false
                                  })
                                }}
                              />
                            }
                            label="Whatsapp"
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-auto">
                          <FormControlLabel
                            control={
                              <Radio
                                color="primary"
                                checked={contactWechat}
                                onChange={() => {
                                  this.setState({
                                    contactWhatsapp: false,
                                    contactWechat: true,
                                    contactOthers: false
                                  })
                                }}
                              />
                            }
                            label="Wechat"
                          />
                        </div>
                        <div className="col-md-3 col-6">
                          <TextField
                            type="text"
                            placeholder="Wechat ID"
                            name="contactWechatId"
                            onChange={this.handleChange}
                            fullWidth
                            defaultValue={contactWechatId}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-auto">
                          <FormControlLabel
                            control={
                              <Radio
                                color="primary"
                                checked={contactOthers}
                                onChange={() => {
                                  this.setState({
                                    contactWhatsapp: false,
                                    contactWechat: false,
                                    contactOthers: true
                                  })
                                }}
                              />
                            }
                            label="Other"
                          />
                        </div>
                        <div className="col-md-3 col-6">
                          <TextField
                            type="text"
                            placeholder="e.g. phone call, SMS, e-mail"
                            name="contactOthersChannel"
                            onChange={this.handleChange}
                            fullWidth
                            defaultValue={contactOthersChannel}
                            className="mt-0 mb-2"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Paper>

                <Paper elevation={2} className="app-wrapper mb-3">
                  <FormLabel component="legend">Student Background</FormLabel>
                  <FormControl className="w-100">
                    <CKEditor
                      name="studentBackground"
                      content={studentBackground}
                      config={CkConfig}
                      events={{
                        change: this.handleCKEditorChange('studentBackground')
                      }}
                    />
                  </FormControl>
                </Paper>
              </div>
            )}

            {(!userData || userData._type === 'Tutor') && (
              <div className="col-md">
                <Paper elevation={2} className={'app-wrapper mb-3'}>
                  <div className="row">
                    <div className="col">
                      <FormControl className={'mb-3 w-100'}>
                        <TextField
                          label={'Profile Picture'}
                          name={'profile_pic'}
                          value={profile_pic}
                          onChange={this.handleChange}
                          helperText={'Upload the image to S3 first and then paste the HTTPS link here.'}
                        />
                      </FormControl>
                      <FormControl className="mb-3 w-100">
                        <TextField
                          label={'Subjects taught'}
                          name={'subject'}
                          value={subject}
                          onChange={this.handleChange}
                          helperText={'Separate multiple subjects with commas e.g. English, Mathematics'}
                        />
                      </FormControl>
                      <FormControl className="mb-3 w-100">
                        <TextField
                          label={'Years of experience'}
                          name={'year_of_exp'}
                          value={year_of_exp}
                          onChange={this.handleChange}
                        />
                      </FormControl>
                      <FormControl className="mb-3 w-100">
                        <TextField
                          label={'Credentials'}
                          name={'track_record'}
                          value={track_record}
                          onChange={this.handleChange}
                          helperText={'What makes this tutor qualified? Comma separated.'}
                        />
                      </FormControl>
                    </div>
                    {profile_pic && (
                      <div className="col-4">
                        <img src={profile_pic} alt="profile" className="img-fluid" style={{ borderRadius: '50%' }} />
                      </div>
                    )}
                    <div className="col-12">
                      <FormControl className="mb-3 w-100">
                        <CKEditor
                          name="description"
                          content={desc}
                          config={CkConfig}
                          events={{
                            change: this.handleCKEditorChange('desc')
                          }}
                        />
                      </FormControl>
                    </div>
                  </div>
                </Paper>
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant={'text'} color={'default'} onClick={toggle}>
            Cancel
          </Button>
          <Button
            disabled={!name || !email || isLoading}
            variant="contained"
            color="primary"
            onClick={this.saveChanges}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

DialogUserEdit.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  userData: PropTypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogUserEdit)
