import superagent from 'superagent'

const API_ROOT = process.env.REACT_APP_API_ROOT
const responseBody = res => res.body

let token = null
const tokenPlugin = req => {
  if (token) {
    req.set('Authorization', `Bearer ${token}`)
  }
}

let getHost = port => {
  let host = API_ROOT
  if (port && process.env.NODE_ENV !== 'production') {
    host = `http://localhost:${port}`
  }
  return host
}

const requests = {
  del: (url, port) =>
    superagent
      .del(`${getHost(port)}${url}`)
      .use(tokenPlugin)
      .then(responseBody),
  get: (url, body, port) =>
    superagent
      .get(`${getHost(port)}${url}`)
      .use(tokenPlugin)
      .query(body)
      .then(responseBody),
  put: (url, body, port) =>
    superagent
      .put(`${getHost(port)}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody),
  post: (url, body, port) =>
    superagent
      .post(`${getHost(port)}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody),
  patch: (url, body, port) =>
    superagent
      .patch(`${getHost(port)}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody)
}

const limit = (max, offset) => `max=${max}&offset=${offset ? offset * max : 0}`

const Auth = {
  current: () => requests.get('/me'),
  login: (email, password) => requests.post('/auth', { email, password }),
  refreshToken: token => requests.post('/refreshToken', {}),
  getUser: userId => requests.get(`/user?${userId}`),
  changePassword: reqPayload => requests.post('/changePassword', reqPayload)
}

const Orders = {
  getAll: (filter, perPage, page) => {
    let query = {
      sortBy: 'created',
      order: 'desc',
      ...filter,
      max: perPage,
      offset: perPage * (page - 1)
    }
    return requests.get(`/admin/order`, query)
  },
  createOrder: reqBody => {
    return requests.post('/admin/order', reqBody)
  },
  cancelOrder: (orderId, reqBody) => requests.patch(`/admin/order/${orderId}/cancel`, reqBody),
  updateOrder: (orderId, reqBody) => requests.patch(`/admin/order/${orderId}`, reqBody),
  createSalesTrialOrder: reqBody => requests.post('/admin/trialorder', reqBody)
}

const Question = {
  getList: (filter, perPage, page) => {
    if (!perPage) perPage = 10
    if (!page) page = 1
    const query = {
      ...filter,
      max: perPage,
      offset: perPage * (page - 1)
    }
    return requests.get(`/admin/question`, query)
  },
  create: payload => requests.post('/admin/question', payload),
  tag: (id, payload) => requests.patch(`/admin/question/${id}/tag`, payload)
  /*
  search: (offset, filter) =>
    requests.get(
      `/question?${filter}${limit(15, offset)}&sortBy=year&order=desc&sortBy=score&order=desc&sortBy=_id&order=asc`
    ),
  report: (questionId, problems, comments) => requests.post(`/material/${questionId}/report`, { problems, comments }),
  hide: (questionId, problems, comments, is_active) => requests.patch(`/question/${questionId}`, { is_active }),
  updateQuestion: question => requests.patch(`/question/${question.file_ref}`, question),
  searchReportedQuestions: offset =>
    requests.get(`/admin/material/question?defect_report=true&is_qa=false&${limit(15, offset)}`)
  */
}

const DiscountCode = {
  getAllOfType: (code_type, offset) => requests.get(`/admin/discountcode/${code_type}?${limit(20, offset)}`, {}),
  createNew: (code_type, reqBody) => requests.post(`/admin/discountcode/${code_type}`, reqBody),
  disable: id => requests.patch(`/admin/discountcode/general/${id}`, { is_active: false }),
  enable: id => requests.patch(`/admin/discountcode/general/${id}`, { is_active: true })
}

const User = {
  getUsers: (filter, perPage, page) => {
    let query = {
      sortBy: 'created',
      order: 'ASC',
      ...filter,
      max: perPage,
      offset: (page - 1) * perPage
    }
    return requests.get(`/admin/user`, query)
  },
  createUser: userObj => requests.post('/admin/user', userObj),
  updateUser: (userType, userId, userObj) => {
    return requests.patch(`/admin/user/${userType}/${userId}`, userObj)
  },
  getCredit: filter => requests.get('/admin/credit', filter),
  addCredit: reqPayload => requests.post('/admin/credit', reqPayload)
}

const Classroom = {
  getClasses: (filter, perPage, page) => {
    let query = {
      sortBy: 'updated',
      order: 'desc',
      ...filter,
      max: perPage,
      offset: page * perPage
    }
    return requests.get(`/admin/class`, query)
  },
  createClass: classroom => requests.post(`/admin/class`, classroom),
  editClass: classroom => requests.patch(`/admin/class/${classroom._id}`, classroom)
}

const Lesson = {
  getLessonList: (filter, perPage, page) => {
    if (!perPage) perPage = 20
    if (!page) page = 1

    let query = {
      sortBy: 'scheduled_on',
      order: 'asc',
      ...filter,
      max: perPage,
      offset: (page - 1) * perPage
    }
    return requests.get(`/admin/lesson`, query)
  },
  getLesson: lessonId => requests.get(`/admin/lesson/${lessonId}`),
  createLesson: lessonObj => requests.post(`/admin/lesson`, lessonObj),
  editLesson: lessonObj => requests.patch(`/admin/lesson/${lessonObj._id}`, lessonObj),
  addStudentToLesson: (lessonId, reqPayload) => requests.patch(`/admin/lesson/${lessonId}/add-student`, reqPayload),
  createPollQuestion: (lessonId, pollObj) => requests.post(`/admin/lesson/${lessonId}/poll`, pollObj),
  editPollQuestion: (lessonId, questionIndex, pollObj) =>
    requests.put(`/admin/lesson/${lessonId}/poll/${questionIndex}`, pollObj),
  deletePollQuestion: (lessonId, questionIndex) => requests.del(`/admin/lesson/${lessonId}/poll/${questionIndex}`),
  getUserLiveLessonLink: (lessonId, userId) => requests.get(`/admin/lesson/${lessonId}/link/${userId}`)
}

const Announcement = {
  makeAnnouncement: (scope_type, scope_id, announcement_type, target_type, subject, content) => {
    return requests.post(`/admin/config/announcement/${scope_type}/${scope_id}/${announcement_type}/${target_type}`, {
      content,
      subject
    })
  }
}

const UserInput = {
  getFeedback: (filter, perPage, page) => {
    if (!perPage) perPage = 10
    if (!page) page = 1
    let query = {
      sortBy: 'created',
      order: 'desc',
      ...filter,
      max: perPage,
      offset: perPage * (page - 1)
    }
    return requests.get(`/user_input/feedback`, query)
  }
}

const Event = {
  getList: (filter, perPage, page) => {
    if (!perPage) perPage = 20
    if (!page) page = 1
    let query = {
      sortBy: 'scheduled_on',
      order: 'desc',
      ...filter,
      max: perPage,
      offset: perPage * (page - 1)
    }
    return requests.get(`/event/webinar`, query)
  },
  createEvent: eventData => requests.post(`/admin/event/webinar`, eventData),
  editEvent: eventData => requests.patch(`/admin/event/webinar/${eventData._id}`, eventData),
  deleteEvent: id => requests.del(`/admin/event/webinar/${id}`)
}

const Homework = {
  getList: (filter, perPage, page) => {
    if (!perPage) perPage = 30
    if (!page) page = 1
    let query = {
      ...filter,
      max: perPage,
      offset: perPage * (page - 1)
    }
    return requests.get(`/homework`, query)
  },
  getPendingSubmissionList: homeworkId => requests.get(`/homework/${homeworkId}/pending-submission`)
}

const Submission = {
  getList: reqPayload => requests.get('/submission', reqPayload),
  getStat: reqPayload => requests.get('/submission/stat', reqPayload)
}

const Report = {
  getClassEnrolment: reqPayload => requests.post('/admin/report/enrolment', reqPayload),
  getTrialStat: reqPayload => requests.post('/admin/report/trial', reqPayload),
  getHomeworkAssingment: reqPayload => requests.post('/admin/report/homework-assignment', reqPayload),
  getBirthdayStudent: reqPayload => requests.post('/admin/report/birthday-student', reqPayload),
  getSalesStat: (startDate, endDate, exclude) =>
    requests.get(`/admin/report/sales?start=${startDate}&end=${endDate}&exclude=${exclude}`),
  getTicketsStat: (startDate, endDate) => requests.get(`/admin/report/tickets?start=${startDate}&end=${endDate}`, {})
}

const Ticket = {
  getList: (type, filter) => requests.get(`/admin/ticket/${type}`, filter),
  updateById: (type, ticketId, reqPayload) => requests.patch(`/admin/ticket/${type}/${ticketId}`, reqPayload),
  batchUpdate: (type, reqPayload) => requests.patch(`/admin/ticket/batch/${type}`, reqPayload)
}

export default {
  Auth,
  Question,
  Orders,
  DiscountCode,
  User,
  Classroom,
  Lesson,
  Announcement,
  UserInput,
  Event,
  Homework,
  Submission,
  Report,
  Ticket,
  setToken: _token => {
    token = _token
  }
}
