import React from 'react'
import { GoogleSpreadsheet } from 'google-spreadsheet'
import { connect } from 'react-redux'
import agent from 'agent'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import {
  GET_LESSONS,
  CREATE_LESSON_POLL,
  EDIT_LESSON_POLL,
  DELETE_LESSON_POLL,
  SET_ACTIVE_LESSON
} from 'constants/actionTypes'
import moment from 'moment'
import { ROUTE_LESSON_DETAIL_ZOOM, ROUTE_LESSON_DETAIL_TENOPY_CLASSROOM } from 'routes/paths'
import qs from 'qs'
const mapStateToProps = state => ({
  lessonList: state.lessons.lessons,
  isLoading: state.lessons.isLoading
})

const SPREADSHEET_ID = process.env.REACT_APP_SPREADSHEET_ID
const SHEET_ID = process.env.REACT_APP_SHEET_ID
const CLIENT_EMAIL = process.env.REACT_APP_GOOGLE_CLIENT_EMAIL
const PRIVATE_KEY = process.env.REACT_APP_GOOGLE_SERVICE_PRIVATE_KEY.replace(/\\n/g, '\n')

const doc = new GoogleSpreadsheet(SPREADSHEET_ID)

const appendSpreadsheet = async data => {
  try {
    await doc.useServiceAccountAuth({
      client_email: CLIENT_EMAIL,
      private_key: PRIVATE_KEY
    })
    // loads document properties and worksheets
    await doc.loadInfo()

    const sheet = doc.sheetsById[SHEET_ID]
    // // // JSON.stringify(sheet.addRow(row));

    const headers = ['Teacher', 'Day', 'Date', 'Time', 'Class_Name', 'Lesson_Name', 'Link', 'Zoom_Account']

    // Append the headers to the sheet
    await sheet.setHeaderRow(headers)

    await sheet.addRows(data)
    await sheet.addRows([
      { Teacher: '-', Day: '-', Date: '-', Time: '-', Class_Name: '-', Lesson_Name: '-', Link: '-', Zoom_Account: '-' }
    ])

    console.log('Data written to sheet!')
  } catch (e) {
    console.error('Error: ', e)
  }
}

// appendSpreadsheet(newRow);

const mapDispatchToProps = dispatch => ({
  getLessonList: day =>
    dispatch({
      type: GET_LESSONS,
      payload: agent.Lesson.getLessonList({
        is_active: true,
        ended_on: {
          $gte: moment(day).startOf('d').format('YYYY-MM-DDTHH:mm:ss[Z]'),
          $lte: moment(day).endOf('d').format('YYYY-MM-DDTHH:mm:ss[Z]')
        }
      })
    }),
  createPollQuestion: (lessonId, pollObj) =>
    dispatch({ type: CREATE_LESSON_POLL, payload: agent.Lesson.createPollQuestion(lessonId, pollObj) }),
  editPollQuestion: (lessonId, questionIndex, pollObj) =>
    dispatch({ type: EDIT_LESSON_POLL, payload: agent.Lesson.editPollQuestion(lessonId, questionIndex, pollObj) }),
  deletePollQuestion: (lessonId, questionIndex) =>
    dispatch({ type: DELETE_LESSON_POLL, payload: agent.Lesson.deletePollQuestion(lessonId, questionIndex) }),
  setActiveLesson: lesson => dispatch({ type: SET_ACTIVE_LESSON, lesson })
})

class LessonManagement extends React.Component {
  state = {
    // day: moment().format('YYYY-MM-DD'),
    day: '2021-02-21',
    selectedLessonId: '',
    selectedQuestionIndex: '',
    selectedPoll: []
  }

  componentDidMount() {
    let { day } = this.state
    let searchParams = qs.parse(this.props.location.search.slice(1))
    if (searchParams.date) {
      day = searchParams.date
    }

    this.props.getLessonList(day)
    this.setState({ day })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.day !== this.state.day) {
      this.props.getLessonList(this.state.day)
    }
  }

  handleDateChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  searchLessons = day => () => {
    this.setState({ day })
  }

  goToDetailPage = lessonData => () => {
    this.props.setActiveLesson({ lesson: lessonData })
    if (lessonData.hosting_platform === 'Zoom') {
      this.props.history.push(ROUTE_LESSON_DETAIL_ZOOM.replace(':lessonId', lessonData._id))
    }
    if (lessonData.hosting_platform === 'Tenopy Classroom') {
      this.props.history.push(ROUTE_LESSON_DETAIL_TENOPY_CLASSROOM.replace(':lessonId', lessonData._id))
    }
  }

  render() {
    let { lessonList, isLoading } = this.props
    let { day } = this.state

    const handleClick = async () => {
      const data = lessonList.map(ll => {
        return [
          ll.tutor[0].name,
          moment(ll.scheduled_on).format('ddd'),
          `${moment(ll.scheduled_on).format('D')} ${moment(ll.scheduled_on).format('MMM')}`,
          `${moment(ll.scheduled_on).format('LT')} - ${moment(ll.ended_on).format('LT')}`,
          ll.class.name,
          ll.name,
          ll.platform_integration.id ? `https://zoom.us/s/${ll.platform_integration.id}` : 'Tenopy Classroom',
          ll.platform_integration?.settings?.alternative_hosts
            ? ll.platform_integration?.settings?.alternative_hosts
            : '-'
        ]
      })

      // map data to an array of objects with column names as keys
      const rows = data.map(row => {
        return {
          Teacher: row[0],
          Day: row[1],
          Date: row[2],
          Time: row[3],
          Class_Name: row[4],
          Lesson_Name: row[5],
          Link: row[6],
          Zoom_Account: row[7]
        }
      })

      await appendSpreadsheet(rows)
    }

    return (
      <div className="app-wrapper">
        <h1>Quick Lesson Management</h1>

        <button onClick={handleClick}>Write data to sheet</button>

        <div className="row justify-content-center mb-3">
          <div className="col-md-auto col-12">
            <Button
              className="mr-4"
              variant="outlined"
              color="primary"
              onClick={this.searchLessons(moment(day).subtract(1, 'd').format('YYYY-MM-DD'))}
              disabled={isLoading}>
              Prev Day
            </Button>
            <TextField type={'date'} label={'Day'} name={'day'} value={day} onChange={this.handleDateChange} />
            <Button
              className="ml-4"
              variant="outlined"
              color="primary"
              onClick={this.searchLessons(moment(day).add(1, 'd').format('YYYY-MM-DD'))}
              disabled={isLoading}>
              Next Day
            </Button>
          </div>
        </div>

        {this.props.isLoading && (
          <div className="my-5 text-center">
            <CircularProgress color={'primary'} size={40} />
          </div>
        )}

        {lessonList && (
          <Paper elevation={2}>
            <table className="table table-responsive-md">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Time</th>
                  <th scope="col">Poll</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {lessonList.map(p => (
                  <tr key={p._id}>
                    <td>
                      {p.class.name} - {p.name}
                    </td>
                    <td>{moment(p.scheduled_on).format('lll')}</td>
                    <td>{p.poll_setup?.length} questions</td>
                    <td>
                      <Button color="primary" variant="contained" onClick={this.goToDetailPage(p)}>
                        Edit
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Paper>
        )}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LessonManagement)
