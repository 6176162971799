import React from 'react'
import { connect } from 'react-redux'
import { Button, Dialog, DialogActions, DialogContent, Slide, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import agent from 'agent'
import { TAG_QUESTION } from 'constants/actionTypes'
import { MATH_TOPICS, MATH_SKILL_SET, HEURISTICS } from './constant'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const mapStateToProps = state => ({
  token: state.common.token,
  tagging: state.question.tagging,
  createResp: state.question.createResp
})

const mapDispatchToProps = dispatch => ({
  tagQuestion: (questionId, reqPayload) =>
    dispatch({ type: TAG_QUESTION, payload: agent.Question.tag(questionId, reqPayload) })
})

class DialogTagQuestion extends React.Component {
  state = {
    topic: '',
    topicSearch: '',
    skill: '',
    skillSearch: '',
    heuristic: '',
    heuristicSearch: ''
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen && !this.props.isOpen) {
      this.setState({
        topic: '',
        topicSearch: '',
        skill: '',
        skillSearch: '',
        heuristic: '',
        heuristicSearch: ''
      })
    }

    if (prevProps.tagging && !this.props.tagging) {
      this.props.closeDialog()
    }
  }

  handleTag = () => {
    const { questionId } = this.props
    const { topic, skill, heuristic } = this.state

    if (!questionId) return

    this.props.tagQuestion(questionId, { topic, skill, heuristic })
  }

  render() {
    const { isOpen } = this.props
    const { topic, topicSearch, skill, skillSearch, heuristic, heuristicSearch } = this.state

    return (
      <Dialog open={isOpen} onClose={this.props.close} TransitionComponent={Transition} maxWidth="lg" fullWidth>
        <DialogContent>
          <div className="row ">
            <div className="col-6">
              <Autocomplete
                value={topic}
                onChange={(event, newValue) => {
                  this.setState({ topic: newValue })
                }}
                inputValue={topicSearch}
                onInputChange={(event, newInputValue) => {
                  this.setState({ topicSearch: newInputValue })
                }}
                options={MATH_TOPICS}
                style={{ minWidth: 200 }}
                renderInput={params => <TextField {...params} label="Topic" />}
              />
            </div>
            <div className="col-6">
              <Autocomplete
                value={skill}
                onChange={(event, newValue) => {
                  this.setState({ skill: newValue })
                }}
                inputValue={skillSearch}
                onInputChange={(event, newInputValue) => {
                  this.setState({ skillSearch: newInputValue })
                }}
                options={topic ? MATH_SKILL_SET[topic] : []}
                style={{ minWidth: 200 }}
                renderInput={params => <TextField {...params} label="Skills Set (or Sub-Strands)" />}
              />
            </div>
            <div className="col mt-3">
              <Autocomplete
                value={heuristic}
                onChange={(event, newValue) => {
                  this.setState({ heuristic: newValue })
                }}
                inputValue={heuristicSearch}
                onInputChange={(event, newInputValue) => {
                  this.setState({ heuristicSearch: newInputValue })
                }}
                options={HEURISTICS}
                style={{ minWidth: 200 }}
                renderInput={params => <TextField {...params} label="Heuristic" />}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button className="mr-2" variant="text" color="secondary" onClick={this.props.close}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={this.handleTag}>
            Tag
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogTagQuestion)
