import React from 'react'
import { connect } from 'react-redux'
import {
  AppBar,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Slide,
  TextField,
  Toolbar
} from '@material-ui/core'
import { Alert, Autocomplete } from '@material-ui/lab'
import { Cancel as CancelIcon, Close as CloseIcon, Done as CorrectIcon } from '@material-ui/icons'
import { EditorState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import { stateToHTML } from 'draft-js-export-html'
import Latex from 'components/editor/latex-renderer'
import MathInput from 'components/editor/math-input'
import agent from 'agent'
import { CREATE_QUESTION } from 'constants/actionTypes'
import { AVAILABLE_SUBJECT, AVAILABLE_LEVEL, MATH_TOPICS, MATH_SKILL_SET, HEURISTICS } from './constant'
import AddQuestionUnitDialog from './dialog-add-question-unit'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const mapStateToProps = state => ({
  token: state.common.token,
  creating: state.question.creating,
  createResp: state.question.createResp
})

const mapDispatchToProps = dispatch => ({
  create: reqPayload => dispatch({ type: CREATE_QUESTION, payload: agent.Question.create(reqPayload) })
})

class DialogAddQuestion extends React.Component {
  state = {
    subject: 'Mathematics',
    level: ['primary-6'],
    year: 2021,
    school: '',
    topic: '',
    topicSearch: '',
    skill: '',
    skillSearch: '',
    heuristic: '',
    heuristicSearch: '',
    isSelfChecking: false,
    instruction: '',
    context: EditorState.createEmpty(),
    questionUnit: [],
    isAddQuestionUnitDialogOpen: false,
    alertMessage: '',
    mathWorksheetSection: ''
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.creating && !this.props.creating) {
      const { createResp } = this.props
      if (createResp?.status === 'success') {
        this.props.closeDialog()
      } else {
        this.setState({ alertMessage: `Something went wrong. (${createResp.msg.toString()})` })
      }
    }

    if (prevProps.isOpen && !this.props.isOpen) {
      this.setState({
        subject: 'Mathematics',
        level: ['primary-6'],
        year: 2021,
        school: '',
        topic: '',
        topicSearch: '',
        skill: '',
        skillSearch: '',
        heuristic: '',
        heuristicSearch: '',
        isSelfChecking: false,
        instruction: '',
        context: EditorState.createEmpty(),
        questionUnit: [],
        isAddQuestionUnitDialogOpen: false,
        alertMessage: '',
        mathWorksheetSection: ''
      })
    }

    if (prevState.topic !== this.state.topic) {
      this.setState({ skill: '' })
    }
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleCheckboxChange = event => {
    this.setState({ [event.target.name]: event.target.checked })
  }

  onEditorStateChange = element => editorState => {
    this.setState({ [element]: editorState })
  }

  uploadImageCallBack = file => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.open('POST', `${process.env.REACT_APP_API_ROOT}/admin/misc/image-upload`)
      xhr.setRequestHeader('Authorization', `Bearer ${this.props.token}`)
      const data = new FormData()
      data.append('attachment', file)
      xhr.send(data)
      xhr.addEventListener('load', () => {
        const response = JSON.parse(xhr.responseText)
        resolve({ data: { link: response.url } })
      })
      xhr.addEventListener('error', () => {
        const error = JSON.parse(xhr.responseText)
        reject(error)
      })
    })
  }

  toggleAddQuestionUnitDialog = () => {
    this.setState({ isAddQuestionUnitDialogOpen: !this.state.isAddQuestionUnitDialogOpen })
  }

  addQuestionUnit = data => {
    let { questionUnit } = this.state
    questionUnit.push({
      uid: Math.random().toString(36),
      ...data
    })
    this.setState({ questionUnit })
    this.toggleAddQuestionUnitDialog()
  }

  removeQuestionUnit = index => () => {
    let { questionUnit } = this.state
    questionUnit.splice(index, 1)
    this.setState({ questionUnit })
  }

  handleSave = () => {
    const {
      subject,
      level,
      context,
      instruction,
      questionUnit,
      year,
      school,
      topic,
      skill,
      heuristic,
      isSelfChecking,
      mathWorksheetSection
    } = this.state
    this.setState({ alertMessage: '' })

    const reqPayload = {
      subject,
      level,
      instruction: instruction.trim(),
      context: context.getCurrentContent().hasText() ? stateToHTML(context.getCurrentContent()) : '',
      unit: questionUnit,
      meta: {
        year,
        school: school.trim(),
        topic,
        skill,
        heuristic,
        section: mathWorksheetSection
      },
      is_self_checking: isSelfChecking
    }
    this.props.create(reqPayload)
  }

  render() {
    const { isOpen, closeDialog, token, creating } = this.props
    const {
      subject,
      level,
      year,
      school,
      topic,
      topicSearch,
      skill,
      skillSearch,
      heuristic,
      heuristicSearch,
      instruction,
      context,
      questionUnit,
      isSelfChecking,
      isAddQuestionUnitDialogOpen,
      alertMessage,
      mathWorksheetSection
    } = this.state

    return (
      <>
        <Dialog fullScreen open={isOpen} onClose={closeDialog} TransitionComponent={Transition}>
          <div>
            <AppBar className="position-relative">
              <Toolbar>
                <IconButton edge="start" color="inherit" onClick={closeDialog} aria-label="close">
                  <CloseIcon />
                </IconButton>
                <h3 className="ml-2 mb-0">Add New Question</h3>
                <Button
                  variant="contained"
                  className="bg-white text-primary ml-auto"
                  onClick={this.handleSave}
                  disabled={creating}>
                  Save {creating && <CircularProgress color="primary" className="ml-2" size={20} />}
                </Button>
              </Toolbar>
            </AppBar>

            <div className="container-fluid my-3">
              <div className="row ">
                {alertMessage && (
                  <div className="col-12">
                    <Alert severity="error">{alertMessage}</Alert>
                  </div>
                )}

                <div className="col-6">
                  <Paper className="p-3" elevation={2}>
                    <FormControl className="mb-1">
                      <FormLabel component="legend">Subject</FormLabel>
                      <RadioGroup row name="subject" value={subject} onChange={this.handleChange}>
                        {AVAILABLE_SUBJECT.map(s => (
                          <FormControlLabel key={s} value={s} control={<Radio />} label={s} />
                        ))}
                      </RadioGroup>
                    </FormControl>

                    <FormControl className="w-100 mb-3">
                      <InputLabel htmlFor="level">Level</InputLabel>
                      <Select
                        multiple
                        name="level"
                        value={level}
                        onChange={this.handleChange}
                        label="Level"
                        renderValue={selected => selected.join(', ').replaceAll('-', ' ')}>
                        {AVAILABLE_LEVEL.map(lvl => (
                          <MenuItem key={lvl} value={lvl}>
                            <Checkbox checked={level.indexOf(lvl) > -1} />
                            <ListItemText primary={lvl.replace('-', ' ')} className="text-capitalize" />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {subject === 'Mathematics' && (
                      <div className="row mb-3">
                        <div className="col-6">
                          <Autocomplete
                            value={topic}
                            onChange={(event, newValue) => {
                              this.setState({ topic: newValue })
                            }}
                            inputValue={topicSearch}
                            onInputChange={(event, newInputValue) => {
                              this.setState({ topicSearch: newInputValue })
                            }}
                            options={MATH_TOPICS}
                            style={{ minWidth: 200 }}
                            renderInput={params => <TextField {...params} label="Topic" />}
                          />
                        </div>
                        <div className="col-6">
                          <Autocomplete
                            value={skill}
                            onChange={(event, newValue) => {
                              this.setState({ skill: newValue })
                            }}
                            inputValue={skillSearch}
                            onInputChange={(event, newInputValue) => {
                              this.setState({ skillSearch: newInputValue })
                            }}
                            options={topic ? MATH_SKILL_SET[topic] : []}
                            style={{ minWidth: 200 }}
                            renderInput={params => <TextField {...params} label="Skills Set (or Sub-Strands)" />}
                          />
                        </div>
                        <div className="col mt-3">
                          <Autocomplete
                            value={heuristic}
                            onChange={(event, newValue) => {
                              this.setState({ heuristic: newValue })
                            }}
                            inputValue={heuristicSearch}
                            onInputChange={(event, newInputValue) => {
                              this.setState({ heuristicSearch: newInputValue })
                            }}
                            options={HEURISTICS}
                            style={{ minWidth: 200 }}
                            renderInput={params => <TextField {...params} label="Heuristic" />}
                          />
                        </div>
                        <div className="col-auto mt-3">
                          <FormControl style={{ minWidth: '150px' }}>
                            <InputLabel htmlFor="level">Section</InputLabel>
                            <Select
                              name="mathWorksheetSection"
                              value={mathWorksheetSection}
                              onChange={this.handleChange}
                              label="Section">
                              <MenuItem value="MCQ">MCQ</MenuItem>
                              <MenuItem value="Short Question">SQ</MenuItem>
                              <MenuItem value="Word Problem">WP</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    )}

                    <div className="row mb-3">
                      <div className="col-auto">
                        <TextField label="Year" name="year" onChange={this.handleChange} value={year} />
                      </div>
                      <div className="col-auto">
                        <TextField label="School" name="school" onChange={this.handleChange} value={school} />
                      </div>
                      <div className="col-auto">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isSelfChecking}
                              onChange={this.handleCheckboxChange}
                              name="isSelfChecking"
                              color="secondary"
                            />
                          }
                          label="Self Checking (no auto marking)"
                        />
                      </div>
                    </div>

                    <FormControl className="w-100 mb-3">
                      <TextField
                        label="Instruction"
                        name="instruction"
                        onChange={this.handleChange}
                        value={instruction}
                      />
                    </FormControl>

                    <div className="mb-3">
                      <FormLabel component="legend">Context</FormLabel>
                      <Editor
                        editorState={context}
                        onEditorStateChange={this.onEditorStateChange('context')}
                        toolbarCustomButtons={[<MathInput key={0} />]}
                        toolbar={{
                          options: [
                            'inline',
                            'list',
                            'textAlign',
                            'colorPicker',
                            'link',
                            'embedded',
                            'emoji',
                            'image',
                            'history'
                          ],
                          image: {
                            uploadCallback: this.uploadImageCallBack,
                            previewImage: true,
                            alt: { present: true, mandatory: true }
                          }
                        }}
                      />
                      <div
                        className="mt-2"
                        style={{
                          backgroundColor: '#F0F0F0',
                          border: '1px solid #F1F1F1',
                          borderRadius: '5px',
                          color: '#333',
                          padding: '1rem'
                        }}>
                        <Latex>
                          <div dangerouslySetInnerHTML={{ __html: stateToHTML(context.getCurrentContent()) }} />
                        </Latex>
                      </div>
                    </div>
                  </Paper>
                </div>

                <div className="col-6">
                  <Paper className="p-3" elevation={2}>
                    <div className="row mb-3">
                      <div className="col-12 text-right mb-2">
                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          onClick={this.toggleAddQuestionUnitDialog}>
                          Add Question Unit
                        </Button>
                      </div>
                      <div className="col-12">
                        <table className="w-100">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Question Details</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {questionUnit.map((q, index) => (
                              <tr key={q.uid}>
                                <td>{q.label}</td>
                                <td>
                                  <div>
                                    <Latex>
                                      <div dangerouslySetInnerHTML={{ __html: q.question_body }} />
                                    </Latex>
                                    {q.question_type === 'short_answer' && (
                                      <div>
                                        <span className="text-muted">Answer: </span>
                                        <span>{q.answer}</span>
                                      </div>
                                    )}
                                    {q.question_type === 'mcq' && (
                                      <>
                                        {q.option.map((o, index) => (
                                          <div key={index} className="d-flex flex-row align-items-center">
                                            {o.label}. {o.content}
                                            {q.answer.includes(index) && (
                                              <CorrectIcon
                                                className="ml-1 text-success"
                                                style={{ width: '0.8em', height: '0.8em' }}
                                              />
                                            )}
                                          </div>
                                        ))}
                                      </>
                                    )}
                                  </div>
                                </td>
                                <td>
                                  <CancelIcon
                                    className="text-danger clickable"
                                    onClick={this.removeQuestionUnit(index)}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Paper>
                </div>
              </div>
            </div>
          </div>
        </Dialog>

        <AddQuestionUnitDialog
          isOpen={isAddQuestionUnitDialogOpen}
          close={this.toggleAddQuestionUnitDialog}
          addQuestionUnit={this.addQuestionUnit}
          token={token}
        />
      </>
    )
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(DialogAddQuestion)
