import React from 'react'

import { Chip } from '@material-ui/core'

const TicketStatusBadge = props => {
  const { status } = props

  let backgroundColor = '#e0e0e0',
    color = '#2a2a2a'

  if (
    status === 'TRIAL REGISTERED' ||
    status === 'PRE TRIAL FOLLOWED UP' ||
    status === 'POST TRIAL FOLLOWED UP' ||
    status === 'INIT'
  ) {
    backgroundColor = '#00bcd4'
    color = '#ffffff'
  } else if (status === 'TRIAL ATTENDED') {
    backgroundColor = '#4caf50'
    color = '#ffffff'
  } else if (status === 'TRIAL ABSENT') {
    backgroundColor = '#ff9800'
    color = '#ffffff'
  } else if (status === 'PENDING DECISION') {
    backgroundColor = '#3f51b5'
    color = '#ffffff'
  } else if (status === 'CLOSED WON' || status === 'RENEWED') {
    backgroundColor = '#673ab7'
    color = '#ffffff'
  } else if (status === 'CLOSED LOSE' || status === 'DROPPED') {
    backgroundColor = 'E91E63'
    color = '#ffffff'
  }

  return (
    <Chip label={status} size="small" style={{ backgroundColor: backgroundColor, color: color, cursor: 'pointer' }} />
  )
}

export default TicketStatusBadge
