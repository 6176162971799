import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Slide,
  TextField
} from '@material-ui/core'
import { EditorState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import { stateToHTML } from 'draft-js-export-html'
import _ from 'lodash'
import { QUESTION_TYPE, MCQ_ALPHABET_LABEL } from './constant'
import Latex from 'components/editor/latex-renderer'
import MathInput from 'components/editor/math-input'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

class DialogAddQuestion extends React.Component {
  state = {
    mark: 1,
    label: '',
    questionType: 'open_ended',
    body: EditorState.createEmpty(),
    hint: '',
    answerType: 'absolute_answer',
    absoluteAnswer: '',
    absoluteAnswerPreUnit: '',
    absoluteAnswerPostUnit: '',
    answerSet: ['', ''],
    answerSetPreUnit: ['', ''],
    answerSetPostUnit: ['', ''],
    richTextAnswer: EditorState.createEmpty(),
    mcqAnswerIndex: [],
    mcqOption: ['', '', '', '']
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isOpen && !this.props.isOpen) {
      this.setState({
        mark: 1,
        label: '',
        questionType: 'open_ended',
        body: EditorState.createEmpty(),
        hint: '',
        answerType: 'absolute_answer',
        absoluteAnswer: '',
        absoluteAnswerPreUnit: '',
        absoluteAnswerPostUnit: '',
        answerSet: ['', ''],
        answerSetPreUnit: ['', ''],
        answerSetPostUnit: ['', ''],
        richTextAnswer: EditorState.createEmpty(),
        mcqAnswerIndex: [],
        mcqOption: ['', '', '', '']
      })
    }

    if (prevState.questionType !== this.state.questionType) {
      const { questionType } = this.state
      if (questionType === 'mcq') {
        this.setState({ answerType: 'single' })
      } else if (questionType === 'open_ended') {
        this.setState({ answerType: 'absolute_answer' })
      }
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  onEditorStateChange = element => editorState => {
    this.setState({ [element]: editorState })
  }

  uploadImageCallBack = file => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.open('POST', `${process.env.REACT_APP_API_ROOT}/admin/misc/image-upload`)
      xhr.setRequestHeader('Authorization', `Bearer ${this.props.token}`)
      const data = new FormData()
      data.append('attachment', file)
      xhr.send(data)
      xhr.addEventListener('load', () => {
        const response = JSON.parse(xhr.responseText)
        resolve({ data: { link: response.url } })
      })
      xhr.addEventListener('error', () => {
        const error = JSON.parse(xhr.responseText)
        reject(error)
      })
    })
  }

  onChangeAnswerOption = index => event => {
    const targetId = event.target.id
    let { mcqOption, answerSet, answerSetPreUnit, answerSetPostUnit } = this.state
    if (targetId === 'mcq') {
      let newOption = mcqOption.slice(0)
      newOption[index] = event.target.value
      this.setState({ mcqOption: newOption })
    } else if (targetId === 'answerSet') {
      let newOption = answerSet.slice(0)
      newOption[index] = event.target.value
      this.setState({ answerSet: newOption })
    } else if (targetId === 'answerSetPreUnit') {
      let newOption = answerSetPreUnit.slice(0)
      newOption[index] = event.target.value
      this.setState({ answerSetPreUnit: newOption })
    } else if (targetId === 'answerSetPostUnit') {
      let newOption = answerSetPostUnit.slice(0)
      newOption[index] = event.target.value
      this.setState({ answerSetPostUnit: newOption })
    }
  }

  addMoreAnswerOption = () => {
    let { questionType, mcqOption, answerSet, answerSetPreUnit, answerSetPostUnit } = this.state
    if (questionType === 'mcq') {
      mcqOption.push('')
      this.setState({ mcqOption })
    } else if (questionType === 'open_ended') {
      answerSet.push('')
      answerSetPreUnit.push('')
      answerSetPostUnit.push('')
      this.setState({ answerSet, answerSetPreUnit, answerSetPostUnit })
    }
  }

  setMCQAnswer = index => () => {
    let { mcqAnswerIndex } = this.state
    let newAnswerIndex = mcqAnswerIndex
    if (mcqAnswerIndex.includes(index)) {
      newAnswerIndex = _.remove(mcqAnswerIndex, function (o) {
        return o !== index
      })
    } else {
      newAnswerIndex.push(index)
    }
    this.setState({ mcqAnswerIndex: newAnswerIndex })
  }

  handleAdd = () => {
    const {
      mark,
      label,
      questionType,
      body,
      hint,
      answerType,
      absoluteAnswer,
      absoluteAnswerPreUnit,
      absoluteAnswerPostUnit,
      answerSet,
      answerSetPreUnit,
      answerSetPostUnit,
      richTextAnswer,
      mcqAnswerIndex,
      mcqOption
    } = this.state

    let questionUnit = {
      mark,
      label: label.trim(),
      question_type: questionType,
      question_body: stateToHTML(body.getCurrentContent()),
      hint: hint.trim(),
      answer_type: answerType
    }

    if (questionType === 'mcq') {
      let formattedOption = []
      let validMcqOption = _.compact(mcqOption)
      for (let i = 0; i < validMcqOption.length; i++) {
        formattedOption.push({
          label: MCQ_ALPHABET_LABEL[i],
          content: validMcqOption[i].trim()
        })
      }
      questionUnit['answer'] = mcqAnswerIndex
      questionUnit['option'] = formattedOption
    } else if (questionType === 'open_ended') {
      if (answerType === 'absolute_answer') {
        questionUnit['answer'] = {
          body: absoluteAnswer.trim(),
          pre_unit: absoluteAnswerPreUnit.trim(),
          post_unit: absoluteAnswerPostUnit.trim()
        }
      } else if (answerType === 'short_answer_set') {
        let answer = []
        for (let i = 0; i < answerSet.length; i++) {
          const answerBody = answerSet[i].trim()
          if (answerBody) {
            answer.push({
              body: answerBody,
              pre_unit: answerSetPreUnit[i].trim(),
              post_unit: answerSetPostUnit[i].trim()
            })
          }
        }
        questionUnit['answer'] = answer
      } else if (answerType === 'rich_text') {
        questionUnit['answer'] = stateToHTML(richTextAnswer.getCurrentContent())
      }
    }
    this.props.addQuestionUnit(questionUnit)
  }

  render() {
    const { isOpen } = this.props
    const {
      mark,
      label,
      questionType,
      body,
      hint,
      answerType,
      absoluteAnswer,
      absoluteAnswerPreUnit,
      absoluteAnswerPostUnit,
      richTextAnswer,
      answerSet,
      answerSetPreUnit,
      answerSetPostUnit,
      mcqOption,
      mcqAnswerIndex
    } = this.state

    return (
      <Dialog open={isOpen} onClose={this.props.close} TransitionComponent={Transition} maxWidth="lg" fullWidth>
        <DialogContent>
          <div className="row ">
            <div className="col-6">
              <div className="row mb-3">
                <div className="col-12">
                  <FormLabel component="legend">Question Type</FormLabel>
                  <FormControl className="mb-2">
                    <RadioGroup row name="questionType" value={questionType} onChange={this.handleChange}>
                      {QUESTION_TYPE.map(s => (
                        <FormControlLabel key={s} value={s} control={<Radio />} label={s.replaceAll('_', ' ')} />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className="col-auto">
                  <TextField variant="outlined" label="Label" name="label" onChange={this.handleChange} value={label} />
                </div>
                <div className="col-auto">
                  <TextField variant="outlined" label="Mark" name="mark" onChange={this.handleChange} value={mark} />
                </div>
              </div>
              <div className="mb-3">
                <FormLabel component="legend">Question Body</FormLabel>
                <Editor
                  editorState={body}
                  onEditorStateChange={this.onEditorStateChange('body')}
                  toolbarCustomButtons={[<MathInput key={0} />]}
                  toolbar={{
                    options: [
                      'inline',
                      'list',
                      'textAlign',
                      'colorPicker',
                      'link',
                      'embedded',
                      'emoji',
                      'image',
                      'history'
                    ],
                    image: {
                      uploadCallback: this.uploadImageCallBack,
                      previewImage: true,
                      alt: { present: true, mandatory: true }
                    }
                  }}
                />
                <div
                  className="mt-2"
                  style={{
                    backgroundColor: '#F0F0F0',
                    border: '1px solid #F1F1F1',
                    borderRadius: '5px',
                    color: '#333',
                    padding: '1rem'
                  }}>
                  <Latex>
                    <div dangerouslySetInnerHTML={{ __html: stateToHTML(body.getCurrentContent()) }} />
                  </Latex>
                </div>
              </div>
            </div>
            <div className="col-6">
              <TextField
                variant="outlined"
                fullWidth
                label="Hint"
                name="hint"
                onChange={this.handleChange}
                value={hint}
                className="mb-3"
              />
              <FormLabel component="legend">Answer Type</FormLabel>
              <FormControl className="mb-2">
                <RadioGroup row name="answerType" value={answerType} onChange={this.handleChange}>
                  {questionType === 'mcq' && (
                    <>
                      <FormControlLabel value="single" control={<Radio />} label="Single choice" />
                      <FormControlLabel value="multiple" control={<Radio />} label="Multiple choice" />
                    </>
                  )}
                  {questionType === 'open_ended' && (
                    <>
                      <FormControlLabel value="absolute_answer" control={<Radio />} label="Only 1 answer" />
                      <FormControlLabel
                        value="short_answer_set"
                        control={<Radio />}
                        label="Multiple possible answers"
                      />
                      <FormControlLabel value="rich_text" control={<Radio />} label="Rich Text (image)" />
                    </>
                  )}
                </RadioGroup>
              </FormControl>
              {questionType === 'open_ended' && (
                <>
                  {answerType === 'absolute_answer' && (
                    <div className="row mb-3">
                      <div className="col-2">
                        <TextField
                          variant="outlined"
                          fullWidth
                          label="Unit"
                          name="absoluteAnswerPreUnit"
                          onChange={this.handleChange}
                          value={absoluteAnswerPreUnit}
                        />
                      </div>
                      <div className="col-8">
                        <TextField
                          variant="outlined"
                          fullWidth
                          label="Answer"
                          name="absoluteAnswer"
                          onChange={this.handleChange}
                          value={absoluteAnswer}
                        />
                      </div>
                      <div className="col-2">
                        <TextField
                          variant="outlined"
                          fullWidth
                          label="Unit"
                          name="absoluteAnswerPostUnit"
                          onChange={this.handleChange}
                          value={absoluteAnswerPostUnit}
                        />
                      </div>
                    </div>
                  )}
                  {answerType === 'short_answer_set' && (
                    <div className="mb-3">
                      <FormLabel component="legend">Answer Set</FormLabel>
                      {answerSet.map((item, index) => (
                        <div className="row" key={index}>
                          <div className="col-2">
                            <TextField
                              id="answerSetPreUnit"
                              label={`Unit ${index + 1}`}
                              value={answerSetPreUnit[index]}
                              onChange={this.onChangeAnswerOption(index)}
                              variant="outlined"
                              fullWidth
                              margin="normal"
                            />
                          </div>
                          <div className="col-8">
                            <TextField
                              id="answerSet"
                              label={`Answer ${index + 1}`}
                              value={item}
                              onChange={this.onChangeAnswerOption(index)}
                              variant="outlined"
                              fullWidth
                              margin="normal"
                            />
                          </div>
                          <div className="col-2">
                            <TextField
                              id="answerSetPostUnit"
                              label={`Unit ${index + 1}`}
                              value={answerSetPostUnit[index]}
                              onChange={this.onChangeAnswerOption(index)}
                              variant="outlined"
                              fullWidth
                              margin="normal"
                            />
                          </div>
                        </div>
                      ))}
                      <Button onClick={this.addMoreAnswerOption} color="primary">
                        Add more
                      </Button>
                    </div>
                  )}
                  {answerType === 'rich_text' && (
                    <div className="mb-3">
                      <FormLabel component="legend">Answer</FormLabel>
                      <Editor
                        editorState={richTextAnswer}
                        onEditorStateChange={this.onEditorStateChange('richTextAnswer')}
                        toolbarCustomButtons={[<MathInput key={0} />]}
                        toolbar={{
                          options: [
                            'inline',
                            'list',
                            'textAlign',
                            'colorPicker',
                            'link',
                            'embedded',
                            'emoji',
                            'image',
                            'history'
                          ],
                          image: {
                            uploadCallback: this.uploadImageCallBack,
                            previewImage: true,
                            alt: { present: true, mandatory: true }
                          }
                        }}
                      />
                      <div
                        className="mt-2"
                        style={{
                          backgroundColor: '#F0F0F0',
                          border: '1px solid #F1F1F1',
                          borderRadius: '5px',
                          color: '#333',
                          padding: '1rem'
                        }}>
                        <Latex>
                          <div dangerouslySetInnerHTML={{ __html: stateToHTML(richTextAnswer.getCurrentContent()) }} />
                        </Latex>
                      </div>
                    </div>
                  )}
                </>
              )}
              {questionType === 'mcq' && (
                <div className="mb-3">
                  <FormLabel component="legend">Answer</FormLabel>
                  {mcqOption.map((item, index) => (
                    <React.Fragment key={index}>
                      <div className="d-flex flex-row">
                        <Checkbox
                          checked={mcqAnswerIndex.includes(index)}
                          onChange={this.setMCQAnswer(index)}
                          color="primary"
                        />
                        <TextField
                          id="mcq"
                          label={`Answer ${index + 1} ${index > 1 ? '(optional)' : ''}`}
                          value={item}
                          onChange={this.onChangeAnswerOption(index)}
                          variant="outlined"
                          fullWidth={true}
                          margin="normal"
                        />
                      </div>
                    </React.Fragment>
                  ))}
                  <Button onClick={this.addMoreAnswerOption} color="primary">
                    Add more
                  </Button>
                </div>
              )}

              <div className="mt-3">
                <div className="text-muted">Cheat sheeet for math unit</div>
                <Latex>
                  <div className="manage-margin">
                    <CopyToClipboard text="\(cm^{2}\)">
                      <Chip label="\(cm^{2}\)" />
                    </CopyToClipboard>
                    <CopyToClipboard text="\(cm^{3}\)">
                      <Chip label="\(cm^{3}\)" />
                    </CopyToClipboard>
                    <CopyToClipboard text="\(m^{2}\)">
                      <Chip label="\(m^{2}\)" />
                    </CopyToClipboard>
                    <CopyToClipboard text="\(m^{3}\)">
                      <Chip label="\(m^{3}\)" />
                    </CopyToClipboard>
                    <CopyToClipboard text="\(^{o}C\)">
                      <Chip label="\(^{o}C\)" />
                    </CopyToClipboard>
                    <CopyToClipboard text="\(^{o}\)">
                      <Chip label="\(^{o}\)" />
                    </CopyToClipboard>
                  </div>
                </Latex>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button className="mr-2" variant="text" color="secondary" onClick={this.props.close}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={this.handleAdd}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default DialogAddQuestion
