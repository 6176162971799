import React from 'react'

import { Accordion, AccordionSummary, AccordionDetails, Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import { Check, ExpandMore } from '@material-ui/icons'
import _ from 'lodash'

const AccordionPollDetails = props => {
  const { userPollData, pollSetup, pollStat } = props

  let pollResult = ''
  let pollQuestions = []
  if (userPollData) {
    if (pollStat) {
      for (const [key, value] of Object.entries(pollStat)) {
        if (value.participant_count > 0) {
          let questionData = _.find(pollSetup, o => {
            return o.question === key
          })
          if (questionData) {
            pollQuestions.push(questionData)
          }
        }
      }
    }

    if (pollQuestions.length > 0) {
      let correctlyAnsweredQuestion = 0
      if (userPollData) {
        correctlyAnsweredQuestion = _.filter(userPollData, o => {
          return o.result?.result === 'Correct'
        }).length
        pollResult = `${correctlyAnsweredQuestion}/${pollQuestions.length}`
      } else {
        pollResult = `${correctlyAnsweredQuestion}/${pollQuestions.length}`
      }
    }
  } else {
    pollResult = 'No poll data'
  }

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />} style={{ backgroundColor: '#efefef' }}>
        {pollResult} (Expand for details)
      </AccordionSummary>
      <AccordionDetails>
        <Table>
          <TableBody>
            {pollQuestions.map((questionData, index) => {
              let correctAnswer = questionData.answer
              let correctAnswerLabel = _.filter(questionData.option, o => {
                return correctAnswer.includes(o.content)
              })

              return (
                <TableRow key={index}>
                  <TableCell>
                    <span>Q{questionData.question_index}</span>
                  </TableCell>
                  <TableCell>
                    <p>
                      <span className="text-muted">Question:</span> {questionData.question}
                    </p>
                    <p>
                      <span className="text-muted">Correct Answer:</span>{' '}
                      {_.map(correctAnswerLabel, 'label').join(', ')}
                    </p>
                  </TableCell>
                  <TableCell>
                    {questionData.option.map((opt, index) => {
                      let studentResult = _.find(userPollData, o => {
                        return o.question === questionData.question
                      })
                      let color = '#e0e0e0',
                        studentSelected = ''

                      if (studentResult) {
                        studentSelected = _.find(studentResult.result.student_answer, o => {
                          return o === opt.content
                        })
                        if (studentSelected) {
                          if (correctAnswer.includes(studentSelected)) {
                            color = '#4caf50'
                          } else {
                            color = '#E91E63'
                          }
                        }
                      }

                      return (
                        <div
                          key={index}
                          className="d-flex align-items-center mb-1 p-1"
                          style={{ border: `1px solid ${color}`, borderRadius: '3px' }}>
                          {studentSelected && <Check className="text-small" style={{ color: color }} />}
                          {opt.label}: {opt.content}
                        </div>
                      )
                    })}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  )
}

export default AccordionPollDetails
