import React from 'react'
import Proptypes from 'prop-types'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core'

import TicketStatusBadge from './badge-ticket-status'
import HistoryAccordion from './accordion-history'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const DialogTicketDetails = props => {
  const { isOpen, close, ticketData } = props

  let studentGender = 'N/A'
  if (ticketData) {
    if (ticketData.student.gender === 'm') {
      studentGender = 'Male'
    } else if (ticketData.student.gender === 'f') {
      studentGender = 'Female'
    }
  }

  return (
    <Dialog open={isOpen} onClose={close} TransitionComponent={Transition} fullWidth maxWidth="lg">
      <DialogTitle>
        <span style={{ fontSize: '24px' }}></span>
      </DialogTitle>
      <DialogContent>
        {ticketData && (
          <div className="row justify-content-center">
            <div className="col-6 mb-3 p-2">
              <h3>Student Details</h3>
              <Paper elevation={2}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>:</TableCell>
                      <TableCell>{ticketData.student.name}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Email</TableCell>
                      <TableCell>:</TableCell>
                      <TableCell>{ticketData.student.email}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Phone</TableCell>
                      <TableCell>:</TableCell>
                      <TableCell>
                        {ticketData.student.phone_country_code} {ticketData.student.phone}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Gender</TableCell>
                      <TableCell>:</TableCell>
                      <TableCell>{studentGender}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>School</TableCell>
                      <TableCell>:</TableCell>
                      <TableCell>{ticketData.student.school ?? 'N/A'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Language</TableCell>
                      <TableCell>:</TableCell>
                      <TableCell>{ticketData.student.first_language ?? 'N/A'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Background</TableCell>
                      <TableCell>:</TableCell>
                      <TableCell>
                        <div dangerouslySetInnerHTML={{ __html: ticketData.student.meta?.background ?? 'N/A' }} />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Parent</TableCell>
                      <TableCell>:</TableCell>
                      <TableCell>{ticketData.student.parent?.name ?? 'N/A'}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Paper>
            </div>
            <div className="col-6 mb-3 p-2">
              <h3>Class Details</h3>
              <Paper elevation={2} className="mb-3">
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>Class</TableCell>
                      <TableCell>:</TableCell>
                      <TableCell>{ticketData.class.name}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Paper>
              <h3>Ticket Details</h3>
              <Paper elevation={2}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>Status</TableCell>
                      <TableCell>:</TableCell>
                      <TableCell>
                        <TicketStatusBadge status={ticketData.status} />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Remark</TableCell>
                      <TableCell>:</TableCell>
                      <TableCell>{ticketData.remark || 'N/A'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Handle Person</TableCell>
                      <TableCell>:</TableCell>
                      <TableCell>{ticketData.handled_by?.name}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Previous Agent</TableCell>
                      <TableCell>:</TableCell>
                      <TableCell>{ticketData.meta?.prev_agent ?? 'N/A'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>History</TableCell>
                      <TableCell>:</TableCell>
                      <TableCell>
                        <HistoryAccordion history={ticketData.history} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Paper>
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary" variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DialogTicketDetails.propTypes = {
  isOpen: Proptypes.bool.isRequired,
  close: Proptypes.func.isRequired,
  ticketData: Proptypes.object
}

export default DialogTicketDetails
