import React from 'react'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField
} from '@material-ui/core'
import moment from 'moment'

class DialogUserDetail extends React.Component {
  state = {
    addCreditSectionVisible: false,
    addCreditQty: 0,
    addCreditReason: ''
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.addCreditSectionVisible && this.state.addCreditSectionVisible) {
      this.setState({
        addCreditQty: 0,
        addCreditReason: ''
      })
    }

    if (prevProps.addingCredit && !this.props.addingCredit) {
      this.props.getCredit()
      this.setState({ addCreditSectionVisible: false })
    }
  }

  toggleAddCredit = () => {
    this.setState({
      addCreditSectionVisible: !this.state.addCreditSectionVisible
    })
  }

  confirmAddCredit = () => {
    const { addCreditQty, addCreditReason } = this.state
    this.props.addCredit(addCreditQty, addCreditReason)()
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const { isOpen, toggleDialog, userDetail, canManageCredit, addingCredit } = this.props
    const { addCreditSectionVisible, addCreditQty, addCreditReason } = this.state

    const parent = userDetail?.parent ?? ''
    let creditDetails = userDetail?.credit
    let remainingCredit = 0
    if (creditDetails) {
      for (let i = 0; i < creditDetails.length; i++) {
        if (creditDetails[i].action === 'ADD') {
          remainingCredit = remainingCredit + creditDetails[i].qty
        } else if (creditDetails[i].action === 'REDEEM' || creditDetails.action === 'BLOCK') {
          remainingCredit = remainingCredit - creditDetails[i].qty
        }
      }
    }

    return (
      <Dialog open={isOpen} maxWidth="md" fullWidth onClose={toggleDialog(null)}>
        <DialogContent>
          {userDetail ? (
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Student Name
                  </TableCell>
                  <TableCell align="right">{userDetail.name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Email
                  </TableCell>
                  <TableCell align="right">
                    {userDetail.email}
                    {userDetail.e_verified ? (
                      <span className="tag success-tag ml-2">Verified</span>
                    ) : (
                      <span className="tag light-tag ml-2">Not Verify</span>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Phone number
                  </TableCell>
                  <TableCell align="right">
                    {userDetail.phone_country_code} - {userDetail.phone}
                    {userDetail.p_verified ? (
                      <span className="tag success-tag ml-2">Verified</span>
                    ) : (
                      <span className="tag light-tag ml-2">Not Verify</span>
                    )}
                  </TableCell>
                </TableRow>
                {userDetail?._type === 'Student' && (
                  <>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Parent
                      </TableCell>
                      <TableCell align="right">
                        {parent ? (
                          <div>
                            <span className="text-muted">Name:</span> {parent.salute} {parent.name}
                            <br />
                            <span className="text-muted">Email:</span> {parent.email}
                            <br />
                            <span className="text-muted">Phone:</span> {parent.phone_country_code} {parent.phone}
                            <br />
                            <span className="text-muted">Contact Preference:</span>&nbsp;
                            {parent.contact_preference?.whatsapp && <span>Whatsapp</span>}
                            {parent.contact_preference?.wechat && (
                              <span>Wechat ({parent.contact_preference?.wechat_id})</span>
                            )}
                            {parent.contact_preference?.others && <span>{parent.contact_preference.others}</span>}
                          </div>
                        ) : (
                          <>N/A</>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Referrer
                      </TableCell>
                      <TableCell align="right">
                        {userDetail.referred_by ? (
                          <span>
                            {userDetail.referred_by.name} ({userDetail.referred_by.email})
                          </span>
                        ) : (
                          <span>N/A</span>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Lead Source
                      </TableCell>
                      <TableCell align="right">
                        {userDetail.lead_source ? (
                          <div>
                            {Object.entries(userDetail.lead_source).map(([key, value]) => {
                              if (key === 'meta') {
                                return null
                              }
                              return (
                                <div key={key}>
                                  <span className="text-muted">{key}:</span> {value.toString()}
                                </div>
                              )
                            })}

                            {userDetail.lead_source.meta && (
                              <div>
                                {Object.entries(userDetail.lead_source.meta).map(([key, value]) => {
                                  return (
                                    <div key={key}>
                                      <span className="text-muted">{key}:</span> {value.toString()}
                                    </div>
                                  )
                                })}
                              </div>
                            )}
                          </div>
                        ) : (
                          <span>N/A</span>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        First purchase on
                      </TableCell>
                      <TableCell align="right">
                        {userDetail.first_purchased_on ? (
                          moment(userDetail.first_purchased_on).format('ll')
                        ) : (
                          <span>N/A</span>
                        )}
                      </TableCell>
                    </TableRow>
                  </>
                )}

                <TableRow>
                  <TableCell component="th" scope="row">
                    Registered on
                  </TableCell>
                  <TableCell align="right">{moment(userDetail.created).format('ll')}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row">
                    Credit
                  </TableCell>
                  <TableCell align="right">
                    <div>
                      {canManageCredit && (
                        <Button variant="text" color="primary" onClick={this.toggleAddCredit}>
                          Add
                        </Button>
                      )}
                      <Button variant="text" color="primary" onClick={this.props.getCredit}>
                        View
                      </Button>
                    </div>
                    {userDetail.credit && (
                      <>
                        <div>
                          Remaining Credit: <span className="font-weight-semibold">{remainingCredit}</span>
                        </div>
                        <Table className="mb-3">
                          <TableBody>
                            {userDetail.credit.map((creditData, index) => (
                              <TableRow key={index}>
                                <TableCell>{moment(creditData.created).format('ll')}</TableCell>
                                <TableCell>{creditData.action}</TableCell>
                                <TableCell>{creditData.qty}</TableCell>
                                <TableCell>{creditData.remark}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </>
                    )}
                    {addCreditSectionVisible && canManageCredit && (
                      <div>
                        <TextField
                          required
                          label="Quantity (max 100)"
                          type="number"
                          name="addCreditQty"
                          value={addCreditQty}
                          inputProps={{ min: 0, max: 100, step: 1 }}
                          onChange={this.handleChange}
                          className="mb-3"
                          fullWidth
                        />
                        <TextField
                          required
                          label="Reason"
                          name="addCreditReason"
                          onChange={this.handleChange}
                          defaultValue={addCreditReason}
                          className="mb-3"
                          fullWidth
                        />
                        <div className="text-right">
                          <Button
                            variant="text"
                            color="primary"
                            className="mr-2"
                            onClick={this.toggleAddCredit}
                            disabled={addingCredit}>
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={this.confirmAddCredit}
                            disabled={addingCredit}>
                            Confirm Add Credit
                            {addingCredit && <CircularProgress color="primary" size={20} className="ml-2" />}
                          </Button>
                        </div>
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          ) : (
            <h3>Opps, something wrong...</h3>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={toggleDialog(null)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default DialogUserDetail
