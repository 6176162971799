import React from 'react'
import { connect } from 'react-redux'
import CKEditor from 'react-ckeditor-component'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Slide,
  TextField
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import Proptypes from 'prop-types'

import agent from 'agent'
import { UPDATE_USER } from 'constants/actionTypes'

const mapStateToProps = state => ({
  isLoading: state.user.isLoading,
  userUpdated: state.user.userUpdated
})

const mapDispatchToProps = dispatch => ({
  updateUser: (userType, userId, userObj) =>
    dispatch({ type: UPDATE_USER, payload: agent.User.updateUser(userType, userId, userObj), studentId: userId })
})

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

class DialogUpdateStudent extends React.Component {
  state = {
    alertMessage: '',
    gender: '',
    school: '',
    firstLanguage: '',
    studentBackground: ''
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      const { studentData } = this.props

      this.setState({
        gender: studentData.gender ?? '',
        school: studentData.school ?? '',
        firstLanguage: studentData.first_language ?? '',
        studentBackground: studentData?.meta?.background ?? ''
      })
    }

    if (prevProps.isOpen && !this.props.isOpen) {
      this.setState({ gender: '', school: '', firstLanguage: '', studentBackground: '', alertMessage: '' })
    }

    if (prevProps.isLoading && !this.props.isLoading) {
      if (this.props.isOpen) {
        this.props.close()
      }
    }
  }

  handleChange = event => {
    const type = event.target.name
    const value = event.target.value
    this.setState({ [type]: value })
  }

  handleCKEditorChange = targetName => event => {
    this.setState({
      [targetName]: event.editor.getData(),
      alertMessage: ''
    })
  }

  clickUpdateBtn = () => {
    const { studentData } = this.props
    const { gender, school, firstLanguage, studentBackground } = this.state

    let updateParams = {}

    if (studentBackground) {
      updateParams['meta'] = { background: studentBackground }
    }
    if (gender) {
      updateParams['gender'] = gender
    }
    if (school) {
      updateParams['school'] = school
    }
    if (firstLanguage) {
      updateParams['first_language'] = firstLanguage
    }

    this.props.updateUser(studentData._type, studentData._id, updateParams)
  }

  render() {
    const { isOpen, close, studentData, isLoading } = this.props
    const { gender, school, firstLanguage, studentBackground, alertMessage } = this.state

    return (
      <Dialog open={isOpen} TransitionComponent={Transition} maxWidth="md" fullWidth>
        <DialogTitle>
          <span style={{ fontSize: '24px' }}>
            Update Student:&nbsp;
            {studentData && (
              <>
                {studentData.name} ({studentData.email ?? '---'})
              </>
            )}
          </span>
        </DialogTitle>
        <DialogContent>
          {studentData && (
            <div className="row justify-content-center">
              <div className="col-12 mb-3">
                <FormControl>
                  <FormLabel component="legend">Gender</FormLabel>
                  <RadioGroup row aria-label="gender" name="gender" value={gender} onChange={this.handleChange}>
                    <FormControlLabel value="f" control={<Radio color="primary" />} label="Female" />
                    <FormControlLabel value="m" control={<Radio color="primary" />} label="Male" />
                  </RadioGroup>
                </FormControl>
              </div>
              <div className="col-12 mb-3">
                <FormControl className="w-100">
                  <FormLabel component="legend">First language</FormLabel>
                  <TextField
                    type="text"
                    name="firstLanguage"
                    onChange={this.handleChange}
                    value={firstLanguage}
                    fullWidth
                  />
                </FormControl>
              </div>
              <div className="col-12 mb-3">
                <FormLabel component="legend">School</FormLabel>
                <TextField
                  className="w-100 mb-3"
                  type="text"
                  name="school"
                  onChange={this.handleChange}
                  value={school}
                />
              </div>

              <div className="col-12">
                {alertMessage && (
                  <Alert severity="error" className="mb-3">
                    {alertMessage}
                  </Alert>
                )}
                <FormLabel component="legend">Background</FormLabel>
                <FormControl className="w-100">
                  <CKEditor
                    name="studentBackground"
                    content={studentBackground}
                    config={{
                      toolbarGroups: [
                        { name: 'basicstyles', groups: ['basicstyles', 'Underline'] },
                        { name: 'paragraph', groups: ['list', 'align', 'bidi', 'links'] }
                      ]
                    }}
                    events={{
                      change: this.handleCKEditorChange('studentBackground')
                    }}
                  />
                </FormControl>
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="primary" variant="text">
            Close
          </Button>
          <Button onClick={this.clickUpdateBtn} color="primary" variant="contained" disabled={isLoading}>
            Update {isLoading && <CircularProgress size={15} className="text-white" />}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

DialogUpdateStudent.propTypes = {
  isOpen: Proptypes.bool.isRequired,
  close: Proptypes.func.isRequired,
  studentData: Proptypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogUpdateStudent)
