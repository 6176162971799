import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Paper from '@material-ui/core/Paper'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { Clear } from '@material-ui/icons'

import agent from 'agent'
import * as Type from 'constants/actionTypes'

const mapStateToProps = state => ({
  tutors: state.user.tutorList,
  TAs: state.user.taList,
  creatingLesson: state.lessons.creatingLesson,
  lessonCreated: state.lessons.lessonCreated
})

const mapDispatchToProps = dispatch => ({
  getUsers: (filter, offset, userType) =>
    dispatch({ type: Type.GET_USERS, payload: agent.User.getUsers({ ...filter, _type: userType }, offset), userType })
})

class DialogAddLessonBatch extends React.Component {
  constructor(props) {
    super()

    let { targetClass } = props

    this.state = {
      lessons: [
        {
          _id: Math.random().toString(36),
          name: '',
          desc: '',
          scheduled_on: moment(props.targetClass.schedule.start_time).format('YYYY-MM-DDTHH:mm'),
          ended_on: moment(props.targetClass.schedule.end_time).format('HH:mm'),
          index: '',
          term: ''
        }
      ],
      hosting_platform: 'Tenopy Classroom',
      hosting_type: 'webinar',
      scheduled_on: moment(props.targetClass.schedule.start_time).format('YYYY-MM-DDTHH:mm'),
      ended_on: moment(props.targetClass.schedule.end_time).format('HH:mm'),
      class: targetClass._id,
      tutor: targetClass.tutor,
      ta: [],
      desc: '',
      searchTerm: '',
      searchField: 'name',
      tutorSelectModalOpen: false,
      taSelectModalOpen: false,
      is_released: true,
      is_active: true,
      autoPopulateLessonCount: 5,
      saveInProgress: false
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.creatingLesson && !this.props.creatingLesson && this.props.lessonCreated) {
      let { lessons } = this.state
      lessons.shift()
      this.setState({ lessons })
      if (lessons.length > 0) {
        this.saveLesson()
      } else {
        this.setState({ saveInProgress: false })
        this.props.toggle()
      }
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleCheckboxChange = (e, checked) => {
    this.setState({ [e.target.name]: checked })
  }

  handleArrayDataChange = index => event => {
    let updatedLessons = this.state.lessons
    updatedLessons[index][event.target.name] = event.target.value

    this.setState({
      lessons: updatedLessons
    })
  }

  toggleTutorSelectModal = index => () => {
    this.setState({
      tutorSelectModalOpen: !this.state.tutorSelectModalOpen,
      targetIndex: index
    })
  }

  toggleTASelectModal = index => () => {
    this.setState({
      taSelectModalOpen: !this.state.taSelectModalOpen,
      targetIndex: index
    })
  }

  searchUser = e => {
    e.preventDefault()
    const { searchTerm, searchField } = this.state

    let filter = { [searchField]: { $regex: `${searchTerm}`, $options: 'i' } }

    this.props.getUsers(filter, 0, e.target.user_type.value)
  }

  selectTutor = selectedTutor => () => {
    let { tutor, targetIndex } = this.state
    let tutorCopy = [...tutor]

    if (typeof targetIndex === 'undefined') {
      tutorCopy.push(selectedTutor)
    } else {
      tutorCopy[targetIndex] = selectedTutor
    }

    this.setState({ tutor: tutorCopy })
    this.toggleTutorSelectModal()
  }

  removeTutor = index => () => {
    let { tutor } = this.state

    let tutorCopy = [...tutor]

    tutorCopy.splice(index, 1)

    this.setState({ tutor: tutorCopy })
  }

  selectTA = selectedTA => () => {
    let { ta, targetIndex } = this.state
    let taCopy = [...ta]

    if (typeof targetIndex === 'undefined') {
      taCopy.push(selectedTA)
    } else {
      taCopy[targetIndex] = selectedTA
    }

    this.setState({ ta: taCopy })
    this.toggleTASelectModal()
  }

  removeTA = index => () => {
    let { ta } = this.state

    let taCopy = [...ta]

    taCopy.splice(index, 1)

    this.setState({ ta: taCopy })
  }

  saveLesson = () => {
    let { lessons, hosting_platform, hosting_type, tutor, ta, is_active, is_released } = this.state
    let endedOnMoment = moment(lessons[0].ended_on, 'HH:mm')
    let correctedEndedOn = moment(lessons[0].scheduled_on, 'YYYY-MM-DDTHH:mm')
      .hour(endedOnMoment.hour())
      .minute(endedOnMoment.minute())
    let scheduledOnMoment = moment(lessons[0].scheduled_on, 'YYYY-MM-DDTHH:mm')

    let lessonData = {
      name: lessons[0].name,
      desc: lessons[0].desc,
      meta: { index: lessons[0].index, term: lessons[0].term },
      hosting_platform,
      hosting_type,
      scheduled_on: scheduledOnMoment.toISOString(),
      ended_on: correctedEndedOn.toISOString(),
      class: this.state.class,
      tutor: tutor.map(t => {
        return t._id
      }),
      ta: _.map(ta, '_id'),
      is_active,
      is_released
    }
    this.props.onSave(lessonData)
    this.setState({ saveInProgress: true })
  }

  addLessonToArray = () => {
    let { lessons } = this.state

    let lastLesson = lessons[lessons.length - 1]
    let newLesson = {
      _id: Math.random().toString(36),
      name: '',
      desc: '',
      scheduled_on: moment(lastLesson.scheduled_on).add(7, 'days').format('YYYY-MM-DDTHH:mm'),
      ended_on: lastLesson.ended_on,
      index: lastLesson.index,
      term: lastLesson.term
    }
    lessons.push(newLesson)

    this.setState({ lessons })
  }

  removeLessonFromArray = index => () => {
    let { lessons } = this.state
    if (lessons.length === 1) return

    let updatedLessons = []
    for (let i = 0; i < lessons.length; i++) {
      if (index !== i) updatedLessons.push(lessons[i])
    }

    this.setState({ lessons: updatedLessons })
  }

  autoPopulateLessons = () => {
    let { autoPopulateLessonCount } = this.state
    let { targetClass } = this.props
    let lessons = []

    let endTime = moment(targetClass.schedule.end_time).format('HH:mm')

    for (let i = 0; i < autoPopulateLessonCount; i++) {
      let firstLessonDate = targetClass.schedule.start_time
      let lessonScheduledOnDate = moment(firstLessonDate)
        .add(i * 7, 'days')
        .format('YYYY-MM-DDTHH:mm')
      let newLesson = {
        _id: Math.random().toString(36),
        name: '',
        desc: '',
        scheduled_on: lessonScheduledOnDate,
        ended_on: endTime,
        index: i + 1,
        term: ''
      }
      lessons.push(newLesson)
    }

    this.setState({ lessons })
  }

  render() {
    let { isOpen, toggle, tutors, TAs } = this.props
    let {
      lessons,
      hosting_platform,
      hosting_type,
      tutor,
      tutorSelectModalOpen,
      ta,
      taSelectModalOpen,
      searchTerm,
      searchField,
      is_released,
      is_active,
      autoPopulateLessonCount,
      saveInProgress
    } = this.state

    return (
      <Dialog disableEnforceFocus open={isOpen} onClose={toggle} fullScreen={true}>
        <AppBar className="position-relative">
          <Toolbar>
            <IconButton onClick={toggle} aria-label="Close" className={'text-white'}>
              <CloseIcon />
            </IconButton>
            Batch Create Lesson
          </Toolbar>
        </AppBar>
        <DialogContent style={{ padding: '24px' }}>
          <div className="row">
            <div className="col-12">
              <Paper elevation={2} className={'app-wrapper border mb-3'}>
                <div className="d-flex flex-row align-items-start py-2 justify-content-between border-bottom">
                  <h2 className="mb-0 font-weight-semibold">Lesson Details</h2>
                  <div>
                    <TextField
                      required
                      label="Lessons Count"
                      name="autoPopulateLessonCount"
                      onChange={this.handleChange}
                      defaultValue={autoPopulateLessonCount}
                      margin="none"
                    />
                    <Button variant="outlined" color="primary" onClick={this.autoPopulateLessons}>
                      Populate
                    </Button>
                  </div>
                </div>

                {lessons.map((lesson, index) => (
                  <div className="row" key={lesson._id}>
                    <div
                      className="col-1 text-center pt-2"
                      style={{ backgroundColor: index % 2 === 0 ? '#efefef' : '#c7ebf3' }}>
                      <div className="font-weight-semibold" style={{ fontSize: '20px' }}>
                        {index + 1}
                      </div>
                      <Clear className="text-danger clickable my-3" onClick={this.removeLessonFromArray(index)} />
                    </div>
                    <div className="col-11">
                      <FormControl className="w-100 mb-3">
                        <TextField
                          required
                          label="Lesson Name"
                          name={'name'}
                          onChange={this.handleArrayDataChange(index)}
                          defaultValue={lesson.name}
                          margin="none"
                        />
                      </FormControl>
                      <FormControl className="w-100 mb-3">
                        <TextField
                          label="Lesson Description"
                          name={'desc'}
                          onChange={this.handleArrayDataChange(index)}
                          defaultValue={lesson.desc}
                          multiline
                          margin="none"
                        />
                      </FormControl>
                      <div className="row mb-2">
                        <div className="col">
                          <TextField
                            label={'Lesson Start Time & Date'}
                            type={'datetime-local'}
                            name={'scheduled_on'}
                            fullWidth
                            value={lesson.scheduled_on}
                            onChange={this.handleArrayDataChange(index)}
                          />
                        </div>
                        <div className="col-4">
                          <TextField
                            label={'Lesson End Time'}
                            type={'time'}
                            name={'ended_on'}
                            fullWidth
                            value={lesson.ended_on}
                            onChange={this.handleArrayDataChange(index)}
                          />
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-3">
                          <TextField
                            label={'Lesson Order'}
                            name={'index'}
                            fullWidth
                            value={lesson.index}
                            onChange={this.handleArrayDataChange(index)}
                          />
                        </div>
                        <div className="col-3">
                          <TextField
                            label={'Lesson Term'}
                            name={'term'}
                            fullWidth
                            value={lesson.term}
                            onChange={this.handleArrayDataChange(index)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                <div className="text-right">
                  <Button variant={'contained'} color={'primary'} onClick={this.addLessonToArray}>
                    Add a lesson
                  </Button>
                </div>
              </Paper>
            </div>

            <div className="col">
              <Paper elevation={2} className={'app-wrapper mb-3'}>
                <h2 className="font-weight-semibold border-bottom">Tutor currently assigned</h2>
                {tutor.length > 0 &&
                  tutor.map((t, index) => {
                    return (
                      <div key={t._id} className="row">
                        <div className="col d-flex align-items-center">
                          <h3>
                            <span className={'text-capitalize'}>{t.name}</span>
                            <div className="text-muted">
                              <small>{t.email}</small>
                            </div>
                          </h3>
                        </div>
                        <div className="col-auto">
                          <Button variant={'contained'} color={'default'} onClick={this.toggleTutorSelectModal(index)}>
                            Change teacher
                          </Button>
                          <Button className={'ml-2'} onClick={this.removeTutor(index)}>
                            Remove
                          </Button>
                        </div>
                      </div>
                    )
                  })}
                <Button variant={'contained'} color={'primary'} onClick={this.toggleTutorSelectModal()}>
                  Add Teacher
                </Button>

                {/*Select Tutor Dialog*/}
                <Dialog onClose={this.toggleTutorSelectModal()} open={tutorSelectModalOpen} fullWidth={true}>
                  <DialogTitle>Select a teacher</DialogTitle>
                  <div className="app-wrapper pt-0">
                    <form onSubmit={this.searchUser}>
                      <input type="hidden" name="user_type" value="Tutor" />
                      <div className="row">
                        <div className="col">
                          <TextField
                            label={'Tutor Name/Email'}
                            name={'searchTerm'}
                            value={searchTerm}
                            onChange={this.handleChange}
                            fullWidth
                          />
                        </div>
                        <div className="col-auto d-flex align-items-end">
                          <Button type={'submit'} variant={'contained'}>
                            Search
                          </Button>
                        </div>
                        <div className="col-auto d-flex align-items-end">
                          <Button type={'button'} variant={'text'} onClick={this.clearSearch}>
                            Clear
                          </Button>
                        </div>
                        <div className="col-12">
                          <FormControl component="fieldset" required>
                            <RadioGroup
                              className="d-flex flex-row"
                              aria-label="searchField"
                              name="searchField"
                              value={searchField}
                              onChange={this.handleChange}>
                              <FormControlLabel value="name" control={<Radio color="primary" />} label="Name" />
                              <FormControlLabel value="email" control={<Radio color="primary" />} label="Email" />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div>
                    {!!tutors && tutors.length > 0 ? (
                      <List disablePadding={true}>
                        {tutors.map(user => (
                          <ListItem button onClick={this.selectTutor(user)} key={user._id}>
                            <ListItemText primary={user.name} secondary={user.email} />
                          </ListItem>
                        ))}
                      </List>
                    ) : (
                      <div className="app-wrapper">
                        <h4 className={'font-weight-semibold'}>No tutors found</h4>
                      </div>
                    )}
                  </div>
                </Dialog>
                {/*Select Tutor Dialog END*/}
              </Paper>

              <Paper elevation={2} className={'app-wrapper mb-3'}>
                <h2 className="font-weight-semibold border-bottom">TA assigned</h2>
                {ta.length > 0 &&
                  ta.map((t, index) => {
                    return (
                      <div key={t._id} className="row">
                        <div className="col d-flex align-items-center">
                          <h3>
                            <span className={'text-capitalize'}>{t.name}</span>
                            <div className="text-muted">
                              <small>{t.email}</small>
                            </div>
                          </h3>
                        </div>
                        <div className="col-auto">
                          <Button variant={'contained'} color={'default'} onClick={this.toggleTASelectModal(index)}>
                            Change TA
                          </Button>
                          <Button className={'ml-2'} onClick={this.removeTA(index)}>
                            Remove
                          </Button>
                        </div>
                      </div>
                    )
                  })}
                <Button variant={'contained'} color={'primary'} onClick={this.toggleTASelectModal()}>
                  Add TA
                </Button>

                {/*Select TA Dialog*/}
                <Dialog onClose={this.toggleTASelectModal()} open={taSelectModalOpen} fullWidth={true}>
                  <DialogTitle>Select a TA</DialogTitle>
                  <div className="app-wrapper pt-0">
                    <form onSubmit={this.searchUser}>
                      <input type="hidden" name="user_type" value="TA" />
                      <div className="row">
                        <div className="col">
                          <TextField
                            label={'Tutor Name/Email'}
                            name={'searchTerm'}
                            value={searchTerm}
                            onChange={this.handleChange}
                            fullWidth
                          />
                        </div>
                        <div className="col-auto d-flex align-items-end">
                          <Button type={'submit'} variant={'contained'}>
                            Search
                          </Button>
                        </div>
                        <div className="col-auto d-flex align-items-end">
                          <Button type={'button'} variant={'text'} onClick={this.clearSearch}>
                            Clear
                          </Button>
                        </div>
                        <div className="col-12">
                          <FormControl component="fieldset" required>
                            <RadioGroup
                              className="d-flex flex-row"
                              aria-label="searchField"
                              name="searchField"
                              value={searchField}
                              onChange={this.handleChange}>
                              <FormControlLabel value="name" control={<Radio color="primary" />} label="Name" />
                              <FormControlLabel value="email" control={<Radio color="primary" />} label="Email" />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div>
                    {!!TAs && TAs.length > 0 ? (
                      <List disablePadding={true}>
                        {TAs.map(user => (
                          <ListItem button onClick={this.selectTA(user)} key={user._id}>
                            <ListItemText primary={user.name} secondary={user.email} />
                          </ListItem>
                        ))}
                      </List>
                    ) : (
                      <div className="app-wrapper">
                        <h4 className={'font-weight-semibold'}>No ta found</h4>
                      </div>
                    )}
                  </div>
                </Dialog>
                {/*Select Tutor Dialog END*/}
              </Paper>
            </div>
            <div className="col">
              <Paper elevation={2} className={'app-wrapper mb-3'}>
                <h2 className="font-weight-semibold border-bottom">Lesson Hosting</h2>
                <FormControl className="w-100 mb-3">
                  <RadioGroup
                    className="d-flex flex-row"
                    aria-label="hosting_platform"
                    name="hosting_platform"
                    value={hosting_platform}
                    onChange={this.handleChange}>
                    <FormControlLabel
                      value="Tenopy Classroom"
                      control={<Radio color="primary" />}
                      label="Tenopy Classroom"
                    />
                    <FormControlLabel value="Zoom" control={<Radio color="primary" />} label="Zoom" />
                  </RadioGroup>
                </FormControl>
                {hosting_platform === 'Zoom' && (
                  <FormControl className="w-100 mb-3">
                    <RadioGroup
                      className="d-flex flex-row"
                      aria-label="hosting_type"
                      name="hosting_type"
                      value={hosting_type}
                      onChange={this.handleChange}>
                      <FormControlLabel value="meeting" control={<Radio color="primary" />} label="Meeting" />
                      <FormControlLabel value="webinar" control={<Radio color="primary" />} label="Webinar" />
                      <FormControlLabel value="1-to-1" control={<Radio color="primary" />} label="1 to 1" />
                    </RadioGroup>
                  </FormControl>
                )}
              </Paper>
              <Paper elevation={2} className={'app-wrapper mb-3'}>
                <h2 className="font-weight-semibold border-bottom">Lesson Status</h2>
                <FormControl className={''}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        name={'is_active'}
                        checked={is_active}
                        onChange={this.handleCheckboxChange}
                      />
                    }
                    label="Active"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        name={'is_released'}
                        checked={is_released}
                        onChange={this.handleCheckboxChange}
                      />
                    }
                    label="Released"
                  />
                </FormControl>
              </Paper>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button disabled={saveInProgress} variant="contained" color="primary" onClick={this.saveLesson}>
            Save All Lesson
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

DialogAddLessonBatch.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  lesson: PropTypes.object,
  targetClass: PropTypes.object.isRequired,
  onSave: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogAddLessonBatch)
