import React from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Chip,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Paper,
  CircularProgress
} from '@material-ui/core'
import moment from 'moment'
import _ from 'lodash'

import agent from 'agent'
import { GET_TICKETS_STAT } from 'constants/actionTypes'

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  loading: state.report.loadingTicketsStat,
  ticketsStat: state.report.ticketsStat
})

const mapDispatchToProps = dispatch => ({
  getTicketsStat: (startDate, endDate) =>
    dispatch({ type: GET_TICKETS_STAT, payload: agent.Report.getTicketsStat(startDate, endDate) })
})

class Tickets extends React.Component {
  state = {
    fromDate: moment().startOf('month').format('YYYY-MM-DD'),
    toDate: moment().endOf('month').format('YYYY-MM-DD'),
    trialStat: {
      totalTicketCount: 0,
      wonTicketCount: 0,
      loseTicketCount: 0,
      pendingTicketCount: 0,
      uniqUserCount: 0,
      uniqWonUserCount: 0,
      wonValue: 0,
      subjectBreakdown: []
    },
    renewalStat: {
      totalTicketCount: 0,
      renewedCount: 0,
      droppedCount: 0,
      pendingTicketCount: 0,
      uniqRenewedUserCount: 0,
      uniqDroppedUserCount: 0,
      renewedValue: 0,
      subjectBreakdown: []
    }
  }

  componentDidMount() {
    this.clickGenerate()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading && !this.state.loading) {
      this.processData()
    }
  }

  processData = () => {
    const { ticketsStat } = this.props

    if (!ticketsStat) return

    const { trial: trialTickets, renewal: renewalTickets } = ticketsStat

    const totalTrialTicket = trialTickets.length
    const wonTickets = _.filter(trialTickets, o => {
      return o.status === 'CLOSED WON'
    })
    const loseTickets = _.filter(trialTickets, o => {
      return o.status === 'CLOSED LOSE'
    })
    const allUniqTrialUser = _.uniq(_.map(trialTickets, 'student'))
    const allUniqWonUser = _.uniq(_.map(wonTickets, 'student'))
    const totalConvertedValue = _.sumBy(wonTickets, 'purchase_order.actual_price')
    //const trialBySubject = _.groupBy(trialTickets, 'class.subject')

    this.setState({
      trialStat: {
        totalTicketCount: totalTrialTicket,
        wonTicketCount: wonTickets.length,
        loseTicketCount: loseTickets.length,
        pendingTicketCount: totalTrialTicket - wonTickets.length - loseTickets.length,
        uniqUserCount: allUniqTrialUser.length,
        uniqWonUserCount: allUniqWonUser.length,
        wonValue: totalConvertedValue
      }
    })

    const totalRenewalTicket = renewalTickets.length
    const renewedTicket = _.filter(renewalTickets, o => {
      return o.status === 'RENEWED'
    })
    const droppedTicket = _.filter(renewalTickets, o => {
      return o.status === 'DROPPED'
    })
    const allUniqRenewedUser = _.uniq(_.map(renewedTicket, 'student'))
    const allUniqDroppedUser = _.uniq(_.map(droppedTicket, 'student'))
    const renewedValue = _.sumBy(renewedTicket, 'renewal_order.actual_price')
    //const renewalBySubject = _.groupBy(renewalTickets, 'class.subject')

    this.setState({
      renewalStat: {
        totalTicketCount: totalRenewalTicket,
        renewedCount: renewedTicket.length,
        droppedCount: droppedTicket.length,
        uniqRenewedUserCount: allUniqRenewedUser.length,
        uniqDroppedUserCount: allUniqDroppedUser.length,
        pendingTicketCount: totalRenewalTicket - renewedTicket.length - droppedTicket.length,
        renewedValue
      }
    })
  }

  handleDateRangeSelect = option => () => {
    let fromDate, toDate
    switch (option) {
      case 1:
        fromDate = moment().startOf('week').format('YYYY-MM-DD')
        toDate = moment().endOf('week').format('YYYY-MM-DD')
        break
      case 2:
        fromDate = moment().subtract(2, 'week').startOf('week').format('YYYY-MM-DD')
        toDate = moment().subtract(1, 'week').endOf('week').format('YYYY-MM-DD')
        break
      case 3:
        fromDate = moment().startOf('month').format('YYYY-MM-DD')
        toDate = moment().endOf('month').format('YYYY-MM-DD')
        break
      case 4:
        fromDate = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
        toDate = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
        break
      default:
        break
    }

    this.setState({ fromDate, toDate })
  }

  clickGenerate = () => {
    const { fromDate, toDate } = this.state
    const startDate = moment(`${fromDate}T00:00:00Z`).startOf('day').toISOString()
    const endDate = moment(`${toDate}T00:00:00Z`).endOf('day').toISOString()
    this.props.getTicketsStat(startDate, endDate)
  }

  render() {
    const { loading } = this.props
    const { fromDate, toDate, trialStat, renewalStat } = this.state

    return (
      <div className="app-wrapper">
        {loading && (
          <div className="text-center my-5">
            <CircularProgress color="primary" size={40} />
          </div>
        )}
        <div className="row mb-3">
          <div className="col-12">
            <div className="d-flex align-items-center manage-margin mb-1">
              <TextField
                variant="outlined"
                type="date"
                label="From"
                name="fromDate"
                value={fromDate}
                onChange={this.handleChange}
              />

              <TextField
                variant="outlined"
                type="date"
                label="To"
                name="toDate"
                value={toDate}
                onChange={this.handleChange}
              />

              <Button variant="contained" color="primary" onClick={this.clickGenerate}>
                Generate
              </Button>
            </div>
            <div className="d-flex">
              <Chip
                size="small"
                label="this week"
                className="bg-blue-gray px-1 mr-1"
                onClick={this.handleDateRangeSelect(1)}
              />

              <Chip
                size="small"
                label="last 2 weeks"
                className="bg-blue-gray px-1 mr-1"
                onClick={this.handleDateRangeSelect(2)}
              />

              <Chip
                size="small"
                label="this month"
                className="bg-blue-gray px-1 mr-1"
                onClick={this.handleDateRangeSelect(3)}
              />

              <Chip
                size="small"
                label="last month"
                className="bg-blue-gray px-1 mr-1"
                onClick={this.handleDateRangeSelect(4)}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <Paper elevation={3}>
              <div
                className="text-white p-3"
                style={{
                  backgroundColor: 'rgb(192, 118, 251)',
                  backgroundImage: 'linear-gradient(rgb(192, 118, 251) -18.96%, rgb(119, 23, 194) 108.17%)'
                }}>
                <h1 className="mb-0">Trial Tickets Stat</h1>
              </div>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Total Trial Tickets</TableCell>
                    <TableCell>:</TableCell>
                    <TableCell>{trialStat.totalTicketCount}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total Uniq User</TableCell>
                    <TableCell>:</TableCell>
                    <TableCell>{trialStat.uniqUserCount}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total Won Tickets</TableCell>
                    <TableCell>:</TableCell>
                    <TableCell>{trialStat.wonTicketCount}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total Uniq Won Users</TableCell>
                    <TableCell>:</TableCell>
                    <TableCell>{trialStat.uniqWonUserCount}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total Converted Value</TableCell>
                    <TableCell>:</TableCell>
                    <TableCell>$ {trialStat.wonValue}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total Lose Tickets</TableCell>
                    <TableCell>:</TableCell>
                    <TableCell>{trialStat.loseTicketCount}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total Pending Tickets</TableCell>
                    <TableCell>:</TableCell>
                    <TableCell>{trialStat.pendingTicketCount}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </div>

          <div className="col-6">
            <Paper elevation={3}>
              <div
                className="text-white p-3"
                style={{
                  backgroundColor: 'rgb(90, 185, 254)',
                  backgroundImage: 'linear-gradient(rgb(90, 185, 254) -18.96%, rgb(19, 114, 183) 108.17%)'
                }}>
                <h1 className="mb-0">Renewal Tickets Stat</h1>
              </div>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Total Renewal Tickets</TableCell>
                    <TableCell>:</TableCell>
                    <TableCell>{renewalStat.totalTicketCount}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total Renewed Count</TableCell>
                    <TableCell>:</TableCell>
                    <TableCell>{renewalStat.renewedCount}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total Uniq Renewed Users</TableCell>
                    <TableCell>:</TableCell>
                    <TableCell>{renewalStat.uniqRenewedUserCount}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total Renewed Value</TableCell>
                    <TableCell>:</TableCell>
                    <TableCell>$ {renewalStat.renewedValue}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total Dropped Count</TableCell>
                    <TableCell>:</TableCell>
                    <TableCell>{renewalStat.droppedCount}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total Uniq Dropped Users</TableCell>
                    <TableCell>:</TableCell>
                    <TableCell>{renewalStat.uniqDroppedUserCount}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total Pending Tickets</TableCell>
                    <TableCell>:</TableCell>
                    <TableCell>{renewalStat.pendingTicketCount}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </div>
        </div>
        {/*<div className="row">
          <div className="col-3">
            <Card
              className="text-white"
              style={{
                backgroundColor: 'rgb(142, 73, 240)',
                backgroundImage: 'linear-gradient(rgb(142, 73, 240) -18.96%, rgb(73, 4, 171) 108.17%)'
              }}>
              <CardContent>
                1000
                <br/>
                Total trial
              </CardContent>
            </Card>
          </div>
        </div>*/}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tickets)
