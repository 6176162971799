import React from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select
} from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import { Done as CorrectIcon } from '@material-ui/icons'

import agent from 'agent'
import { GET_QUESTION_LIST } from 'constants/actionTypes'
import { AVAILABLE_SUBJECT, AVAILABLE_LEVEL, MATH_TOPICS } from './constant'
import AddNewQuestionDialog from './dialog-add-question'
import TagQuestionDialog from './dialog-tag-question'
import Latex from 'components/editor/latex-renderer'

const mapStateToProps = state => ({
  loading: state.question.loadingList,
  questionList: state.question.list
})

const mapDispatchToProps = dispatch => ({
  getList: (filter, perPage, page) =>
    dispatch({ type: GET_QUESTION_LIST, payload: agent.Question.getList(filter, perPage, page), filter })
})

const PER_PAGE = 30

class QuestionBank extends React.Component {
  state = {
    selectedSubject: 'Mathematics',
    selectedLevel: ['primary-6'],
    selectedMathTopic: '',
    isAddNewDialogOpen: false,
    isTagQuestionDialogOpen: false,
    selectedQuestionId: '',
    pageCount: 0,
    currentPage: 1,
    refreshPagination: false
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading && !this.props.loading && this.state.refreshPagination) {
      let { questionList } = this.props
      let pageCount = Math.ceil(questionList.count / PER_PAGE)
      this.setState({
        refreshPagination: false,
        pageCount,
        currentPage: 1
      })
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  clickSearch = () => {
    const { selectedSubject, selectedLevel, selectedMathTopic } = this.state
    let filter = {
      subject: selectedSubject,
      level: selectedLevel.map(level => level.toLowerCase())
    }
    if (selectedSubject === 'Mathematics') {
      if (selectedMathTopic) {
        filter['meta.topic'] = selectedMathTopic
      }
    }
    this.setState({ refreshPagination: true })
    this.props.getList(filter, PER_PAGE, 1)
  }

  changePage = (event, page) => {
    const { selectedSubject, selectedLevel, selectedMathTopic } = this.state
    let filter = {
      subject: selectedSubject,
      level: selectedLevel.map(level => level.toLowerCase())
    }
    if (selectedSubject === 'Mathematics') {
      if (selectedMathTopic) {
        filter['meta.topic'] = selectedMathTopic
      }
    }

    this.props.getList(filter, PER_PAGE, page)
    this.setState({ currentPage: page })
  }

  toggleAddNewDialog = () => {
    this.setState({ isAddNewDialogOpen: !this.state.isAddNewDialogOpen })
  }

  toggleTagQuestionDialog = questionId => () => {
    this.setState({
      selectedQuestionId: questionId,
      isTagQuestionDialogOpen: !this.state.isTagQuestionDialogOpen
    })
  }

  render() {
    const { loading, questionList } = this.props
    const {
      selectedSubject,
      selectedLevel,
      selectedMathTopic,
      isAddNewDialogOpen,
      isTagQuestionDialogOpen,
      selectedQuestionId,
      pageCount,
      currentPage
    } = this.state

    return (
      <div className="app-wrapper">
        <div className="text-right mb-3">
          <Button variant="contained" color="primary" onClick={this.toggleAddNewDialog}>
            Add New Question
          </Button>
        </div>

        <Paper className="p-3 mb-3" elevation={3}>
          <div className="d-flex align-items-center">
            <FormControl variant="outlined" className="mr-2">
              <InputLabel htmlFor="subject">Subject</InputLabel>
              <Select name="selectedSubject" value={selectedSubject} onChange={this.handleChange} label="Subject">
                {AVAILABLE_SUBJECT.map(subject => (
                  <MenuItem value={subject} key={subject}>
                    {subject}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl variant="outlined" className="mr-2">
              <InputLabel htmlFor="level">Level</InputLabel>
              <Select
                multiple
                name="selectedLevel"
                value={selectedLevel}
                onChange={this.handleChange}
                label="Level"
                renderValue={selected => selected.join(', ').replaceAll('-', ' ')}>
                {AVAILABLE_LEVEL.map(lvl => (
                  <MenuItem key={lvl} value={lvl}>
                    <Checkbox checked={selectedLevel.indexOf(lvl) > -1} />
                    <ListItemText primary={lvl.replace('-', ' ')} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {selectedSubject === 'Mathematics' && (
              <>
                <FormControl variant="outlined" className="mr-2" style={{ minWidth: '100px' }}>
                  <InputLabel htmlFor="topic">Topic</InputLabel>
                  <Select name="selectedMathTopic" value={selectedMathTopic} onChange={this.handleChange} label="Topic">
                    {MATH_TOPICS.map(topic => (
                      <MenuItem value={topic} key={topic}>
                        {topic}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}
            <Button variant="contained" color="primary" onClick={this.clickSearch} className="ml-2">
              Search
            </Button>
          </div>
        </Paper>

        {loading && (
          <div className="text-center my-5">
            <CircularProgress size={40} color="primary" className="text-center" />
          </div>
        )}

        <div className="d-flex justify-content-between align-items-center py-2">
          {pageCount > 1 && (
            <Pagination color="primary" count={pageCount} page={currentPage} onChange={this.changePage} />
          )}

          {pageCount > 1 && questionList.count > 0 ? (
            <div className="text-muted">
              Showing {PER_PAGE * (currentPage - 1) + 1} - {PER_PAGE * (currentPage - 1) + PER_PAGE} out of&nbsp;
              {questionList.count} questions
            </div>
          ) : (
            <div className="text-muted">Showing all {questionList.count} questions</div>
          )}
        </div>

        {questionList.data.map(question => {
          const showQuestionUnitNumber = question.unit.length > 1
          return (
            <Paper elevation={2} key={question._id} className="mb-3">
              <div className="d-flex flex-row-reverse align-items-center justify-content-between bg-light p-2">
                <div>
                  {question.meta.year && <Chip className="bg-white mr-2" label={question.meta.year} />}
                  {question.meta.school && <Chip className="bg-white mr-2" label={question.meta.school} />}
                  {question.meta.topic && <Chip className="bg-white mr-2" label={question.meta.topic} />}
                  {question.meta.skill && <Chip className="bg-white mr-2" label={question.meta.skill} />}
                  {question.meta.is_self_checking && (
                    <Chip className="bg-white mr-2" label="Self Checking" color="secondary" />
                  )}
                </div>
                {question.instruction && (
                  <div>
                    <span className="text-muted">Instruction: </span>
                    {question.instruction}
                  </div>
                )}
              </div>
              <div className="p-2">
                {question.context && (
                  <Latex>
                    <div dangerouslySetInnerHTML={{ __html: question.context }} />
                    <hr />
                  </Latex>
                )}
                {question.unit.map((qu, index) => (
                  <div key={index}>
                    <Latex>
                      <div className="d-flex justify-content-between">
                        <div>
                          {showQuestionUnitNumber && <span className="font-semibold">{qu.label}.&nbsp;&nbsp;</span>}
                          <div dangerouslySetInnerHTML={{ __html: qu.question_body }} />
                        </div>
                        <Chip label={`Mark: ${qu.mark}`} variant="outlined" color="primary" />
                      </div>
                      <div
                        className={`d-flex ${
                          ['absolute_answer', 'short_answer_set'].includes(qu.answer_type)
                            ? 'align-items-center'
                            : 'aling-items-baseline'
                        }`}>
                        <div className="text-muted">Answer:&nbsp;&nbsp;</div>
                        {['single', 'multiple'].includes(qu.answer_type) && (
                          <div>
                            {qu.option.map((o, index) => (
                              <div key={index} className="d-flex flex-row align-items-center px-2 mb-1">
                                <Chip label={`${o.label}. ${o.content}`} variant="outlined" size="small" />
                                {qu.answer.includes(index) && (
                                  <CorrectIcon
                                    className="ml-1 text-success"
                                    style={{ width: '0.8em', height: '0.8em' }}
                                  />
                                )}
                              </div>
                            ))}
                          </div>
                        )}
                        {qu.answer_type === 'absolute_answer' && (
                          <Latex>
                            <Chip
                              label={`${qu.answer.pre_unit}${qu.answer.body}${qu.answer.post_unit}`}
                              variant="outlined"
                              size="small"
                              className="mr-2"
                            />
                          </Latex>
                        )}
                        {qu.answer_type === 'short_answer_set' && (
                          <Latex>
                            {qu.answer.map((a, index) => (
                              <Chip
                                label={`${a.pre_unit}${a.body}${a.post_unit}`}
                                variant="outlined"
                                size="small"
                                className="mr-2"
                                key={index}
                              />
                            ))}
                          </Latex>
                        )}
                        {qu.answer_type === 'rich_text' && (
                          <div
                            className="w-100 p-2"
                            style={{
                              border: '1px solid rgba(0, 0, 0, 0.23)',
                              borderRadius: '5px',
                              backgroundColor: 'white'
                            }}>
                            <div dangerouslySetInnerHTML={{ __html: qu.answer }} />
                          </div>
                        )}
                      </div>
                    </Latex>
                    <hr />
                  </div>
                ))}
              </div>
              <div className="text-right pb-3 px-3">
                <Button variant="outlined" color="primary" onClick={this.toggleTagQuestionDialog(question._id)}>
                  Tag Question
                </Button>
              </div>
            </Paper>
          )
        })}

        {pageCount > 0 && (
          <div className="d-flex justify-content-between align-items-center py-2">
            {pageCount > 1 && (
              <Pagination color="primary" count={pageCount} page={currentPage} onChange={this.changePage} />
            )}

            {pageCount > 1 && questionList.count > 0 ? (
              <div className="text-muted">
                Showing {PER_PAGE * (currentPage - 1) + 1} - {PER_PAGE * (currentPage - 1) + PER_PAGE} out of&nbsp;
                {questionList.count} questions
              </div>
            ) : (
              <div className="text-muted">Showing all {questionList.count} questions</div>
            )}
          </div>
        )}

        <AddNewQuestionDialog isOpen={isAddNewDialogOpen} closeDialog={this.toggleAddNewDialog} />
        <TagQuestionDialog
          isOpen={isTagQuestionDialogOpen}
          closeDialog={this.toggleTagQuestionDialog()}
          questionId={selectedQuestionId}
        />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionBank)
