import * as Type from 'constants/actionTypes'

const initialState = {
  loadingEventList: false,
  eventList: { data: [], count: 0 }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Type.GET_EVENT_LIST:
      return {
        ...state,
        loadingEventList: true
      }

    case Type.GET_EVENT_LIST_SUCCESS: {
      let updatedEventList = state.eventList
      updatedEventList = action.payload

      return {
        ...state,
        loadingEventList: false,
        eventList: updatedEventList,
        eventCount: action.payload.count,
        eventPage: action.page
      }
    }

    case Type.CREATE_EVENT:
      return {
        ...state,
        creatingEvent: true
      }

    case Type.CREATE_EVENT_SUCCESS:
      return {
        ...state,
        creatingEvent: false
      }

    case Type.CREATE_EVENT_FAILED:
      return {
        ...state,
        creatingEvent: false
      }

    case Type.EDIT_EVENT:
      return {
        ...state,
        editingEvent: true
      }

    case Type.EDIT_EVENT_SUCCESS:
      return {
        ...state,
        editingEvent: false
      }

    case Type.EDIT_EVENT_FAILED:
      return {
        ...state,
        editingEvent: false
      }

    case Type.DELETE_EVENT:
      return {
        ...state,
        deletingEvent: true
      }

    case Type.DELETE_EVENT_SUCCESS:
      return {
        ...state,
        deletingEvent: false
      }

    case Type.DELETE_EVENT_FAILED:
      return {
        ...state,
        deletingEvent: false
      }

    case Type.GET_FEEDBACK_FAILED:
      return {
        ...state,
        loadingEventList: false
      }

    case Type.LOGOUT:
      return {
        initialState
      }

    default:
      return state
  }
}
