import React from 'react'

const Footer = () => {
  return (
    <footer className="app-footer">
      <div className="d-flex flex-row justify-content-between">
        <div>
          <span>© Copyright {new Date().getFullYear()} TenOfTen Pte Ltd. All rights reserved.</span>
        </div>
      </div>
    </footer>
  )
}

export default Footer
