import React from 'react'
import { connect } from 'react-redux'
import {
  Button,
  CircularProgress,
  FormControlLabel,
  RadioGroup,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField
} from '@material-ui/core'
import _ from 'lodash'
import moment from 'moment'

import agent from 'agent'
import { GET_TRIAL_STAT } from 'constants/actionTypes'
import DialogTrialStudentDetails from './dialog-trial-student-details'

const mapStateToProps = state => ({
  loadingTrialStat: state.report.loadingTrialStat,
  trialStat: state.report.trialStat
})

const mapDispatchToProps = dispatch => ({
  getTrialData: reqPayload => dispatch({ type: GET_TRIAL_STAT, payload: agent.Report.getTrialStat(reqPayload) })
})

const headCells = [
  { id: 'class', numeric: false, label: 'Class' },
  { id: 'lesson.meta.index', numeric: false, label: 'Lesson' },
  { id: 'tutor', numeric: false, label: 'Tutor' },
  { id: 'registered_trial_count', numeric: true, label: 'Total Trial Registration' },
  { id: 'attendance_count', numeric: true, label: 'Total Attended' },
  { id: 'attendance_percentage', numeric: true, label: 'Attendance (%)' },
  { id: 'attended_converted_count', numeric: true, label: 'Attended & Purchase' },
  { id: 'attended_converted_percentage', numeric: true, label: 'Attended & Purchase (%)' },
  { id: 'converted_count', numeric: true, label: 'Total Purchased' }
]

const EnhancedTableHead = props => {
  const { order, orderBy, onRequestSort } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

class TrialStatTab extends React.Component {
  state = {
    subject: 'English',
    startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    tableData: [],
    order: 'asc',
    orderBy: 'class',
    sortedData: [],
    isDetailDialogOpen: false,
    selectedData: []
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.loadingTrialStat && !this.props.loadingTrialStat) {
      //NOTE: having this processed table date bcz CK requested some percentage and separation of conversion with attendance n without.
      //      shall change and renew when move to proper trial stat in Jan 2021
      const { trialStat } = this.props
      if (_.isArray(trialStat)) {
        let tableData = trialStat
        for (let i = 0; i < trialStat.length; i++) {
          let currentState = trialStat[i]

          let attendedNConverted = _.filter(currentState.trial_student, o => {
            return o.attendance && o.class_purchased
          })

          tableData[i]['attended_converted_count'] = attendedNConverted.length

          let attendedConvertedPercentage = ((attendedNConverted.length / currentState.attendance_count) * 100).toFixed(
            2
          )

          if (isNaN(attendedConvertedPercentage)) {
            attendedConvertedPercentage = Number(0).toFixed(2)
          }
          tableData[i]['attended_converted_percentage'] = attendedConvertedPercentage

          tableData[i]['attendance_percentage'] = (
            (currentState.attendance_count / currentState.registered_trial_count) *
            100
          ).toFixed(2)
        }
        this.setState({ tableData })
      }
    }

    if (prevState.tableData !== this.state.tableData) {
      this.sortTableData()
    }

    if (prevState.orderBy !== this.state.orderBy || prevState.order !== this.state.order) {
      this.sortTableData()
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  generate = () => {
    const { subject, startDate, endDate } = this.state
    this.props.getTrialData({
      subject,
      date_range: {
        $gte: moment(startDate).toISOString(),
        $lte: moment(endDate).toISOString()
      }
    })
  }

  handleRequestSort = (event, property) => {
    const { order, orderBy } = this.state
    const isAsc = orderBy === property && order === 'asc'
    this.setState({
      order: isAsc ? 'desc' : 'asc',
      orderBy: property
    })
  }

  sortTableData = () => {
    const { tableData, order, orderBy } = this.state
    let sortedData = _.orderBy(tableData, [orderBy], [order])
    this.setState({ sortedData })
  }

  viewDetails = data => () => {
    this.setState({
      selectedData: data,
      isDetailDialogOpen: true
    })
  }

  closeDetailDialog = () => {
    this.setState({
      selectedData: [],
      isDetailDialogOpen: false
    })
  }

  render() {
    const { activeTab, loadingTrialStat, trialStat } = this.props
    const { subject, startDate, endDate, order, orderBy, sortedData, isDetailDialogOpen, selectedData } = this.state

    return (
      <div className="app-wrapper" hidden={activeTab !== 'trial-stat'}>
        <div className="row align-items-center mb-3">
          <div className="col-auto">
            <div className="text-muted">Subject: </div>
            <RadioGroup name="subject" value={subject} onChange={this.handleChange} row>
              <FormControlLabel value="English" control={<Radio />} label="English" />
              <FormControlLabel value="Mathematics" control={<Radio />} label="Math" />
              <FormControlLabel value="Science" control={<Radio />} label="Science" />
            </RadioGroup>
          </div>
          <div className="col-auto">
            <TextField
              variant="outlined"
              type="date"
              label="From"
              name="startDate"
              value={startDate}
              onChange={this.handleChange}
            />
          </div>
          <div className="col-auto">
            <TextField
              variant="outlined"
              type="date"
              label="To"
              name="endDate"
              value={endDate}
              onChange={this.handleChange}
            />
          </div>
          <div className="col-12 text-right">
            <Button variant="contained" color="primary" onClick={this.generate}>
              Generate
            </Button>
          </div>
        </div>

        {loadingTrialStat && (
          <div className="my-5 text-center">
            <CircularProgress color="primary" size={40} />
          </div>
        )}

        {!_.isArray(trialStat) ? (
          <h2>{trialStat}</h2>
        ) : (
          <Table>
            <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={this.handleRequestSort} />
            <TableBody>
              {sortedData.map((stat, index) => {
                let lessonMeta = `Lesson ${stat.lesson.meta.index}`
                if (stat.lesson.meta?.term) {
                  lessonMeta = `Term ${stat.lesson.meta.term} ${lessonMeta}`
                }

                return (
                  <TableRow key={index} onClick={this.viewDetails(stat)} className="clickable">
                    <TableCell>{stat.class}</TableCell>
                    <TableCell>{lessonMeta}</TableCell>
                    <TableCell>{stat.tutor}</TableCell>
                    <TableCell align="right">{stat.registered_trial_count}</TableCell>
                    <TableCell align="right">{stat.attendance_count}</TableCell>
                    <TableCell align="right">{stat.attendance_percentage}%</TableCell>
                    <TableCell align="right">{stat.attended_converted_count}</TableCell>
                    <TableCell align="right">{stat.attended_converted_percentage}%</TableCell>
                    <TableCell align="right">{stat.converted_count}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        )}

        <DialogTrialStudentDetails
          isOpen={isDetailDialogOpen}
          close={this.closeDetailDialog}
          studentList={selectedData.trial_student}
          classTitle={selectedData.class}
        />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TrialStatTab)
