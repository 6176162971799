import * as Type from 'constants/actionTypes'

const initialState = {
  userRole: '',
  changingPassword: false
}

const getUserRole = userData => {
  const OXKID_MAIN_ACCOUNT = 'rwD3ZIxtTf2yaf0IaON3XQ'
  const userId = userData._id
  let userRole = userData._type
  if (userId === OXKID_MAIN_ACCOUNT) {
    userRole = 'Partner'
  }
  return userRole
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Type.LOGIN:
      return {
        ...state,
        errors: false
      }

    case Type.LOGIN_SUCCESS:
      return {
        ...state,
        userRole: getUserRole(action.payload.data)
      }

    case Type.LOGIN_FAILED:
      return {
        ...state,
        errors: true
      }

    case Type.LOAD_APP_SUCCESS:
      return {
        ...state,
        userRole: getUserRole(action.payload[0].data)
      }

    case Type.CHANGE_PASSWORD:
      return {
        ...state,
        changingPassword: true
      }

    case Type.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changingPassword: false
      }

    case Type.LOGOUT:
      return initialState

    default:
      return state
  }
}
