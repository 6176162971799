import React from 'react'
import { connect } from 'react-redux'
import { AppBar, Tab, Tabs } from '@material-ui/core'

import { ROUTE_NUMBER_DASHBOARD } from 'routes/paths'
import permissions, { checkActionPermitted } from 'permissions'
import ClassEnrolmentTab from './tab-class-enrolment'
import LessonAttendanceTab from './tab-lesson-attendance'
import HomeworkStatTab from './tab-homework-stat'
import TLSAttendanceTab from './tab-tls-attendance'
import TrialStatTab from './tab-trial-stat'

const mapStateToProps = state => ({
  userRole: state.auth.userRole
})

class Dashboard extends React.Component {
  state = {
    activeTab: 'class-enrolment'
  }

  componentDidMount() {
    if (this.props.userRole === 'CustService') {
      this.setState({ activeTab: 'lesson-attendance' })
    }
  }

  handleTabChange = (event, value) => {
    this.setState({ activeTab: value })
  }

  render() {
    const { userRole } = this.props
    const { activeTab } = this.state

    return (
      <>
        <AppBar position="static">
          <Tabs className="pt-2" value={activeTab} onChange={this.handleTabChange} centered>
            {checkActionPermitted(permissions, userRole, ROUTE_NUMBER_DASHBOARD, 'canViewClassEnrolment') && (
              <Tab label="Class Enrolment" value="class-enrolment" />
            )}
            {checkActionPermitted(permissions, userRole, ROUTE_NUMBER_DASHBOARD, 'canViewTrialStat') && (
              <Tab label="Trial Stat" value="trial-stat" />
            )}
            <Tab label="Lesson Attendance" value="lesson-attendance" />
            <Tab label="Homework" value="homework-stat" />
            <Tab label="TLS Attendance" value="tls-attendance" />
          </Tabs>
        </AppBar>

        <ClassEnrolmentTab activeTab={activeTab} />
        <TrialStatTab activeTab={activeTab} />
        <LessonAttendanceTab activeTab={activeTab} />
        <HomeworkStatTab activeTab={activeTab} />
        <TLSAttendanceTab activeTab={activeTab} />
      </>
    )
  }
}

export default connect(mapStateToProps)(Dashboard)
