import React from 'react'
import { connect } from 'react-redux'
import { Avatar, Card, Chip, CircularProgress, Divider, TextField } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import _ from 'lodash'
import moment from 'moment'

import agent from 'agent'
import { GET_LESSONS } from 'constants/actionTypes'
import StudentListDialog from './dialog-student-list'

const mapStateToProps = state => ({
  lessonList: state.lessons.lessons,
  lessonCount: state.lessons.lessonCount,
  isLoadingLesson: state.lessons.isLoading
})

const mapDispatchToProps = dispatch => ({
  getLessonList: (filter, perPage, page) =>
    dispatch({
      type: GET_LESSONS,
      payload: agent.Lesson.getLessonList(filter, perPage, page)
    })
})

const AttendanceStat = props => {
  let { attendance, paid, trial, viewDetail } = props
  let paidAttended = [],
    paidHalfAttended = [],
    paidAbsent = []
  let trialAttended = [],
    trialHalfAttended = [],
    trialAbsent = []
  let lessonEnd

  attendance.forEach(data => {
    if (lessonEnd) {
      let leaveTime = moment(data.leave_time)
      if (leaveTime > lessonEnd) {
        lessonEnd = leaveTime
      }
    } else {
      lessonEnd = moment(data.leave_time)
    }
  })

  // TODO: refactor this, duplicated code
  paid.forEach(student => {
    let attendanceRecords = _.filter(attendance, o => {
      return o.user_email === student.email
    })
    if (attendanceRecords.length > 0) {
      let joinTime, leaveTime
      attendanceRecords.forEach(attendanceData => {
        if (joinTime) {
          if (moment(attendanceData.join_time) < moment(joinTime)) {
            joinTime = attendanceData.join_time
          }
        } else {
          joinTime = attendanceData.join_time
        }

        if (leaveTime) {
          if (moment(attendanceData.leave_time) > moment(leaveTime)) {
            leaveTime = attendanceData.leave_time
          }
        } else {
          leaveTime = attendanceData.leave_time
        }
      })

      let tempStudent = {
        ...student,
        join_time: joinTime,
        leave_time: leaveTime
      }

      if (lessonEnd.diff(moment(leaveTime), 'minute') > 30) {
        paidHalfAttended.push(tempStudent)
      } else {
        paidAttended.push(tempStudent)
      }
    } else {
      paidAbsent.push(student)
    }
  })

  trial.forEach(student => {
    let attendanceRecords = _.filter(attendance, o => {
      return o.user_email === student.email
    })
    if (attendanceRecords.length > 0) {
      let joinTime, leaveTime
      attendanceRecords.forEach(attendanceData => {
        if (joinTime) {
          if (moment(attendanceData.join_time) < moment(joinTime)) {
            joinTime = attendanceData.join_time
          }
        } else {
          joinTime = attendanceData.join_time
        }

        if (leaveTime) {
          if (moment(attendanceData.leave_time) > moment(leaveTime)) {
            leaveTime = attendanceData.leave_time
          }
        } else {
          leaveTime = attendanceData.leave_time
        }
      })

      let tempStudent = {
        ...student,
        join_time: joinTime,
        leave_time: leaveTime
      }

      if (lessonEnd.diff(moment(leaveTime), 'minute') > 30) {
        trialHalfAttended.push(tempStudent)
      } else {
        trialAttended.push(tempStudent)
      }
    } else {
      trialAbsent.push(student)
    }
  })

  return (
    <div className="row">
      <div className="col-12">
        <h4>Paid Student: {paid.length}</h4>
        <div className="d-flex manage-margin px-2">
          <Chip
            variant="default"
            label="Attended"
            className={`${paidAttended.length > 0 ? 'bg-success' : 'bg-danger blink'} text-white clickable`}
            avatar={<Avatar className="bg-white">{paidAttended.length}</Avatar>}
            onClick={viewDetail(paidAttended)}
          />

          {paidHalfAttended.length > 0 && (
            <Chip
              variant="default"
              label="Half Attended"
              className="bg-warning text-white clickable"
              avatar={<Avatar className="bg-white">{paidHalfAttended.length}</Avatar>}
              onClick={viewDetail(paidHalfAttended)}
            />
          )}

          {paidAbsent.length > 0 && (
            <Chip
              variant="default"
              label="Absent"
              className="bg-danger text-white clickable"
              avatar={<Avatar className="bg-white">{paidAbsent.length}</Avatar>}
              onClick={viewDetail(paidAbsent)}
            />
          )}
        </div>
      </div>

      <div className="col-12 mt-2">
        <h4>Trial Student: {trial.length}</h4>
        <div className="d-flex px-2 manage-margin">
          {trialAttended.length > 0 && (
            <Chip
              variant="default"
              label="Attended"
              className="bg-success text-white clickable"
              avatar={<Avatar className="bg-white">{trialAttended.length}</Avatar>}
              onClick={viewDetail(trialAttended)}
            />
          )}

          {trialHalfAttended.length > 0 && (
            <Chip
              variant="default"
              label="Half Attended"
              className="bg-warning text-white clickable"
              avatar={<Avatar className="bg-white">{trialHalfAttended.length}</Avatar>}
              onClick={viewDetail(trialHalfAttended)}
            />
          )}

          {trialAbsent.length > 0 && (
            <Chip
              variant="default"
              label="Absent"
              className="bg-danger text-white clickable"
              avatar={<Avatar className="bg-white">{trialAbsent.length}</Avatar>}
              onClick={viewDetail(trialAbsent)}
            />
          )}
        </div>
      </div>
    </div>
  )
}

class LessonAttendanceTab extends React.Component {
  state = {
    day: moment().subtract(1, 'day').startOf('').format('YYYY-MM-DD'),
    listData: [],
    isStudentListDialogOpen: false,
    pageCount: 0,
    currentPage: 1,
    perPage: 15,
    refreshPagination: false
  }

  componentDidMount() {
    if (this.props.activeTab === 'lesson-attendance') {
      this.search()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.activeTab !== this.props.activeTab && this.props.activeTab === 'lesson-attendance') {
      if (!this.props.isLoadingLesson && !this.props.lessonList) {
        this.search()
      }
    }

    if (prevProps.isLoadingLesson && !this.props.isLoadingLesson && this.state.refreshPagination) {
      let { perPage } = this.state
      let { lessonCount } = this.props
      let pageCount = Math.ceil(lessonCount / perPage)

      this.setState({
        refreshPagination: false,
        pageCount,
        currentPage: 1
      })
    }

    if (prevState.day !== this.state.day) {
      this.search()
    }
  }

  search = () => {
    let { day, perPage } = this.state
    let filter = {
      is_active: true,
      ended_on: {
        $gte: moment(day).startOf('d').toISOString(),
        $lte: moment(day).endOf('d').toISOString()
      },
      populate: 'student trial_student'
    }

    this.setState({ refreshPagination: true })
    this.props.getLessonList(filter, perPage, 1)
  }

  changePage = (event, page) => {
    let { day, perPage } = this.state

    let filter = {
      is_active: true,
      ended_on: {
        $gte: moment(day).startOf('d').toISOString(),
        $lte: moment(day).endOf('d').toISOString()
      },
      populate: 'student trial_student'
    }

    this.setState({ currentPage: page })
    this.props.getLessonList(filter, perPage, page)
  }

  handleDateChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  toggleStudentListDialog = listData => () => {
    this.setState({
      listData,
      isStudentListDialogOpen: !this.state.isStudentListDialogOpen
    })
  }

  render() {
    let { activeTab, isLoadingLesson, lessonList, lessonCount } = this.props
    let { day, listData, isStudentListDialogOpen, pageCount, currentPage, perPage } = this.state

    return (
      <div className="app-wrapper" hidden={activeTab !== 'lesson-attendance'}>
        <div className="row mb-3">
          <div className="col-12">
            <TextField
              variant="outlined"
              type="date"
              label="Day"
              name="day"
              value={day}
              onChange={this.handleDateChange}
            />
          </div>
          <div className="col-12 mt-2 text-muted">
            * half attended means student left more than 30 mins before lesson ended
          </div>
        </div>

        {isLoadingLesson && (
          <div className="my-5 text-center">
            <CircularProgress color={'primary'} size={40} />
          </div>
        )}

        <div className="text-right">
          {pageCount > 1 && lessonCount > 0 ? (
            <>
              Showing {perPage * (currentPage - 1) + 1} - {perPage * (currentPage - 1) + perPage} out of {lessonCount}{' '}
              records
            </>
          ) : (
            <>Showing all {lessonCount} records</>
          )}
        </div>

        {lessonList &&
          lessonList.map(lesson => {
            let tutorNames = _.map(lesson.tutor, 'name').join(',')

            return (
              <Card className="mb-3" key={lesson._id}>
                <div className="p-3">
                  <h3 className="mb-0">{lesson.class.name}</h3>
                </div>
                <Divider />
                <div className="row p-3">
                  <div className="col-5">
                    <span className="text-muted">Lesson: </span>
                    <span>{lesson.name}</span>
                    <br />
                    <span className="text-muted">Meta: </span>
                    <span>
                      {lesson.meta.term ? `Term ${lesson.meta.term}, ` : ''}
                      Lesson {lesson.meta.index}
                    </span>
                    <br />
                    <span className="text-muted">Time: </span>
                    <span>{moment(lesson.scheduled_on).format('h:mm a')}</span>
                    <br />
                    <span className="text-muted">Tutor: </span>
                    <span>{tutorNames}</span>
                  </div>
                  <div className="col-7">
                    <div className="row">
                      <AttendanceStat
                        attendance={lesson.attendance ?? []}
                        paid={lesson.student}
                        trial={lesson.trial_student}
                        viewDetail={this.toggleStudentListDialog}
                      />
                    </div>
                  </div>
                </div>
              </Card>
            )
          })}

        {pageCount > 1 && (
          <Pagination color="primary" count={pageCount} page={currentPage} onChange={this.changePage} />
        )}

        <StudentListDialog isOpen={isStudentListDialogOpen} toggle={this.toggleStudentListDialog} listData={listData} />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LessonAttendanceTab)
