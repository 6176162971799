import React from 'react'
import PropTypes from 'prop-types'
import { Card, CardBody } from 'reactstrap'
import moment from 'moment'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'

class FeedbackItem extends React.Component {
  constructor(props) {
    super()

    this.toggleUserInfoDialog = () => {
      this.setState({ userInfoDialogOpen: !this.state.userInfoDialogOpen })
    }

    this.state = {
      userInfoDialogOpen: false
    }
  }

  render() {
    const { created_by, rating, content, created, feedback_target_object } = this.props.feedback
    const { userInfoDialogOpen } = this.state

    return (
      <Card className={'mb-3'}>
        <CardBody>
          <div className="row">
            <div className="col-3">
              <h4 className="font-weight-semibold">
                <span className={'pointer text-blue'} onClick={this.toggleUserInfoDialog}>
                  {created_by.name}
                </span>
                <Dialog open={userInfoDialogOpen} onClose={this.toggleUserInfoDialog} fullWidth>
                  <DialogContent>
                    <div className="row">
                      <div className="col">
                        <strong>{created_by.name}</strong>
                        <div>Phone: {created_by.phone}</div>
                        <div>Email: {created_by.email}</div>
                      </div>
                      {created_by.parent && created_by.parent.name && (
                        <div className="col">
                          <strong>Parent: {created_by.parent.name}</strong>
                          <div>Phone: {created_by.parent.phone}</div>
                          <div>Email: {created_by.parent.email}</div>
                        </div>
                      )}
                    </div>
                  </DialogContent>
                </Dialog>
              </h4>
              {feedback_target_object && (
                <h5>
                  <div className={'mb-1'}>
                    <strong>{feedback_target_object.class.name}</strong>
                  </div>
                  <div>{feedback_target_object.name}</div>
                </h5>
              )}
              <h5 className={'mb-0'}>{moment(created).format('D MMM YYYY, ddd')}</h5>
            </div>
            <div className="col">
              <div className={'mb-3'} style={{ fontSize: 25, lineHeight: 1 }}>
                <i className={rating >= 1 ? 'zmdi zmdi-star text-warning pr-1' : 'zmdi zmdi-star-outline pr-1'} />
                <i className={rating >= 2 ? 'zmdi zmdi-star text-warning pr-1' : 'zmdi zmdi-star-outline pr-1'} />
                <i className={rating >= 3 ? 'zmdi zmdi-star text-warning pr-1' : 'zmdi zmdi-star-outline pr-1'} />
                <i className={rating >= 4 ? 'zmdi zmdi-star text-warning pr-1' : 'zmdi zmdi-star-outline pr-1'} />
                <i className={rating >= 5 ? 'zmdi zmdi-star text-warning pr-1' : 'zmdi zmdi-star-outline pr-1'} />
              </div>
              <div className={!!content ? '' : 'text-muted'}>{!!content ? content : 'No comments'}</div>
            </div>
          </div>
        </CardBody>
      </Card>
    )
  }
}

FeedbackItem.propTypes = {
  feedback: PropTypes.object.isRequired
}

export default FeedbackItem
