import React from 'react'
import { connect } from 'react-redux'
import agent from 'agent'
import { Link } from 'react-router-dom'
import { EDIT_LESSON, GET_LESSON_BY_ID, GET_USERS, MAKE_ANNOUNCEMENT, UNLOAD_LESSON } from 'constants/actionTypes'
import CircularProgress from '@material-ui/core/CircularProgress'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Button from '@material-ui/core/Button'
import moment from 'moment'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import _ from 'lodash'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import DialogAddLesson from 'pages/CourseManagement/LessonManagement/DialogAddLesson'
import DialogAddStudent from 'pages/CourseManagement/LessonManagement/DialogAddStudent'
import CreateAnnouncement from 'components/Announcements/CreateAnnouncement'
import Pagination from 'components/Pagination/Pagination'

import { ROUTE_LESSON_PAGE } from 'routes/paths'
import permissions, { checkActionPermitted } from 'permissions'

const mapStateToProps = state => ({
  ...state.lessons,
  announcementSent: state.announcements.announcementSent,
  studentList: state.user.userList,
  studentCount: state.user.userCount,
  userRole: state.auth.userRole
})

const mapDispatchToProps = dispatch => ({
  getLesson: lessonId => dispatch({ type: GET_LESSON_BY_ID, payload: agent.Lesson.getLesson(lessonId) }),
  editLesson: lessonData => dispatch({ type: EDIT_LESSON, payload: agent.Lesson.editLesson(lessonData) }),
  addStudent: (lessonId, reqPayload) =>
    dispatch({ type: EDIT_LESSON, payload: agent.Lesson.addStudentToLesson(lessonId, reqPayload) }),
  makeAnnouncement: (lesson, subject, content) =>
    dispatch({
      type: MAKE_ANNOUNCEMENT,
      payload: agent.Announcement.makeAnnouncement('lesson', lesson._id, 'email', 'student', subject, content)
    }),
  unloadLesson: () => dispatch({ type: UNLOAD_LESSON }),
  getUsers: (filter, perPage, page) =>
    dispatch({ type: GET_USERS, payload: agent.User.getUsers(filter, perPage, page) })
})

const StudentListItem = props => {
  const { student, onDelete, attendanceList, attendanceType, canManage } = props

  let foundStudent = []

  if (attendanceType === 'Zoom') {
    foundStudent = _.filter(attendanceList, s => {
      return s.user_email === student.email
    })

    foundStudent = _.orderBy(foundStudent, ['join_time'], ['asc'])
  } else if (attendanceType === 'ClickMeeting') {
    foundStudent = _.filter(attendanceList, s => {
      return s.email === student.email
    })

    foundStudent = _.orderBy(foundStudent, ['start_date'], ['asc'])
  }

  return (
    <ListItem divider={true} className={foundStudent.length > 0 ? 'bg-green lighten-5' : 'bg-amber lighten-5'}>
      <ListItemText>
        <div className="row">
          <div className="col-6">
            {foundStudent.length > 0 && (
              <small className={'d-inline-block border border-success rounded text-success px-3'}>Attended</small>
            )}
            <div>{student.name}</div>
            <div className="text-muted">{student.email}</div>
            <div className="text-muted">
              (+{student.phone_country_code}) {student.phone}
            </div>
            {foundStudent.length > 0 && (
              <div className="text-muted">
                {foundStudent.map((s, index) => {
                  let startMoment = moment()
                  let endMoment = moment()

                  if (attendanceType === 'Zoom') {
                    startMoment = moment(s.join_time)
                    endMoment = moment(s.leave_time)
                  } else if (attendanceType === 'ClickMeeting') {
                    startMoment = moment(s.start_date)
                    endMoment = moment(s.end_date)
                  }

                  return (
                    <div key={index}>
                      Joined Lesson {startMoment.format('h:mma')} - {endMoment.format('h:mma')} (
                      {Math.floor(endMoment.diff(startMoment, 'minute') / 60)} hours{' '}
                      {Math.floor(endMoment.diff(startMoment, 'minute') % 60)} minutes)
                    </div>
                  )
                })}
              </div>
            )}
          </div>
          <div className="col-4">
            {!!student.parent && (
              <Paper elevation={1} className={'app-wrapper'}>
                <h4 className="font-weight-semibold border-bottom">Parent Info</h4>
                <h3>{student.parent.name}</h3>
                <div className="text-muted">{student.parent.email}</div>
                <div className="text-muted">
                  (+{student.parent.phone_country_code}){student.parent.phone}
                </div>
              </Paper>
            )}
          </div>
        </div>
      </ListItemText>
      {_.isFunction(onDelete) && canManage && (
        <ListItemSecondaryAction>
          <Button
            variant={'text'}
            className={'text-danger'}
            onClick={() => {
              onDelete(student)
            }}>
            Remove
          </Button>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  )
}

class LessonPage extends React.Component {
  constructor(props) {
    super()

    props.getLesson(props.match.params.lessonId)

    this.changeSearch = e => {
      this.setState({ searchTerm: e.target.value })
    }

    this.toggleEditMode = () => {
      this.setState({ editMode: !this.state.editMode })
    }

    this.editLesson = lessonData => {
      this.props.editLesson(lessonData)
    }

    this.toggleAddStudentDialog = () => {
      this.setState({ addStudentDialogOpen: !this.state.addStudentDialogOpen })
    }

    this.saveStudents = studentIdArray => {
      const { lesson } = this.props
      const currentStudentIdArr = _.map(lesson.student, '_id')

      //this.props.editLesson(updateData)
      this.props.addStudent(lesson._id, {
        student: _.concat(currentStudentIdArr, studentIdArray)
      })
    }

    this.toggleRemoveStudent = studentObj => {
      this.setState({ confirmRemoveStudentOpen: !this.state.confirmRemoveStudentOpen, toRemove: studentObj })
    }

    this.removeStudent = () => {
      const { toRemove } = this.state
      const { lesson } = this.props

      let currentStudentIdArr = _.map(lesson.student, '_id')
      let newStudentList = _.pull(currentStudentIdArr, toRemove._id)

      this.props.editLesson({ _id: lesson._id, student: newStudentList })
      this.toggleRemoveStudent()
    }

    this.toggleShouldAnnounceDialog = () => {
      this.setState({ shouldAnnounceDialogOpen: !this.state.shouldAnnounceDialogOpen })
    }

    this.state = {
      searchTerm: '',
      filteredStudents: [],
      editMode: false,
      addStudentDialogOpen: false,
      confirmRemoveStudentOpen: false,
      toRemove: null,
      shouldAnnounceDialogOpen: false,
      changes: null,
      createAnnouncementDialogOpen: false,
      tab: 'student',
      perPage: 20,
      page: 1,
      canManage: checkActionPermitted(permissions, props.userRole, ROUTE_LESSON_PAGE, 'canManage'),
      canSendNotification: checkActionPermitted(permissions, props.userRole, ROUTE_LESSON_PAGE, 'canSendNotification')
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.searchTerm !== this.state.searchTerm) {
      const { tab } = this.state

      if (tab === 'student') {
        let filteredStudents = _.filter(this.props.lesson.student, s => {
          let studentName = s.name
          let nameMatch = -1
          let studentEmail = s.email
          let emailMatch = -1

          if (!!studentName) {
            nameMatch = studentName.toLowerCase().indexOf(this.state.searchTerm.toLowerCase())
          }
          if (!!studentEmail) {
            emailMatch = studentEmail.toLowerCase().indexOf(this.state.searchTerm.toLowerCase())
          }
          return nameMatch !== -1 || emailMatch !== -1
        })

        this.setState({ filteredStudents })
      } else {
        this.getUsers()
      }
    }

    if (!prevProps.lessonEdited && this.props.lessonEdited) {
      this.props.getLesson(this.props.match.params.lessonId)
    }

    if (!_.isEqual(prevProps.lesson, this.props.lesson) && !!prevProps.lesson && !!this.props.lesson) {
      //Compare the previous and current lesson objects and trigger different messages depending on the changes
      const originalLesson = prevProps.lesson
      const modifiedLesson = this.props.lesson

      let changes = []
      if (originalLesson.scheduled_on !== modifiedLesson.scheduled_on) {
        //Start date changed
        changes.push({
          field: 'lesson time',
          value: moment(modifiedLesson.scheduled_on).format('dddd Do MMM YYYY, h:mma'),
          original: moment(originalLesson.scheduled_on).format('dddd Do MMM YYYY, h:mma')
        })
      }
      if (
        moment(originalLesson.ended_on).diff(moment(originalLesson.scheduled_on), 'hours', true) !==
        moment(modifiedLesson.ended_on).diff(moment(modifiedLesson.scheduled_on), 'hours', true)
      ) {
        //Lesson duration changed
        let newMinuteDiff = moment(modifiedLesson.ended_on).diff(moment(modifiedLesson.scheduled_on), 'minutes', true)
        let newHours = Math.floor(newMinuteDiff / 60)
        let newMinutes = newMinuteDiff % 60

        let changeValue = []
        if (newHours > 0) {
          if (newHours === 1) {
            changeValue.push(`${newHours} hour`)
          } else {
            changeValue.push(`${newHours} hours`)
          }
        }
        if (newMinutes > 0) {
          if (newMinutes === 1) {
            changeValue.push(`${newMinutes} minute`)
          } else {
            changeValue.push(`${newMinutes} minutes`)
          }
        }

        let oldMinuteDiff = moment(originalLesson.ended_on).diff(moment(originalLesson.scheduled_on), 'minutes', true)
        let oldHours = Math.floor(oldMinuteDiff / 60)
        let oldMinutes = oldMinuteDiff % 60

        let oldValues = []
        if (oldHours > 0) {
          if (oldHours === 1) {
            oldValues.push(`${oldHours} hour`)
          } else {
            oldValues.push(`${oldHours} hours`)
          }
        }
        if (oldMinutes > 0) {
          if (oldMinutes === 1) {
            oldValues.push(`${oldMinutes} minute`)
          } else {
            oldValues.push(`${oldMinutes} minutes`)
          }
        }

        changes.push({
          field: 'lesson duration',
          value: changeValue.join(' '),
          original: oldValues.join(' ')
        })
      }
      if (originalLesson.tutor._id !== modifiedLesson.tutor._id) {
        //tutor changed
        changes.push({
          field: 'tutor',
          value: modifiedLesson.tutor.name,
          original: originalLesson.tutor.name
        })
      }

      //handle changes
      if (changes.length > 0) {
        this.setState({ shouldAnnounceDialogOpen: true, changes })
      } else {
        this.setState({ shouldAnnounceDialogOpen: false, changes: null })
      }
    }

    if (prevState.tab !== this.state.tab && this.state.tab !== 'student') {
      this.getUsers()
    }

    if (prevState.page !== this.state.page) {
      this.getUsers()
    }
  }

  componentWillUnmount() {
    this.props.unloadLesson()
  }

  getUsers = () => {
    const { tab, perPage, page, searchTerm } = this.state
    const { lesson } = this.props

    let filterArray = ['none']

    if (lesson[tab].length > 0) {
      filterArray = lesson[tab].map(s => {
        return s._id
      })
    }

    let filter = {
      _id: {
        $in: filterArray
      }
    }

    if (!!searchTerm) {
      filter = {
        email: {
          $regex: this.state.searchTerm,
          $options: 'i'
        },
        _id: {
          $in: lesson[tab].map(s => {
            return s._id
          })
        }
      }
    }

    this.props.getUsers(filter, perPage, page)
  }

  toggleCreateAnnouncementDialog = () => {
    this.setState({
      createAnnouncementDialogOpen: !this.state.createAnnouncementDialogOpen,
      shouldAnnounceDialogOpen: false
    })
  }

  onSent = () => {
    this.setState({ createAnnouncementDialogOpen: false })
  }

  changeTab = (e, value) => {
    this.setState({ tab: value, page: 1, searchTerm: '' })
  }

  changePage = page => {
    this.setState({ page })
  }

  render() {
    const { lesson, studentList, studentCount } = this.props
    const {
      searchTerm,
      filteredStudents,
      editMode,
      addStudentDialogOpen,
      confirmRemoveStudentOpen,
      toRemove,
      shouldAnnounceDialogOpen,
      createAnnouncementDialogOpen,
      changes,
      tab,
      perPage,
      page,
      canManage,
      canSendNotification
    } = this.state

    if (!!lesson) {
      return (
        <div>
          <Paper elevation={2} className={'app-wrapper sticky-top'}>
            <div className="row">
              <div className="col">
                <div className={'mb-2'}>
                  <Link to={`/course/${lesson.class._id}`}>Back to lesson list</Link>
                </div>
                <h1 className={'mb-1'}>{lesson.name}</h1>
                <h3 className={'text-muted mb-1'}>{lesson.class.name}</h3>
                {lesson.meta && (
                  <div className="font-weight-semibold">
                    Term {lesson.meta.term}, Lesson {lesson.meta.index}
                  </div>
                )}
                <div className="font-weight-semibold">
                  {moment(lesson.scheduled_on).format('dddd, Do MMM YYYY, h:mma')} -{' '}
                  {moment(lesson.ended_on).format('h:mma')}
                </div>
              </div>
              <div className="col-auto">
                {canSendNotification && (
                  <Button
                    variant={'contained'}
                    color={'secondary'}
                    className={'mr-2'}
                    onClick={this.toggleCreateAnnouncementDialog}>
                    <i className="zmdi zmdi-alert-circle mr-2" />
                    Make Announcement
                  </Button>
                )}
                {canManage && (
                  <Button variant={'contained'} className={'bg-info text-white'} onClick={this.toggleEditMode}>
                    <i className="zmdi zmdi-edit mr-2" />
                    Edit Lesson
                  </Button>
                )}
              </div>
            </div>
          </Paper>
          <div className="app-wrapper">
            <div className="row mb-3">
              <div className="col-4">
                <Paper elevation={2} className={'app-wrapper'}>
                  <h4 className="mb-2">Assigned Tutor</h4>
                  <h3 className="font-weight-semibold">
                    {lesson.tutor.map(item => {
                      return <div key={item._id}>{item.name}</div>
                    })}
                  </h3>
                </Paper>
              </div>

              <div className="col-4">
                <Paper elevation={2} className={'app-wrapper'}>
                  <h4 className={'mb-2'}>Assigned TA</h4>
                  <h3 className="font-weight-semibold">
                    {lesson.ta.length > 0 ? (
                      lesson.ta.map((t, index) => <span key={index}>{t.name}, </span>)
                    ) : (
                      <p>N/A</p>
                    )}
                  </h3>
                </Paper>
              </div>

              {lesson.hosting_platform && (
                <div className="col-4">
                  <Paper elevation={2} className={'app-wrapper'}>
                    <h4 className={'mb-2'}>Hosted on</h4>
                    <h3 className="font-weight-semibold">
                      {lesson.hosting_platform} - {lesson.hosting_type}
                    </h3>
                  </Paper>
                </div>
              )}
            </div>

            {!!lesson.desc && (
              <Paper elevation={2} className={'app-wrapper mb-3'}>
                <div dangerouslySetInnerHTML={{ __html: lesson.desc }}></div>
              </Paper>
            )}

            <Paper elevation={2} className={'app-wrapper mb-3'}>
              <div className="row align-items-center">
                <div className="col">
                  <h3 className="font-weight-semibold">Students</h3>
                </div>
                <div className="col-auto">
                  <FormControl className="mb-3" fullWidth>
                    <InputLabel htmlFor="amount">{tab !== 'student' ? 'Email' : 'Name or Email'}</InputLabel>
                    <Input
                      value={searchTerm}
                      onChange={this.changeSearch}
                      startAdornment={
                        <InputAdornment position="start">
                          <i className="zmdi zmdi-search" />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </div>
              </div>
              <Tabs value={tab} onChange={this.changeTab}>
                <Tab className="tab" label={`Payment (${lesson.student.length})`} value={'student'} />
                <Tab className="tab" label={`Trial (${lesson.trial_student.length})`} value={'trial_student'} />
                <Tab className="tab" label={`Video (${lesson.video_student.length})`} value={'video_student'} />
              </Tabs>

              {tab === 'student' && (
                <List disablePadding={true}>
                  {canManage && (
                    <ListItem
                      divider={true}
                      button={true}
                      className={'bg-deep-purple text-white'}
                      onClick={this.toggleAddStudentDialog}>
                      <i className="zmdi zmdi-plus mr-2"></i> Add Student
                    </ListItem>
                  )}
                  {lesson.student.length > 0 && !!searchTerm ? (
                    filteredStudents.length > 0 ? (
                      filteredStudents.map((s, index) => {
                        return (
                          <StudentListItem
                            key={`${s._id}_${index}`}
                            student={s}
                            onDelete={this.toggleRemoveStudent}
                            attendanceList={lesson.attendance}
                            attendanceType={lesson.hosting_platform}
                            canManage={canManage}
                          />
                        )
                      })
                    ) : (
                      <div className="font-weight-bold">There are no students matching your search</div>
                    )
                  ) : (
                    lesson.student.map((s, index) => {
                      return (
                        <StudentListItem
                          key={`${s._id}_${index}`}
                          student={s}
                          onDelete={this.toggleRemoveStudent}
                          attendanceList={lesson.attendance}
                          attendanceType={lesson.hosting_platform}
                          canManage={canManage}
                        />
                      )
                    })
                  )}
                </List>
              )}
              {tab === 'trial_student' && (
                <List disablePadding={true}>
                  {studentCount > 0 && (
                    <Pagination
                      itemCount={studentCount}
                      onChangePage={this.changePage}
                      pageSize={perPage}
                      currentPage={page}
                    />
                  )}
                  {!!studentList && studentList.length > 0 ? (
                    studentList.map((s, index) => {
                      return (
                        <StudentListItem
                          key={`${s._id}_trial`}
                          student={s}
                          attendanceList={lesson.attendance}
                          attendanceType={lesson.hosting_platform}
                        />
                      )
                    })
                  ) : (
                    <ListItem divider={true}>
                      <ListItemText>There are no students</ListItemText>
                    </ListItem>
                  )}
                </List>
              )}
              {tab === 'video_student' && (
                <List disablePadding={true}>
                  {studentCount > 0 && (
                    <Pagination
                      itemCount={studentCount}
                      onChangePage={this.changePage}
                      pageSize={perPage}
                      currentPage={page}
                    />
                  )}
                  {!!studentList && studentList.length > 0 ? (
                    studentList.map((s, index) => {
                      return (
                        <StudentListItem
                          key={`${s._id}_video`}
                          student={s}
                          attendanceList={lesson.attendance}
                          attendanceType={lesson.hosting_platform}
                        />
                      )
                    })
                  ) : (
                    <ListItem divider={true}>
                      <ListItemText>There are no students</ListItemText>
                    </ListItem>
                  )}
                </List>
              )}
            </Paper>
          </div>
          {editMode && (
            <DialogAddLesson
              isOpen={editMode}
              toggle={this.toggleEditMode}
              lesson={lesson}
              targetClass={lesson.class}
              onSave={this.editLesson}
            />
          )}
          {addStudentDialogOpen && (
            <DialogAddStudent
              isOpen={addStudentDialogOpen}
              toggle={this.toggleAddStudentDialog}
              lesson={lesson}
              onSave={this.saveStudents}
            />
          )}
          {confirmRemoveStudentOpen && toRemove && (
            <Dialog open={confirmRemoveStudentOpen} onClose={this.toggleRemoveStudent}>
              <DialogTitle>{`Remove ${toRemove.name} from the lesson?`}</DialogTitle>
              <DialogContent>
                <DialogContentText>{toRemove.name} will no longer be able to access this lesson.</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.toggleRemoveStudent} color="default">
                  Cancel
                </Button>
                <Button onClick={this.removeStudent} className={'text-danger'}>
                  Remove
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {shouldAnnounceDialogOpen && !!changes && (
            <Dialog
              open={shouldAnnounceDialogOpen}
              onClose={this.toggleShouldAnnounceDialog}
              disableBackdropClick
              disableEscapeKeyDown>
              <DialogTitle>Create announcement regarding changes to the lesson?</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  You will be able to edit the message that is sent out to all students.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.toggleShouldAnnounceDialog} color="default">
                  No
                </Button>
                <Button color={'primary'} onClick={this.toggleCreateAnnouncementDialog}>
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {createAnnouncementDialogOpen && (
            <Dialog
              disableEscapeKeyDown
              disableBackdropClick
              open={createAnnouncementDialogOpen}
              onClose={this.toggleCreateAnnouncementDialog}
              fullScreen={true}
              disableEnforceFocus>
              <AppBar className="position-relative">
                <Toolbar>
                  <IconButton onClick={this.toggleCreateAnnouncementDialog} aria-label="Close" className={'text-white'}>
                    <CloseIcon />
                  </IconButton>
                  <Typography
                    type="title"
                    color="inherit"
                    style={{
                      flex: 1
                    }}>
                    Make Announcement | {lesson.name} | {lesson.class.name}
                  </Typography>
                </Toolbar>
              </AppBar>
              <DialogContent style={{ padding: '24px' }}>
                <CreateAnnouncement lesson={lesson} changes={changes} onSent={this.onSent} />
              </DialogContent>
            </Dialog>
          )}
        </div>
      )
    } else {
      return (
        <div className="loader-view-block h-100">
          <div className="loader-view">
            <CircularProgress />
          </div>
        </div>
      )
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LessonPage)
