import React from 'react'
import { connect } from 'react-redux'

import {
  Button,
  CircularProgress,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  MenuItem,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField
} from '@material-ui/core'
import Proptypes from 'prop-types'

import agent from 'agent'
import { UPDATE_ORDER } from 'constants/actionTypes'

const mapStateToProps = state => ({
  agentList: state.user.agentList,
  updating: state.orders.updatingOrder
})

const mapDispatchToProps = dispatch => ({
  update: (orderId, reqBody) =>
    dispatch({ type: UPDATE_ORDER, payload: agent.Orders.updateOrder(orderId, reqBody), orderId })
})

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

class DialogUpdateOrder extends React.Component {
  state = {
    agent: '',
    isRenewal: false,
    remark: ''
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen && !this.props.isOpen) {
      this.setState({
        agent: '',
        remark: ''
      })
    }

    if (prevProps.updating && !this.props.updating) {
      this.props.close()
    }
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleCheckboxChange = event => {
    this.setState({
      [event.target.name]: event.target.checked
    })
  }

  clickUpdateButton = () => {
    const { orderData } = this.props
    const { agent, isRenewal, remark } = this.state

    let reqBody = {
      meta: {
        is_renewal: isRenewal
      }
    }
    if (agent) {
      reqBody['agent'] = agent
    }
    if (remark) {
      reqBody['remark'] = remark
    }

    this.props.update(orderData._id, reqBody)
  }

  render() {
    const { isOpen, close, orderData, agentList, updating } = this.props
    const { agent, isRenewal, remark } = this.state

    return (
      <Dialog open={isOpen} onClose={close} TransitionComponent={Transition} maxWidth="sm" fullWidth>
        <DialogTitle>
          <span style={{ fontSize: '24px' }}>Update Order</span>
        </DialogTitle>
        <DialogContent>
          {orderData && (
            <div className="row justify-content-center">
              <div className="col-12 mb-3">
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>Type</TableCell>
                      <TableCell>:</TableCell>
                      <TableCell>{orderData.type}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>User</TableCell>
                      <TableCell>:</TableCell>
                      <TableCell>
                        {orderData.created_by.name} ({orderData.created_by.email})
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Class</TableCell>
                      <TableCell>:</TableCell>
                      <TableCell>{orderData.detail.class_obj.name}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
              <div className="col-12">
                <FormControl className="w-100 mb-2">
                  <TextField
                    select
                    SelectProps={{ native: true }}
                    label="Agent"
                    name="agent"
                    defaultValue={orderData?.agent?._id}
                    onChange={this.handleChange}
                    helperText="Select one if order is from agent"
                    className="mt-2">
                    {agentList.map(agent => (
                      <option key={agent._id} value={agent._id}>
                        {agent.name}
                      </option>
                    ))}
                  </TextField>
                </FormControl>

                <FormControl className="w-100 mb-2">
                  <TextField
                    label="Remark"
                    name="remark"
                    defaultValue={orderData.remark}
                    onChange={this.handleChange}
                    helperText="Enter a new remark (Only updated upon refresh)"
                    className="mt-2"></TextField>
                </FormControl>

                <FormControl className="w-100 mb-2">
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={orderData?.meta?.is_renewal}
                        onChange={this.handleCheckboxChange}
                        name="isRenewal"
                        color="primary"
                      />
                    }
                    label="Is renewal?"
                  />
                </FormControl>
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="default" variant="text">
            Close
          </Button>
          <Button
            variant="contained"
            className="text-white bg-danger"
            onClick={this.clickUpdateButton}
            disabled={updating}>
            Update {updating && <CircularProgress size={15} className="text-white ml-2" />}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

DialogUpdateOrder.propTypes = {
  isOpen: Proptypes.bool.isRequired,
  close: Proptypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogUpdateOrder)
