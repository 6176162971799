import * as Type from 'constants/actionTypes'
import _ from 'lodash'

const initialState = {
  lessonLink: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Type.GET_LESSONS:
      return {
        ...state,
        isLoading: true,
        lessons: null
      }

    case Type.GET_LESSONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        lessons: action.payload.data,
        lessonCount: action.payload.count,
        lessonPage: action.page ? action.page : 0
      }

    case Type.GET_LESSONS_FAILED:
      return {
        ...state,
        isLoading: false,
        lessons: null
      }

    case Type.GET_LESSON_COUNT:
      return {
        ...state,
        count: null,
        gettingCount: true
      }

    case Type.GET_LESSON_COUNT_SUCCESS:
      return {
        ...state,
        count: action.payload.count,
        gettingCount: false
      }

    case Type.GET_LESSON_COUNT_FAILED:
      return {
        ...state,
        count: 0,
        gettingCount: false
      }

    case Type.GET_LESSON_BATCH:
      return {
        ...state,
        isLoading: true,
        lessonBatch: null
      }

    case Type.GET_LESSON_BATCH_SUCCESS: {
      let payload = action.payload
      let lessonBatch = []

      for (let i = 0; i < payload.length; i++) {
        lessonBatch = _.concat(lessonBatch, payload[i].data)
      }

      return {
        ...state,
        isLoading: false,
        lessonBatch
      }
    }

    case Type.GET_LESSON_BATCH_FAILED:
      return {
        ...state,
        isLoading: false
      }

    case Type.GET_LESSON_STUDENT_DETAIL:
      return {
        ...state,
        isLoadingStudentDetail: true
      }

    case Type.GET_LESSON_STUDENT_DETAIL_SUCCESS: {
      let lessonBatch = []
      for (let i = 0; i < action.payload.length; i++) {
        lessonBatch.push(action.payload[i].data)
      }

      return {
        ...state,
        isLoadingStudentDetail: false,
        lessonBatch
      }
    }

    case Type.GET_LESSON_STUDENT_DETAIL_FAILED:
      return {
        ...state,
        isLoadingStudentDetail: false
      }

    case Type.GET_LESSON_BY_ID:
      return {
        ...state,
        isLoading: true
      }

    case Type.GET_LESSON_BY_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        lesson: action.payload.data
      }

    case Type.GET_LESSON_BY_ID_FAILED:
      return {
        ...state,
        isLoading: false,
        lesson: null
      }

    case Type.CREATE_LESSON:
      return {
        ...state,
        inProgress: true,
        creatingLesson: true,
        lessonCreated: false
      }

    case Type.CREATE_LESSON_SUCCESS:
      return {
        ...state,
        inProgress: false,
        creatingLesson: false,
        lessonCreated: true
      }

    case Type.CREATE_LESSON_FAILED:
      return {
        ...state,
        inProgress: false,
        creatingLesson: false,
        lessonCreated: false
      }

    case Type.EDIT_LESSON:
      return {
        ...state,
        inProgress: true,
        editingLesson: true,
        lessonEdited: false
      }

    case Type.EDIT_LESSON_SUCCESS:
      return {
        ...state,
        inProgress: false,
        editingLesson: false,
        lessonEdited: true
      }

    case Type.EDIT_LESSON_FAILED:
      return {
        ...state,
        inProgress: false,
        editingLesson: false,
        lessonEdited: false
      }

    case Type.UNLOAD_LESSON:
      return {
        ...state,
        lesson: null
      }

    case Type.CREATE_LESSON_POLL:
      return {
        ...state,
        creatingPoll: true,
        pollCreated: ''
      }

    case Type.CREATE_LESSON_POLL_SUCCESS:
      return {
        ...state,
        creatingPoll: false,
        pollCreated: true
      }

    case Type.CREATE_LESSON_POLL_FAILED:
      return {
        ...state,
        creatingPoll: false,
        pollCreated: false
      }

    case Type.EDIT_LESSON_POLL:
      return {
        ...state,
        editingPoll: true,
        pollEdited: ''
      }

    case Type.EDIT_LESSON_POLL_SUCCESS:
      return {
        ...state,
        editingPoll: false,
        pollEdited: true
      }

    case Type.EDIT_LESSON_POLL_FAILED:
      return {
        ...state,
        editingPoll: false,
        pollEdited: false
      }

    case Type.DELETE_LESSON_POLL:
      return {
        ...state,
        deletingPoll: true,
        pollDeleted: ''
      }

    case Type.DELETE_LESSON_POLL_SUCCESS:
      return {
        ...state,
        deletingPoll: false,
        pollDeleted: true
      }

    case Type.DELETE_LESSON_POLL_FAILED:
      return {
        ...state,
        deletingPoll: false,
        pollDeleted: false
      }

    case Type.GET_LESSON_BY_ID_2:
      return {
        ...state,
        isLoading: true,
        activeLesson: null
      }

    case Type.GET_LESSON_BY_ID_2_SUCCESS:
      return {
        ...state,
        isLoading: false,
        activeLesson: action.payload.data
      }

    case Type.GET_LESSON_BY_ID_2_FAILED:
      return {
        ...state,
        isLoading: false
      }

    case Type.GET_USER_LIVE_LESSON_LINK:
      return {
        ...state
      }

    case Type.GET_USER_LIVE_LESSON_LINK_SUCCESS: {
      const linkData = action.payload.data
      return {
        ...state,
        lessonLink: {
          ...state.lessonLink,
          [`${linkData.lesson}||${linkData.user}`]: linkData.link
        }
      }
    }

    case Type.REFRESH_LESSON:
      return {
        ...state,
        refreshingLesson: true
      }

    case Type.REFRESH_LESSON_SUCCESS:
      let updatedLesson = action.payload.data
      let lessonsList = state.lessons

      let lessonIndex = _.findIndex(lessonsList, function (o) {
        return o._id.toString() === updatedLesson._id.toString()
      })
      lessonsList[lessonIndex] = updatedLesson

      let activeLesson = updatedLesson

      return {
        ...state,
        refreshingLesson: false,
        lessons: lessonsList,
        activeLesson
      }

    case Type.SET_ACTIVE_LESSON:
      return {
        ...state,
        activeLesson: action.lesson.lesson
      }

    default:
      return state
  }
}
