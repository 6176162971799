import React from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core'
import {
  Done as CorrectIcon,
  CheckCircleOutline as AttendedIcon,
  CancelOutlined as AbsentIcon
} from '@material-ui/icons'
import moment from 'moment'
import _ from 'lodash'

import agent from 'agent'
import {
  DELETE_LESSON_POLL,
  GET_LESSON_BY_ID_2,
  GET_USER_LIVE_LESSON_LINK,
  REFRESH_LESSON
} from 'constants/actionTypes'
import { ROUTE_LESSON_MANAGEMENT, ROUTE_LESSON_DETAIL_TENOPY_CLASSROOM } from 'routes/paths'
import permissions, { checkActionPermitted } from 'permissions'

const mapStateToProps = state => ({
  userRole: state.auth.userRole,
  activeLesson: state.lessons.activeLesson,
  isLoading: state.lessons.isLoading,
  lessonLink: state.lessons.lessonLink
})

const mapDispatchToProps = dispatch => ({
  deletePollQuestion: (lessonId, questionIndex) =>
    dispatch({ type: DELETE_LESSON_POLL, payload: agent.Lesson.deletePollQuestion(lessonId, questionIndex) }),
  refreshLesson: lessonId => dispatch({ type: REFRESH_LESSON, payload: agent.Lesson.getLesson(lessonId) }),
  getLessonData: lessonId => dispatch({ type: GET_LESSON_BY_ID_2, payload: agent.Lesson.getLesson(lessonId) }),
  getUserLink: (lessonId, userId) =>
    dispatch({ type: GET_USER_LIVE_LESSON_LINK, payload: agent.Lesson.getUserLiveLessonLink(lessonId, userId) })
})

class Detail extends React.Component {
  state = {
    selectedQuestionIndex: -1,
    isCreateEditPollDialogOpen: false,
    isConfirmDeleteDialogOpen: false,
    canManagePoll: true
  }

  componentDidMount() {
    const lessonId = this.props.match.params.lessonId
    this.props.getLessonData(lessonId)

    this.setState({
      canManagePoll: checkActionPermitted(
        permissions,
        this.props.userRole,
        ROUTE_LESSON_DETAIL_TENOPY_CLASSROOM,
        'canManagePoll'
      )
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.deletingPoll && !this.props.deletingPoll) {
      this.setState({ isConfirmDeleteDialogOpen: false, selectedQuestionIndex: -1 })
    }

    if (prevProps.creatingPoll && !this.props.creatingPoll && this.props.pollCreated) {
      this.props.refreshLesson(this.props.activeLesson._id)
      this.setState({ isCreateEditPollDialogOpen: false })
    }

    if (prevProps.editingPoll && !this.props.editingPoll && this.props.pollEdited) {
      this.props.refreshLesson(this.props.activeLesson._id)
      this.setState({ isCreateEditPollDialogOpen: false })
    }

    if (prevProps.deletingPoll && !this.props.deletingPoll && this.props.pollDeleted) {
      this.props.refreshLesson(this.props.activeLesson._id)
    }
  }

  toggleCreateEditPollDialog = index => () => {
    this.setState({
      isCreateEditPollDialogOpen: !this.state.isCreateEditPollDialogOpen,
      selectedQuestionIndex: index
    })
  }

  toggleConfirmDeleteDialog = index => () => {
    this.setState({
      isConfirmDeleteDialogOpen: !this.state.isConfirmDeleteDialogOpen,
      selectedQuestionIndex: index
    })
  }

  deleteQuestion = () => {
    this.props.deletePollQuestion(this.props.activeLesson._id, this.state.selectedQuestionIndex)
  }

  getLessonLink = userId => () => {
    const { _id: lessonId } = this.props.activeLesson
    this.props.getUserLink(lessonId, userId)
  }

  render() {
    const { activeLesson, lessonLink, deletingPoll, isLoading } = this.props
    const { selectedQuestionIndex, isCreateEditPollDialogOpen, isConfirmDeleteDialogOpen, canManagePoll } = this.state

    if (!activeLesson || isLoading) {
      return (
        <div className="my-5 text-center">
          <CircularProgress color="primary" size={40} />
        </div>
      )
    }

    return (
      <div className="app-wrapper">
        <div className="row">
          <div className="col-12">
            <div
              className="text-primary mb-2"
              onClick={() => {
                this.props.history.push(ROUTE_LESSON_MANAGEMENT)
              }}>
              <i className="fa fa-angle-left mr-1"></i>
              <span className="clickable">Back</span>
            </div>

            <h2>Detail</h2>

            <table>
              <tbody>
                <tr>
                  <td>Class</td>
                  <td>:</td>
                  <td>{activeLesson.class.name}</td>
                </tr>
                <tr>
                  <td>Lesson</td>
                  <td>:</td>
                  <td>{activeLesson.name}</td>
                </tr>
                <tr>
                  <td>Time</td>
                  <td>:</td>
                  <td>
                    {moment(activeLesson.scheduled_on).format('lll')} - {moment(activeLesson.ended_on).format('h:mm a')}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="col-12 mt-3">
            <h2>Tenopy Classroom (Unique link)</h2>
            <TableContainer component={Paper}>
              <Table size="small" className="w-100">
                <TableHead>
                  <TableRow>
                    <TableCell>Type</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>
                      {moment() > moment(activeLesson.scheduled_on) && moment() < moment(activeLesson.ended_on)
                        ? 'Attending'
                        : 'Attended'}
                    </TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {activeLesson.tutor.map(t => (
                    <TableRow key={t._id}>
                      <TableCell>
                        <Chip label="Teacher" className="text-success" />
                      </TableCell>
                      <TableCell>{t.name}</TableCell>
                      <TableCell>{t.email}</TableCell>
                      <TableCell>---</TableCell>
                      <TableCell>
                        {lessonLink[`${activeLesson._id}||${t._id}`] ? (
                          <span>{lessonLink[`${activeLesson._id}||${t._id}`]}</span>
                        ) : (
                          <Button variant="outlined" color="primary" size="small" onClick={this.getLessonLink(t._id)}>
                            Get Link
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                  {activeLesson.ta.map(t => (
                    <TableRow key={t._id}>
                      <TableCell>
                        <Chip label="TA" className="text-success" />
                      </TableCell>
                      <TableCell>{t.name}</TableCell>
                      <TableCell>{t.email}</TableCell>
                      <TableCell>---</TableCell>
                      <TableCell>
                        {lessonLink[`${activeLesson._id}||${t._id}`] ? (
                          <span>{lessonLink[`${activeLesson._id}||${t._id}`]}</span>
                        ) : (
                          <Button variant="outlined" color="primary" size="small" onClick={this.getLessonLink(t._id)}>
                            Get Link
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                  {activeLesson.student.map(t => {
                    let attendance = 'not-available'
                    if (activeLesson.attendance?.length > 0) {
                      let attendanceIndex = _.findIndex(activeLesson.attendance, o => {
                        return o.user_id === t._id
                      })

                      if (attendanceIndex > -1) {
                        attendance = 'attended'
                      } else {
                        attendance = 'absent'
                      }
                    }

                    return (
                      <TableRow key={t._id}>
                        <TableCell>
                          <Chip label="Paid Student" className="text-cyan" />
                        </TableCell>
                        <TableCell>{t.name}</TableCell>
                        <TableCell>{t.email}</TableCell>
                        <TableCell>
                          {attendance === 'not-available' && <span>N/A</span>}
                          {attendance === 'attended' && <AttendedIcon className="text-success" />}
                          {attendance === 'absent' && <AbsentIcon className="text-danger" />}
                        </TableCell>
                        <TableCell>
                          {lessonLink[`${activeLesson._id}||${t._id}`] ? (
                            <span>{lessonLink[`${activeLesson._id}||${t._id}`]}</span>
                          ) : (
                            <Button variant="outlined" color="primary" size="small" onClick={this.getLessonLink(t._id)}>
                              Get Link
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                  {activeLesson.trial_student.map(t => {
                    let attendance = 'not-available'
                    if (activeLesson.attendance?.length > 0) {
                      let attendanceIndex = _.findIndex(activeLesson.attendance, o => {
                        return o.user_id === t._id
                      })

                      if (attendanceIndex > -1) {
                        attendance = 'attended'
                      } else {
                        attendance = 'absent'
                      }
                    }

                    return (
                      <TableRow key={t._id}>
                        <TableCell>
                          <Chip label="Trial Student" className="text-warning" />
                        </TableCell>
                        <TableCell>{t.name}</TableCell>
                        <TableCell>{t.email}</TableCell>
                        <TableCell>
                          {attendance === 'not-available' && <span>N/A</span>}
                          {attendance === 'attended' && <AttendedIcon className="text-success" />}
                          {attendance === 'absent' && <AbsentIcon className="text-danger" />}
                        </TableCell>
                        <TableCell>
                          {lessonLink[`${activeLesson._id}||${t._id}`] ? (
                            <span>{lessonLink[`${activeLesson._id}||${t._id}`]}</span>
                          ) : (
                            <Button variant="outlined" color="primary" size="small" onClick={this.getLessonLink(t._id)}>
                              Get Link
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <div className="col-12 mt-3">
            <div className="d-flex flex-row align-items-center my-3">
              <h2 className="mb-0">Lesson Activities</h2>
            </div>
            <table className="table table-responsive-md">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Type</th>
                  <th>Question</th>
                  <th>Answer</th>
                </tr>
              </thead>
              <tbody>
                {activeLesson.activity.map((activity, index) => (
                  <tr key={index}>
                    <td>{activity.order}</td>
                    <td>
                      <div className="manage-margin">
                        <Chip label={activity.content._type} />
                        {activity.content.mode && <Chip label={activity.content.mode} />}
                      </div>
                    </td>
                    <td>
                      <div dangerouslySetInnerHTML={{ __html: activity.content.question }} />
                    </td>
                    <td>
                      {activity.content._type === 'MultipleChoice' && (
                        <>
                          {activity.content.option.map((o, index) => (
                            <div key={index} className="d-flex align-items-center">
                              {o.label}. {o.content}
                              {activity.content.answer_index.includes(index) && (
                                <CorrectIcon
                                  className="ml-1 text-success"
                                  style={{ width: '0.8em', height: '0.8em' }}
                                />
                              )}
                            </div>
                          ))}
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Detail)
