import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal, ModalBody } from 'reactstrap'
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  DialogContent,
  Paper,
  FormControl,
  FormLabel,
  InputLabel,
  Select,
  Input,
  MenuItem,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  DialogActions,
  Card,
  CardContent,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Tab,
  Tabs,
  Divider,
  Radio,
  RadioGroup
} from '@material-ui/core'
import CsvParse from '@vtex/react-csv-parse'
import _ from 'lodash'
import moment from 'moment'
import { CREATE_ORDER, GET_CLASSES } from 'constants/actionTypes'
import agent from 'agent'
import { Close } from '@material-ui/icons'
import Pluralize from 'react-pluralize'

const mapStateToProps = state => ({
  classList: state.classroom.classList,
  isLoadingClasses: state.classroom.isLoadingClasses,
  creatingOrder: state.orders.creatingOrder,
  createError: state.orders.error,
  agentList: state.user.agentList
})

const mapDispatchToProps = dispatch => ({
  getClasses: filter => dispatch({ type: GET_CLASSES, payload: agent.Classroom.getClasses(filter) }),
  createOrder: req => dispatch({ type: CREATE_ORDER, payload: agent.Orders.createOrder(req) })
})

const PAYMENT_CHANNEL = ['offline', 'online']
const PAYMENT_PROVIDER = ['stripe', 'hitpay']

class DialogOrderCSV extends React.Component {
  constructor() {
    super()

    this.state = {
      type: 'PAYMENT',
      data: null,
      dataProcessed: false,
      processedData: [],
      orderedData: [],
      rejectedData: [],
      skippedData: [],
      discount_code: '',
      no_discount_code: false,
      handlingIndex: null,
      handlingObject: null,
      selectedClass: null,
      selectedLessons: [],
      allDone: false,
      class_name: '',
      selectClassDialogOpen: false,
      selectLessonDialogOpen: false,
      selectedTab: 'ordered',
      agent_id: '',
      paymentChannel: 'offline',
      paymentProvider: '',
      paymentCompletedOn: moment().format('YYYY-MM-DDTHH:mm'),
      isRenewal: false,
      errors: {}
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.processedData.length > 0 && !prevState.dataProcessed && this.state.dataProcessed) {
      this.setState({ handlingIndex: 0 })
    }

    if (prevProps.creatingOrder && !this.props.creatingOrder && !this.props.createError) {
      let orderedData = [...this.state.orderedData]
      orderedData.push(this.state.handlingObject)

      if (prevState.handlingIndex < this.state.processedData.length - 1) {
        this.setState(prevState => ({ handlingIndex: prevState.handlingIndex + 1, orderedData }))
      } else {
        this.setState({ allDone: true, orderedData })
      }
    }

    if (this.state.handlingIndex !== null && prevState.handlingIndex !== this.state.handlingIndex) {
      let object = { ...this.state.processedData[this.state.handlingIndex] }
      // if (object.email)

      if (
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          object.email.toLowerCase()
        )
      ) {
        this.setState(prevState => {
          let errors = { ...prevState.errors }
          delete errors['email']

          return { errors }
        })
      } else {
        this.setState(prevState => {
          let errors = { ...prevState.errors }
          errors['email'] = 'You have entered an invalid email'

          return { errors }
        })
      }

      if (
        (!!prevState.handlingObject && prevState.handlingObject.class !== object.class) ||
        !prevState.handlingObject
      ) {
        this.setState({ class_name: object.class })
        this.searchClass(object.class)
      }

      this.setState({ handlingObject: object })
    }

    if (prevProps.isLoadingClasses && !this.props.isLoadingClasses) {
      if (this.props.classList.length === 1) {
        let classObject = this.props.classList[0]

        this.setState({ selectedClass: classObject, selectClassDialogOpen: false })
      } else {
        this.setState({ selectedClass: null, selectClassDialogOpen: true })
      }
    }

    if (
      !!this.state.selectedClass &&
      (!_.isEqual(prevState.selectedClass, this.state.selectedClass) ||
        (!!prevState.handlingObject &&
          !!this.state.handlingObject &&
          (prevState.handlingObject.for_month !== this.state.handlingObject.for_month ||
            prevState.handlingObject.lessons !== this.state.handlingObject.lessons)))
    ) {
      let classObject = this.state.selectedClass
      let object = this.state.handlingObject

      let selectedLessons = this.getLessons(classObject.lesson, object.lessons, object.for_month)

      this.setState({ selectedLessons })
    }
  }

  manualSearch = e => {
    e.preventDefault()

    this.searchClass(this.state.class_name)
  }

  searchClass = class_name => {
    const { handlingObject } = this.state
    let className = null

    if (!!handlingObject) {
      className = handlingObject.class
    }

    if (!class_name && !className) {
      return null
    }

    let filter = {
      name: {
        $regex: className,
        $options: 'i'
      }
    }

    if (!!class_name) {
      filter['name']['$regex'] = class_name
    }

    this.props.getClasses(filter)
  }

  selectClass = classObject => () => {
    this.setState({ selectedClass: classObject })
  }

  toggleSelectClassDialog = () => {
    this.setState(prevState => ({ selectClassDialogOpen: !prevState.selectClassDialogOpen }))
  }

  toggleSelectLessonDialog = () => {
    this.setState(prevState => ({ selectLessonDialogOpen: !prevState.selectLessonDialogOpen }))
  }

  selectLesson = lessonObj => () => {
    const { selectedLessons, selectedClass } = this.state

    let availableLessons = selectedClass.lesson

    let toModify = _.cloneDeep(selectedLessons)

    let indexOfLesson = _.findIndex(toModify, lesson => {
      return lesson._id === lessonObj._id
    })

    if (indexOfLesson === -1) {
      //not found, add lesson
      toModify.push(lessonObj)
    } else {
      //found, remove from array
      _.remove(toModify, lesson => {
        return lesson._id === lessonObj._id
      })
    }

    toModify = _.orderBy(toModify, ['scheduled_on'], ['asc'])
    availableLessons = _.orderBy(availableLessons, ['scheduled_on'], ['asc'])

    let lessonRange = []
    if (toModify.length > 0) {
      let indexFirst = _.findIndex(availableLessons, i => i._id === toModify[0]._id)
      let indexLast = _.findIndex(availableLessons, i => i._id === toModify[toModify.length - 1]._id)

      lessonRange = availableLessons.slice(indexFirst, indexLast + 1)
    }

    this.setState({ selectedLessons: lessonRange })
  }

  getLessons = (lessonList, numOfLessons, for_month) => {
    let monthLessons = _.filter(lessonList, l => moment(l.scheduled_on).format('MMMM') === for_month)
    let futureLessons = _.filter(monthLessons, fl => {
      return moment(fl.scheduled_on).diff(moment(), 'hours', true) > 0
    })
    let sortedLessons = _.orderBy(futureLessons, ['scheduled_on'], ['asc'])
    numOfLessons = parseInt(numOfLessons)

    return sortedLessons.slice(0, numOfLessons)
  }

  validateInput = (target, value) => {
    if (target === 'email') {
      if (
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          value.toLowerCase()
        ) ||
        !value
      ) {
        this.setState(prevState => {
          let errors = { ...prevState.errors }
          delete errors[target]

          return { errors }
        })
      } else {
        this.setState(prevState => {
          let errors = { ...prevState.errors }
          errors[target] = 'You have entered an invalid email'

          return { errors }
        })
      }
    }
  }

  handleChange = e => {
    let name = e.target.name
    let value = e.target.value.trim()

    if (name === 'discount_code') {
      value = value.toUpperCase()
    }

    this.setState({ [name]: value })
  }

  handleCheckboxChange = event => {
    this.setState({
      [event.target.name]: event.target.checked
    })
  }

  handleObjectData = e => {
    let modified = { ...this.state.handlingObject }

    let name = e.target.name
    let value = e.target.value

    if (name === 'discount_code') {
      value = value.toUpperCase()
    }

    modified[name] = value

    this.validateInput(name, value)
    this.setState({ handlingObject: modified })
  }

  handleData = data => {
    this.setState({ data, dataProcessed: false })
  }

  handleError = (err, file, inputElem, reason) => {
    // eslint-disable-next-line no-console
    console.log(err, file, inputElem, reason)
  }

  processCSV = () => {
    const { data, discount_code, no_discount_code, agent_id, type } = this.state

    let processedData = []
    let rejectedData = []

    for (let i = 0; i <= data.length; i++) {
      let current = data[i]

      if (!current) {
        continue
      } else if (!current.email) {
        rejectedData.push(current)
        continue
      }

      let tempOrder = {
        type: type || 'PAYMENT',
        email: current.email,
        class: current.class,
        lessons: current.lessons,
        for_month: current.for_month,
        remark: current.transaction_number,
        discount_code: no_discount_code ? '' : discount_code,
        agent_id: agent_id || ''
      }

      processedData.push(tempOrder)
    }

    this.setState({
      processedData,
      rejectedData,
      skippedData: [],
      orderedData: [],
      dataProcessed: true,
      allDone: false
    })
  }

  submitOrder = () => {
    const {
      handlingObject,
      selectedClass,
      selectedLessons,
      discount_code,
      // type,
      // agent_id,
      paymentChannel,
      paymentProvider,
      paymentCompletedOn,
      isRenewal
    } = this.state

    let request = {
      type: handlingObject.type,
      agent_id: handlingObject.agent_id,
      email: handlingObject.email,
      class: selectedClass._id,
      remark: handlingObject.remark
    }

    let first = selectedLessons[0]
    let last = selectedLessons[selectedLessons.length - 1]

    let start_date = moment(first.scheduled_on).format('YYYY-MM-DD')
    let end_date = moment(last.scheduled_on).format('YYYY-MM-DD')

    request.start_date = start_date
    request.end_date = end_date

    if (!!discount_code.trim()) {
      request.discount_code = discount_code.trim()
    }

    // if (type) {
    //   request.type = type
    // }

    // if (agent_id) {
    //   request.agent_id = agent_id
    // }

    let paymentInfo = {
      channel: paymentChannel,
      provider: paymentProvider,
      completed_on: moment(paymentCompletedOn).toISOString()
    }

    if (paymentChannel === 'online') {
      if (paymentProvider === 'stripe') {
        paymentInfo['platform'] = 'credit-card'
      } else if (paymentProvider === 'hitpay') {
        paymentInfo['platform'] = 'paynow'
      }
    }
    request['payment'] = paymentInfo
    request['meta'] = {
      is_renewal: isRenewal
    }
    this.props.createOrder(request)
  }

  skipData = () => {
    let skippedData = [...this.state.skippedData]
    skippedData.push(this.state.handlingObject)
    this.setState(prevState => ({ skippedData, handlingIndex: prevState.handlingIndex + 1 }))
  }

  finalClose = () => {
    this.props.toggle()
  }

  changeTab = (e, value) => {
    this.setState({ selectedTab: value })
  }

  render() {
    const { isOpen, toggle, classList, isLoadingClasses, agentList } = this.props
    const {
      type,
      data,
      discount_code,
      no_discount_code,
      dataProcessed,
      processedData,
      handlingIndex,
      handlingObject,
      selectedClass,
      selectedLessons,
      class_name,
      selectClassDialogOpen,
      selectLessonDialogOpen,
      allDone,
      orderedData,
      skippedData,
      rejectedData,
      selectedTab,
      agent_id,
      paymentChannel,
      paymentProvider,
      paymentCompletedOn,
      isRenewal,
      errors
    } = this.state

    let lessonList = []
    if (!!selectedClass) {
      lessonList = _.orderBy(selectedClass.lesson, ['scheduled_on'], ['asc'])
    }

    let keys = [
      'no',
      'date_of_order',
      'name',
      'email',
      'class',
      'type',
      'for_month',
      'lessons',
      'llp_price',
      'total',
      'commission',
      'commission_paid',
      'transaction_number',
      'remarks',
      'to_be_paid'
    ]

    let sampleData = []

    if (!!data && data.length > 0) {
      sampleData = data.slice(0, 3)
    }

    return (
      <React.Fragment>
        <Modal centered isOpen={isOpen && !handlingObject} toggle={toggle}>
          <ModalBody>
            <h1>CSV Parser</h1>
            <CsvParse
              keys={keys}
              onDataUploaded={this.handleData}
              onError={this.handleError}
              render={onChange => <input type="file" className="form-control" onChange={onChange} />}
            />
            {!!data && (
              <Card className={'mt-3'}>
                <CardContent>
                  <div className="row">
                    <div className="col-6">
                      <FormControl className="w-100 mb-2">
                        <TextField
                          select
                          label="Order Type"
                          SelectProps={{ native: true }}
                          name={'type'}
                          value={type}
                          onChange={this.handleChange}>
                          <option value={'PAYMENT'}>Payment</option>
                          <option value={'TRIAL'}>Trial</option>
                          <option value={'PAYMENT_VIDEO'}>Video Only</option>
                        </TextField>
                      </FormControl>

                      <TextField
                        select
                        SelectProps={{ native: true }}
                        label="Agent"
                        name="agent_id"
                        value={agent_id}
                        onChange={this.handleChange}
                        helperText="Select one if order is from agent"
                        className="mt-2"
                        fullWidth>
                        {agentList.map(agent => (
                          <option key={agent._id} value={agent._id}>
                            {agent.name}
                          </option>
                        ))}
                      </TextField>

                      <TextField
                        disabled={no_discount_code}
                        name="discount_code"
                        value={discount_code}
                        type="text"
                        label={'Discount Code'}
                        onChange={this.handleChange}
                        fullWidth
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            name={'no_discount_code'}
                            checked={no_discount_code}
                            onChange={this.handleCheckboxChange}
                          />
                        }
                        label="Continue without discount code"
                      />

                      <FormControl className="w-100 mb-3" component="fieldset">
                        <FormLabel component="legend">
                          <span className="text-small">Payment Channel</span>
                        </FormLabel>
                        <RadioGroup row name="paymentChannel" value={paymentChannel} onChange={this.handleChange}>
                          {PAYMENT_CHANNEL.map((channel, index) => (
                            <FormControlLabel
                              key={index}
                              value={channel}
                              control={<Radio color="primary" />}
                              label={channel}
                              labelPlacement="end"
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>

                      {paymentChannel === 'online' && (
                        <FormControl className="w-100 mb-3" component="fieldset">
                          <FormLabel component="legend">
                            <span className="text-small">Payment Provider</span>
                          </FormLabel>
                          <RadioGroup row name="paymentProvider" value={paymentProvider} onChange={this.handleChange}>
                            {PAYMENT_PROVIDER.map((provider, index) => (
                              <FormControlLabel
                                key={index}
                                value={provider}
                                control={<Radio color="primary" />}
                                label={provider}
                                labelPlacement="end"
                              />
                            ))}
                          </RadioGroup>
                        </FormControl>
                      )}

                      <FormControl className="w-100 mb-3">
                        <TextField
                          label="Payment Complete Date"
                          type="datetime-local"
                          name="paymentCompletedOn"
                          onChange={this.handleChange}
                          value={paymentCompletedOn}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </FormControl>

                      <FormControl className="w-100 mb-3">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isRenewal}
                              onChange={this.handleCheckboxChange}
                              name="isRenewal"
                              color="primary"
                            />
                          }
                          label="Is renewal?"
                        />
                      </FormControl>
                    </div>
                    <div className="col-6 justify-content-end align-items-center">
                      <Button
                        variant={'contained'}
                        color={'primary'}
                        disabled={(!discount_code && !no_discount_code) || dataProcessed}
                        onClick={this.processCSV}>
                        {!dataProcessed ? 'Proceed' : 'Data Processed'}
                      </Button>
                    </div>
                  </div>
                  <hr />
                  <h3 className="text-center">Data Preview ({data.length} entries)</h3>
                  <Divider />
                  {sampleData.map((s, index) => (
                    <Card key={index} className="py-2 mb-2">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-md-4">
                            <h4>{s.name}</h4>
                            <div className="text-muted">{s.email}</div>
                          </div>
                          <div className="col-md-8">
                            <h3>{s.class}</h3>
                            <div className="text-muted">{s.lessons} Lessons</div>
                          </div>
                        </div>
                      </div>
                    </Card>
                  ))}
                </CardContent>
              </Card>
            )}
          </ModalBody>
        </Modal>

        {!!handlingObject && (
          <Dialog disableEnforceFocus open={!!handlingObject} fullScreen={true}>
            <AppBar className="position-relative">
              <Toolbar>
                <IconButton aria-label="Close" className={'text-white'} onClick={this.finalClose}>
                  <Close />
                </IconButton>
                <Typography
                  type="title"
                  color="inherit"
                  style={{
                    flex: 1
                  }}>
                  Confirm Order Details ({handlingIndex + 1} of {processedData.length})
                </Typography>
              </Toolbar>
            </AppBar>
            <DialogContent style={{ padding: '24px' }}>
              <Paper elevation={2} className={'app-wrapper mb-3'}>
                <FormControl className="w-100 mb-2">
                  <TextField
                    label={'Order Type'}
                    select
                    SelectProps={{ native: true }}
                    name={'type'}
                    value={handlingObject.type}
                    onChange={this.handleObjectData}
                    input={<Input id="type" />}>
                    <option value={'PAYMENT'}>Payment</option>
                    <option value={'TRIAL'}>Trial</option>
                    <option value={'PAYMENT_VIDEO'}>Video Only</option>
                  </TextField>
                </FormControl>
              </Paper>

              <div className="row">
                <div className="col">
                  {/*User Details*/}
                  <Paper elevation={2} className={'app-wrapper mb-3'}>
                    <FormControl className={'w-100 mb-3'}>
                      <TextField
                        type={'email'}
                        name={'email'}
                        value={handlingObject.email}
                        error={!!errors.email}
                        helperText={errors.email}
                        onChange={this.handleObjectData}
                        label={'Customer Email'}
                      />
                    </FormControl>
                  </Paper>
                  <Paper elevation={2} className={'app-wrapper'}>
                    <FormControl className={'w-100'}>
                      <TextField
                        select
                        SelectProps={{ native: true }}
                        label="Agent"
                        name="agent_id"
                        value={handlingObject.agent_id}
                        onChange={this.handleObjectData}
                        helperText="Select one if order is from agent"
                        className="mt-2">
                        {agentList.map(agent => (
                          <option key={agent._id} value={agent._id}>
                            {agent.name}
                          </option>
                        ))}
                      </TextField>
                      <TextField
                        name={'discount_code'}
                        value={handlingObject.discount_code}
                        label={'Discount Code'}
                        onChange={this.handleObjectData}
                      />
                      <TextField
                        name={'remark'}
                        value={handlingObject.remark}
                        onChange={this.handleObjectData}
                        label={'Remarks'}
                        multiline
                        className="mt-2"
                        helperText={
                          '*REQUIRED: Indicate the transaction ID for bank transfers or just a short description of the transaction for easy reference.'
                        }
                      />
                    </FormControl>
                  </Paper>
                  <Paper elevation={2} className="app-wrapper">
                    <FormControl className="w-100 mb-3" component="fieldset">
                      <FormLabel component="legend">
                        <span className="text-small">Payment Channel</span>
                      </FormLabel>
                      <RadioGroup row name="paymentChannel" value={paymentChannel} onChange={this.handleChange}>
                        {PAYMENT_CHANNEL.map((channel, index) => (
                          <FormControlLabel
                            key={index}
                            value={channel}
                            control={<Radio color="primary" />}
                            label={channel}
                            labelPlacement="end"
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>

                    {paymentChannel === 'online' && (
                      <FormControl className="w-100 mb-3" component="fieldset">
                        <FormLabel component="legend">
                          <span className="text-small">Payment Provider</span>
                        </FormLabel>
                        <RadioGroup row name="paymentProvider" value={paymentProvider} onChange={this.handleChange}>
                          {PAYMENT_PROVIDER.map((provider, index) => (
                            <FormControlLabel
                              key={index}
                              value={provider}
                              control={<Radio color="primary" />}
                              label={provider}
                              labelPlacement="end"
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    )}

                    <FormControl className="w-100 mb-3">
                      <TextField
                        label="Payment Complete Date"
                        type="datetime-local"
                        name="paymentCompletedOn"
                        onChange={this.handleChange}
                        value={paymentCompletedOn}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </FormControl>

                    <FormControl className="w-100 mb-3">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isRenewal}
                            onChange={this.handleCheckboxChange}
                            name="isRenewal"
                            color="primary"
                          />
                        }
                        label="Is renewal?"
                      />
                    </FormControl>
                  </Paper>
                </div>
                <div className="col">
                  {isLoadingClasses ? (
                    <div className="my-5 text-center">
                      <CircularProgress color={'primary'} size={24} />
                    </div>
                  ) : (
                    <Paper elevation={2} className={'app-wrapper mb-3'}>
                      <h3 className="font-weight-semibold">Which class is this order for?</h3>
                      <form onSubmit={this.manualSearch}>
                        <div className="row">
                          <div className="col">
                            <TextField
                              name={'class_name'}
                              label={'Class Name'}
                              value={class_name}
                              onChange={this.handleChange}
                              fullWidth
                            />
                          </div>
                          <div className="col-auto">
                            <Button
                              disabled={isLoadingClasses}
                              type={'submit'}
                              variant={'contained'}
                              color={'secondary'}>
                              Search{' '}
                              {isLoadingClasses && <CircularProgress color={'primary'} size={16} className={'ml-3'} />}
                            </Button>
                          </div>
                        </div>
                      </form>
                      {!!selectedClass && (
                        <Paper elevation={2} className={'app-wrapper mt-2'}>
                          <small className={'text-muted'}>Selected Class</small>
                          <h4 className="font-weight-semibold m-0">{selectedClass.name}</h4>
                        </Paper>
                      )}
                      {!!selectedClass && (
                        <Paper elevation={2} className={'app-wrapper mt-2'}>
                          <small className={'text-muted'}>Selected Lessons</small>
                          <div>
                            <div className="row">
                              <div className="col">
                                <h4 className="font-weight-semibold">
                                  <Pluralize
                                    count={!!selectedLessons ? selectedLessons.length : 0}
                                    singular={'lesson'}
                                  />
                                </h4>
                                {!!selectedLessons &&
                                  selectedLessons.map(sl => {
                                    return (
                                      <div key={sl._id} className="mb-2">
                                        <h4 className="mb-0">{sl.name}</h4>
                                        <div className="text-muted">
                                          <small>{moment(sl.scheduled_on).format('Do MMM YYYY, h:mma')}</small>
                                        </div>
                                      </div>
                                    )
                                  })}
                              </div>
                              <div className="col-auto">
                                <Button
                                  variant={'contained'}
                                  color={'secondary'}
                                  onClick={this.toggleSelectLessonDialog}>
                                  Select Lessons
                                </Button>
                              </div>
                            </div>
                            <Dialog open={selectLessonDialogOpen} fullWidth>
                              <DialogTitle>Select lessons</DialogTitle>
                              <DialogContent>
                                <h4 className="font-weight-semibold text-danger">
                                  Selection will be ALL lessons from earliest to latest lesson chosen
                                </h4>
                                <List disablePadding>
                                  {lessonList.map(item => {
                                    return (
                                      <ListItem
                                        key={item._id}
                                        button
                                        divider
                                        onClick={this.selectLesson(item)}
                                        className={
                                          _.findIndex(selectedClass, i => {
                                            return i._id === item._id
                                          }) !== -1
                                            ? 'bg-green lighten-5'
                                            : ''
                                        }>
                                        <ListItemText>
                                          <div>
                                            {_.findIndex(selectedLessons, i => {
                                              return i._id === item._id
                                            }) !== -1 && <i className="zmdi zmdi-check text-success mr-3" />}
                                            {item.name}
                                            {moment(item.ended_on) < moment() && (
                                              <span className="d-inline-block ml-3 border border-danger text-danger rounded py-1 px-3">
                                                Ended
                                              </span>
                                            )}
                                          </div>
                                          <div className="text-muted">
                                            <small>{moment(item.scheduled_on).format('ddd, D MMM YYYY, h:mma')}</small>
                                          </div>
                                        </ListItemText>
                                      </ListItem>
                                    )
                                  })}
                                </List>
                              </DialogContent>
                              <DialogActions>
                                <Button color={'primary'} onClick={this.toggleSelectLessonDialog}>
                                  Done
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </div>
                        </Paper>
                      )}
                      {selectClassDialogOpen && (
                        <Dialog open={selectClassDialogOpen} fullWidth>
                          <DialogTitle>Select a class</DialogTitle>
                          <DialogContent>
                            <FormControl className={'w-100 app-wrapper'}>
                              <form onSubmit={this.manualSearch}>
                                <div className="row">
                                  <div className="col">
                                    <TextField
                                      name={'class_name'}
                                      label={'Class Name'}
                                      value={class_name}
                                      onChange={this.handleChange}
                                      fullWidth
                                    />
                                  </div>
                                  <div className="col-auto">
                                    <Button
                                      disabled={isLoadingClasses}
                                      type={'submit'}
                                      variant={'contained'}
                                      color={'secondary'}>
                                      Search{' '}
                                      {isLoadingClasses && (
                                        <CircularProgress color={'primary'} size={16} className={'ml-3'} />
                                      )}
                                    </Button>
                                  </div>
                                </div>
                              </form>
                            </FormControl>
                            <Paper elevation={2} className={'mt-3'}>
                              <h3 className={'border-bottom mb-0 font-weight-semibold app-wrapper py-3'}>
                                Results for '{class_name}'
                              </h3>
                              <List disablePadding>
                                {isLoadingClasses ? (
                                  <ListItem>
                                    <ListItemText disableTypography>
                                      <CircularProgress color={'primary'} size={20} />
                                    </ListItemText>
                                  </ListItem>
                                ) : classList.length > 0 ? (
                                  classList.map(item => {
                                    return (
                                      <ListItem key={item._id} button divider onClick={this.selectClass(item)}>
                                        <ListItemText>
                                          {!!selectedClass && selectedClass._id === item._id && (
                                            <i className="zmdi zmdi-check text-success mr-3" />
                                          )}
                                          {item.name}
                                        </ListItemText>
                                      </ListItem>
                                    )
                                  })
                                ) : (
                                  <ListItem>
                                    <ListItemText>No classes found</ListItemText>
                                  </ListItem>
                                )}
                              </List>
                            </Paper>
                          </DialogContent>
                          <DialogActions>
                            <Button color={'primary'} onClick={this.toggleSelectClassDialog}>
                              Done
                            </Button>
                          </DialogActions>
                        </Dialog>
                      )}
                    </Paper>
                  )}
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button variant="text" color={'default'} onClick={this.skipData}>
                Skip
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={this.submitOrder}
                disabled={selectedLessons.length === 0 || errors.email}>
                Create Order
              </Button>
            </DialogActions>
          </Dialog>
        )}
        {allDone && (
          <Dialog open={allDone} maxWidth={'lg'} fullWidth>
            <DialogTitle>Summary</DialogTitle>
            <DialogContent>
              <Tabs value={selectedTab} onChange={this.changeTab}>
                <Tab className="tab" label={`Processed (${orderedData.length})`} value={'ordered'} />
                <Tab className="tab" label={`Skipped (${skippedData.length})`} value={'skipped'} />
                <Tab className="tab" label={`Rejected (${rejectedData.length})`} value={'rejected'} />
              </Tabs>
              {selectedTab === 'ordered' && (
                <List disablePadding>
                  {orderedData.map((item, index) => {
                    return (
                      <ListItem key={index} divider>
                        <ListItemText>
                          <h3 className={'font-weight-semibold'}>{item.email}</h3>
                          <div>{item.class}</div>
                          <div>Agent Id: {item.agent_id}</div>
                          <div>{item.type}</div>
                          <div>
                            <small>
                              {item.lessons} lessons for {item.for_month}
                            </small>
                          </div>
                        </ListItemText>
                      </ListItem>
                    )
                  })}
                </List>
              )}
              {selectedTab === 'skipped' && (
                <List disablePadding>
                  {skippedData.map((item, index) => {
                    return (
                      <ListItem key={index} divider>
                        <ListItemText>
                          <h3 className={'font-weight-semibold'}>{item.email}</h3>
                          <div>{item.class}</div>
                          <div>
                            <small>
                              {item.lessons} lessons for {item.for_month}
                            </small>
                          </div>
                        </ListItemText>
                      </ListItem>
                    )
                  })}
                </List>
              )}
              {selectedTab === 'rejected' && (
                <List disablePadding>
                  {rejectedData.map((item, index) => {
                    return (
                      <ListItem key={index} divider>
                        <ListItemText>
                          <h3 className={'font-weight-semibold'}>{item.email}</h3>
                          <div>{item.class}</div>
                          <div>
                            <small>
                              {item.lessons} lessons for {item.for_month}
                            </small>
                          </div>
                        </ListItemText>
                      </ListItem>
                    )
                  })}
                </List>
              )}
            </DialogContent>
            <DialogActions>
              <Button color={'primary'} onClick={this.finalClose}>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </React.Fragment>
    )
  }
}

DialogOrderCSV.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogOrderCSV)
