import React from 'react'
import { connect } from 'react-redux'
import agent from 'agent'
import _ from 'lodash'
import moment from 'moment'
import CircularProgress from '@material-ui/core/CircularProgress'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Pluralize from 'react-pluralize'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { GET_LESSONS, GET_USERS } from 'constants/actionTypes'

const mapStateToProps = state => ({
  tutors: state.user.userList,
  lessons: state.lessons.lessons,
  lessonCount: state.lessons.lessonCount
})

const mapDispatchToProps = dispatch => ({
  getUsers: (filter, offset) =>
    dispatch({ type: GET_USERS, payload: agent.User.getUsers({ ...filter, _type: 'Tutor' }, offset) }),
  getLessons: filter => dispatch({ type: GET_LESSONS, payload: agent.Lesson.getLessonList(filter, 100, 0) })
})

class PaymentCalculator extends React.Component {
  constructor(props) {
    super()

    const { rate, lessons } = props
    let total = this.calculateTotal(rate, lessons)

    this.state = {
      total: total
    }
  }

  componentDidMount() {
    this.sendTotal()
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.rate, this.props.rate) || !_.isEqual(prevProps.lessons, this.props.lessons)) {
      let total = this.calculateTotal(this.props.rate, this.props.lessons)

      this.setState({ total })
    }

    if (prevState.total !== this.state.total) {
      this.sendTotal()
    }
  }

  calculateTotal = (rate, lessons) => {
    const { base, base_students, additional } = rate

    let total = 0
    for (let i = 0; i < lessons.length; i++) {
      let currentLesson = lessons[i]
      let started = moment(currentLesson.scheduled_on)
      let ended = moment(currentLesson.ended_on)
      let lessonHours = ended.diff(started, 'hours', true)

      let students = _.cloneDeep(currentLesson.student)
      students = _.filter(students, s => {
        return s !== 'euea4bE5QPK0SEmJ7HpVOw2'
      })

      let numberOfStudents = students.length
      total += base * lessonHours
      let moreThanBase = numberOfStudents - base_students

      if (moreThanBase > 0) {
        let extraPay = additional * moreThanBase * lessonHours
        total += extraPay
      }
    }

    return total
  }

  sendTotal = () => {
    const { total } = this.state
    const { onCalculate } = this.props

    if (!!onCalculate) {
      this.props.onCalculate(total)
    } else {
      // eslint-disable-next-line no-console
      console.log('onCalculate function not found in PaymentCalculator')
    }
  }

  render() {
    const { total } = this.state
    return <h2>${total}</h2>
  }
}

class TutorPayments extends React.Component {
  constructor(props) {
    super()

    this.handleChange = e => {
      this.setState({ [e.target.name]: e.target.value })
    }

    this.toggleTutorSearchDialog = () => {
      this.setState({ tutorSearchDialogOpen: !this.state.tutorSearchDialogOpen })
    }

    this.selectTutor = tutor => {
      this.setState({ selectedTutor: tutor, tutorSearchDialogOpen: false })
    }

    this.state = {
      searchTerm: '',
      searchField: 'name',
      selectedTutor: null,
      tutorSearchDialogOpen: false,
      selectedMonth: moment().subtract(1, 'month').format('MMMM'),
      selectedYear: moment().subtract(1, 'month').format('YYYY'),
      groupedLessons: null,
      rates: [],
      totalsList: []
    }

    this.searchTutors = e => {
      e.preventDefault()
      const { searchTerm, searchField } = this.state

      let filter = { [searchField]: { $regex: `${searchTerm}`, $options: 'i' } }

      this.props.getUsers(filter, 0)
      this.setState({ tutorSearchDialogOpen: true })
    }

    this.clearSearch = () => {
      this.setState({ searchTerm: '' })
      this.props.getUsers({}, 0)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevState.selectedTutor, this.state.selectedTutor) && !!this.state.selectedTutor) {
      this.resetLessons()
      this.getLessonsInRange()
    }

    if (prevState.selectedMonth !== this.state.selectedMonth) {
      this.resetLessons()
      this.getLessonsInRange()
    }

    if (prevState.selectedYear !== this.state.selectedYear) {
      this.resetLessons()
      this.getLessonsInRange()
    }

    if (!_.isEqual(prevProps.lessons, this.props.lessons) && !!this.props.lessons) {
      const { lessons } = this.props

      let groupedLessons = _.groupBy(lessons, 'class.name')
      let keyArr = Object.keys(groupedLessons)
      let rates = []

      for (let k = 0; k < keyArr.length; k++) {
        if (groupedLessons[keyArr[k]][0].class.class_type === 'Workshop') {
          rates.push({ base: 70, base_students: 10, additional: 2 })
        } else {
          rates.push({ base: 40, base_students: 5, additional: 5 })
        }
      }

      this.setState({ groupedLessons, rates })
    }
  }

  changeRate = index => e => {
    const { rates } = this.state
    let modified = _.cloneDeep(rates)

    let targetRate = modified[index]
    targetRate[e.target.name] = e.target.value

    this.setState({ rates: modified })
  }

  resetLessons = () => {
    this.setState({ totalsList: [], rates: [], groupedLessons: null })
  }

  addToList = index => total => {
    const { totalsList } = this.state

    totalsList[index] = total

    this.setState({ totalsList })
  }

  getLessonsInRange() {
    const { selectedMonth, selectedTutor, selectedYear } = this.state
    let startRange = moment(`01 ${selectedMonth} ${selectedYear}`).startOf('month').startOf('day')
    let endRange = moment(startRange).add(1, 'month')

    let filter = {
      scheduled_on: {
        $gte: startRange.toISOString(),
        $lt: endRange.toISOString()
      },
      tutor: selectedTutor._id,
      is_active: true
    }

    this.props.getLessons(filter)
  }

  render() {
    const { tutors, lessons, lessonCount } = this.props
    const {
      searchTerm,
      searchField,
      selectedTutor,
      tutorSearchDialogOpen,
      selectedMonth,
      selectedYear,
      groupedLessons,
      rates,
      totalsList
    } = this.state

    return (
      <div className="app-wrapper">
        <h1>Tutor Payments</h1>
        <Paper elevation={2} className={'app-wrapper mb-3'}>
          <h2 className={'font-weight-semibold border-bottom'}>Select a tutor to calculate payment for</h2>
          <div className="select_tutor">
            <form onSubmit={this.searchTutors}>
              <div className="row">
                <div className="col">
                  <TextField
                    name={'searchTerm'}
                    label={'Tutor Name/Email'}
                    value={searchTerm}
                    onChange={this.handleChange}
                    fullWidth
                  />
                </div>
                <div className="col-auto d-flex align-items-end">
                  <Button type={'submit'} color={'primary'} variant={'contained'}>
                    Search
                  </Button>
                </div>
                <div className="col-auto d-flex align-items-end">
                  <Button type={'button'} variant={'text'} onClick={this.clearSearch}>
                    Clear
                  </Button>
                </div>
                <div className="col-12">
                  <FormControl component="fieldset" required>
                    <RadioGroup
                      className="d-flex flex-row"
                      aria-label="searchField"
                      name="searchField"
                      value={searchField}
                      onChange={this.handleChange}>
                      <FormControlLabel value="name" control={<Radio color="primary" />} label="Name" />
                      <FormControlLabel value="email" control={<Radio color="primary" />} label="Email" />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            </form>
            {tutorSearchDialogOpen && !!tutors && (
              <Dialog open={tutorSearchDialogOpen} onClose={this.toggleTutorSearchDialog}>
                <DialogTitle>
                  Tutors with {searchField} matching '{searchTerm}'
                </DialogTitle>
                <div>
                  <List>
                    {tutors.length > 0 ? (
                      tutors.map(tutor => (
                        <ListItem key={tutor._id} button onClick={() => this.selectTutor(tutor)}>
                          <ListItemText primary={tutor.name} secondary={tutor.email} />
                        </ListItem>
                      ))
                    ) : (
                      <ListItem>
                        <ListItemText primary={'There were no tutors found matching your search'} />
                      </ListItem>
                    )}
                  </List>
                </div>
              </Dialog>
            )}
          </div>
        </Paper>
        {selectedTutor && (
          <Paper elevation={2} className={'app-wrapper mb-3'}>
            <div className="row">
              <div className="col-auto">
                <h2 className="font-weight-semibold">{selectedTutor.name}</h2>
              </div>
              <div className="col">
                <h2 className={'border-bottom'}>Month of payment</h2>
                <div className="date_range">
                  <FormControl>
                    <InputLabel htmlFor="month">Month</InputLabel>
                    <Select
                      name={'selectedMonth'}
                      value={selectedMonth}
                      onChange={this.handleChange}
                      input={<Input id="month" />}>
                      <MenuItem value={'January'}>January</MenuItem>
                      <MenuItem value={'February'}>February</MenuItem>
                      <MenuItem value={'March'}>March</MenuItem>
                      <MenuItem value={'April'}>April</MenuItem>
                      <MenuItem value={'May'}>May</MenuItem>
                      <MenuItem value={'June'}>June</MenuItem>
                      <MenuItem value={'July'}>July</MenuItem>
                      <MenuItem value={'August'}>August</MenuItem>
                      <MenuItem value={'September'}>September</MenuItem>
                      <MenuItem value={'October'}>October</MenuItem>
                      <MenuItem value={'November'}>November</MenuItem>
                      <MenuItem value={'December'}>December</MenuItem>
                    </Select>
                  </FormControl>
                  {/* NOTE: use this stupid implementation for now, need to re-work this whole thing later */}
                  <FormControl>
                    <InputLabel htmlFor="month">Year</InputLabel>
                    <Select
                      name={'selectedYear'}
                      value={selectedYear}
                      onChange={this.handleChange}
                      input={<Input id="year" />}>
                      <MenuItem value={'2018'}>2018</MenuItem>
                      <MenuItem value={'2019'}>2019</MenuItem>
                      <MenuItem value={'2020'}>2020</MenuItem>
                      <MenuItem value={'2021'}>2021</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
          </Paper>
        )}
        {selectedTutor ? (
          !!lessons && !!groupedLessons ? (
            <Paper elevation={2} className={'app-wrapper mb-3'}>
              <h2>
                <strong>{lessonCount}</strong> lessons conducted by {selectedTutor.name} in the month of {selectedMonth}{' '}
                {selectedYear}
              </h2>
              {Object.keys(groupedLessons).map((key, index) => {
                return (
                  <div key={index} className={'border-bottom app-wrapper'}>
                    <h3>
                      {key} | ({groupedLessons[key][0].class.class_type})
                    </h3>
                    <div className="row">
                      <div className="col">
                        <div className="row">
                          <div className="col">
                            <TextField
                              label={'Base Rate ($)'}
                              type={'number'}
                              name={'base'}
                              onChange={this.changeRate(index)}
                              value={rates[index]['base']}
                              fullWidth={true}
                            />
                          </div>
                          <div className="col">
                            <TextField
                              label={'Base Rate student limit'}
                              type={'number'}
                              name={'base_students'}
                              value={rates[index]['base_students']}
                              onChange={this.changeRate(index)}
                              fullWidth={true}
                            />
                          </div>
                          <div className="col">
                            <TextField
                              label={'Rate/additional student ($)'}
                              type={'number'}
                              name={'additional'}
                              value={rates[index]['additional']}
                              onChange={this.changeRate(index)}
                              fullWidth={true}
                            />
                          </div>
                        </div>

                        <ExpansionPanel>
                          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>Details</ExpansionPanelSummary>
                          <ExpansionPanelDetails>
                            <div className="row w-100">
                              <div className="col-2">
                                <Pluralize singular={'Lesson'} count={groupedLessons[key].length} />
                              </div>
                              <div className="col">
                                <table className={'table'}>
                                  <tbody>
                                    {groupedLessons[key].map(item => {
                                      let filteredStudents = _.filter(item.student, s => {
                                        return s !== 'euea4bE5QPK0SEmJ7HpVOw2'
                                      })
                                      let numberOfStudents = filteredStudents.length

                                      return (
                                        <tr key={`hours_${item._id}`}>
                                          <td>{item.name}</td>
                                          <td>{moment(item.scheduled_on).format('ddd, D MMM YYYY, h:mma')}</td>
                                          <td>
                                            <Pluralize
                                              count={moment(item.ended_on).diff(
                                                moment(item.scheduled_on),
                                                'hours',
                                                true
                                              )}
                                              singular={'hour'}
                                            />
                                          </td>
                                          <td>
                                            <Pluralize count={numberOfStudents} singular={'student'} />
                                          </td>
                                        </tr>
                                      )
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                      </div>
                      <div className="col-2 text-right">
                        <h3 className="font-weight-semibold">Subtotal</h3>
                        <PaymentCalculator
                          rate={rates[index]}
                          lessons={groupedLessons[key]}
                          onCalculate={this.addToList(index)}
                        />
                      </div>
                    </div>
                  </div>
                )
              })}
              <div className="app-wrapper">
                <div className="row">
                  <div className="col-2 text-right offset-10">
                    <h2 className="font-weight-semibold">Total</h2>
                    <h1 className="font-weight-bold text-success">${totalsList.reduce((a, b) => a + b, 0)}</h1>
                  </div>
                </div>
              </div>
            </Paper>
          ) : (
            <div className="my-5 text-center">
              <CircularProgress color={'primary'} size={20} />
            </div>
          )
        ) : null}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TutorPayments)
