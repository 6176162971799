import React from 'react'
import { connect } from 'react-redux'

import { Avatar, Chip, CircularProgress } from '@material-ui/core'
import _ from 'lodash'

import agent from 'agent'
import { GET_SUBMISSION_STAT } from 'constants/actionTypes'

const mapStateToProps = state => ({
  loadingSubmissionStat: state.submission.loadingSubmissionStat,
  submissionStat: state.submission.submissionStat
})

const mapDispatchToProps = dispatch => ({
  getSubmissionStat: homeworkId =>
    dispatch({ type: GET_SUBMISSION_STAT, payload: agent.Submission.getStat({ homework: homeworkId }), homeworkId })
})

class HomeworkTab extends React.Component {
  state = {
    listData: [],
    isStudentListDialogOpen: false
  }

  componentDidMount() {
    this.props.getSubmissionStat(this.props.homeworkData._id)
  }

  showSubmissionDetailDialog = (count, homeworkId, status) => () => {
    if (count > 0) {
      this.props.showDetail(homeworkId, status)()
    }
  }

  render() {
    let { homeworkData, loadingSubmissionStat, submissionStat, overDue, overReturn } = this.props
    let homeworkId = homeworkData._id
    let stat = submissionStat[homeworkId]

    if (loadingSubmissionStat.includes(homeworkId) || _.isEmpty(stat)) {
      return <CircularProgress color={'primary'} size={25} />
    }

    let submittedChipColor = 'bg-info'
    if (overDue && stat.submitted > 0) {
      submittedChipColor = 'bg-warning'
    }

    if (overReturn && stat.submitted > 0) {
      submittedChipColor = 'bg-danger blink'
    }

    let pendingSubmission =
      homeworkData.student.length - stat.submitted - stat.resubmitted - stat.resubmitted - stat.marked

    return (
      <div className="row">
        <div className="col-12">
          <div className="d-flex flex-wrap manage-margin mt-3">
            {pendingSubmission > 0 && (
              <Chip
                variant="default"
                label="Pending Submission"
                className="bg-secondary text-white clickable"
                avatar={<Avatar className="bg-white">{pendingSubmission}</Avatar>}
                onClick={this.showSubmissionDetailDialog(pendingSubmission, homeworkId, 'PENDING_SUBMISSION')}
              />
            )}

            {stat.submitted > 0 && (
              <Chip
                variant="default"
                label="Submitted"
                className={`${submittedChipColor} text-white ${stat.submitted > 0 ? 'clickable' : ''}`}
                avatar={<Avatar className="bg-white">{stat.submitted}</Avatar>}
                onClick={this.showSubmissionDetailDialog(stat.submitted, homeworkId, 'SUBMITTED')}
              />
            )}

            {stat.resubmitted > 0 && (
              <Chip
                variant="default"
                label="Resubmitted"
                className={`bg-warning text-white ${stat.resubmitted > 0 ? 'clickable' : ''}`}
                avatar={<Avatar className="bg-white">{stat.resubmitted}</Avatar>}
                onClick={this.showSubmissionDetailDialog(stat.resubmitted, homeworkId, 'RESUBMITTED')}
              />
            )}

            {stat.resubmission > 0 && (
              <Chip
                variant="default"
                label="Ask for Resubmission"
                className={`bg-info text-white ${stat.resubmission > 0 ? 'clickable' : ''}`}
                avatar={<Avatar className="bg-white">{stat.resubmission}</Avatar>}
                onClick={this.showSubmissionDetailDialog(stat.resubmission, homeworkId, 'RESUBMISSION_REQUIRE')}
              />
            )}

            <Chip
              variant="default"
              label="Marked"
              className="bg-success text-white"
              avatar={<Avatar className="bg-white">{stat.marked}</Avatar>}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeworkTab)
