import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import {
  ROUTE_COURSES,
  ROUTE_DISCOUNT_CODE,
  ROUTE_FEEDBACK,
  ROUTE_ORDERS,
  ROUTE_TUTOR_PAYMENTS,
  ROUTE_USER_MANAGEMENT,
  ROUTE_REPORT,
  ROUTE_LESSON_MANAGEMENT,
  ROUTE_TLS,
  ROUTE_NUMBER_DASHBOARD,
  ROUTE_TICKETS_TRIAL,
  ROUTE_TICKETS_RENEWAL,
  ROUTE_TICKETS_STAT,
  ROUTE_OPS_DASHBOARD,
  ROUTE_QUESTION_BANK
} from 'routes/paths'
import { connect } from 'react-redux'
import { NavItem, NavSubMenu, NavSubmenuItem } from './_SideNavComponents'

const mapStateToProps = state => ({
  userRole: state.auth.userRole,
  currentUser: state.common.currentUser
})

class SideNavContent extends Component {
  goToPage = location => () => {
    this.props.history.push(location)
  }

  render() {
    const { userRole } = this.props

    return (
      <ul className="nav-menu">
        <li className="nav-header">Main</li>
        <NavItem userRole={userRole} to={ROUTE_NUMBER_DASHBOARD}>
          <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
          <span className="nav-text">Stats</span>
        </NavItem>
        <NavItem userRole={userRole} to={ROUTE_REPORT}>
          <i className="zmdi zmdi-assignment zmdi-hc-fw" />
          <span className="nav-text">Sales Report</span>
        </NavItem>
        <NavItem userRole={userRole} to={ROUTE_USER_MANAGEMENT}>
          <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
          <span className="nav-text">User</span>
        </NavItem>
        <NavItem userRole={userRole} to={ROUTE_COURSES}>
          <i className="zmdi zmdi-desktop-mac zmdi-hc-fw" />
          <span className="nav-text">Online Class</span>
        </NavItem>
        <NavItem userRole={userRole} to={ROUTE_LESSON_MANAGEMENT}>
          <i className="zmdi zmdi-assignment zmdi-hc-fw" />
          <span className="nav-text">Lesson</span>
        </NavItem>
        <NavItem userRole={userRole} to={ROUTE_ORDERS}>
          <i className="zmdi zmdi-view-day zmdi-hc-fw" />
          <span className="nav-text">Order</span>
        </NavItem>
        <NavItem userRole={userRole} to={ROUTE_DISCOUNT_CODE}>
          <i className="zmdi zmdi-star zmdi-hc-fw" />
          <span className="nav-text">Discount Code</span>
        </NavItem>
        <NavItem userRole={userRole} to={ROUTE_TUTOR_PAYMENTS}>
          <i className="zmdi zmdi-money-box zmdi-hc-fw" />
          <span className="nav-text">Tutor Payment</span>
        </NavItem>
        <NavSubMenu
          title="Tickets"
          icon="zmdi zmdi-label-alt zmdi-hc-fw"
          keyword="tickets"
          userRole={userRole}
          to={ROUTE_TICKETS_TRIAL}
          location={this.props.location.pathname}
          goToPage={this.goToPage(ROUTE_TICKETS_TRIAL)}>
          <NavSubmenuItem to={ROUTE_TICKETS_STAT}>
            <span className="nav-text">Overall Stat</span>
          </NavSubmenuItem>

          <NavSubmenuItem to={ROUTE_TICKETS_TRIAL}>
            <span className="nav-text">Trial</span>
          </NavSubmenuItem>

          <NavSubmenuItem to={ROUTE_TICKETS_RENEWAL}>
            <span className="nav-text">Renewal</span>
          </NavSubmenuItem>
        </NavSubMenu>
        <NavItem userRole={userRole} to={ROUTE_FEEDBACK}>
          <i className="zmdi zmdi-comment-text zmdi-hc-fw" />
          <span className="nav-text">Feedback</span>
        </NavItem>
        <NavItem userRole={userRole} to={ROUTE_QUESTION_BANK}>
          <i className="zmdi zmdi-pin-help zmdi-hc-fw" />
          <span className="nav-text">Question Bank (Beta)</span>
        </NavItem>
        <NavItem userRole={userRole} to={ROUTE_OPS_DASHBOARD}>
          <i className="zmdi zmdi-settings zmdi-hc-fw" />
          <span className="nav-text">Ops (Beta)</span>
        </NavItem>
        <NavItem userRole={userRole} to={ROUTE_TLS}>
          <i className="zmdi zmdi-minus-circle zmdi-hc-fw" />
          <span className="nav-text">TLS (deprecated)</span>
        </NavItem>
      </ul>
    )
  }
}

export default connect(mapStateToProps)(withRouter(SideNavContent))
