import * as Type from 'constants/actionTypes'
import _ from 'lodash'

const defaultState = {
  agentList: [],
  loadingCredit: false,
  addingCredit: false
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case Type.GET_USERS:
      return {
        ...state,
        isLoading: true,
        userList: null
      }

    case Type.GET_USERS_SUCCESS: {
      let userList = state.userList || []
      let tutorList = state.tutorList ? state.tutorList : []
      let taList = state.taList ? state.taList : []
      let agentList = state.agentList || []
      let userCount = state.userCount || 0
      let tutorCount = state.tutorCount || 0
      let taCount = state.taCount || 0
      let agentCount = state.agentCount || 0

      if (action.userType === 'Tutor') {
        tutorList = action.payload.data
        tutorCount = action.payload.count
      } else if (action.userType === 'TA') {
        taList = action.payload.data
        taCount = action.payload.count
      } else if (action.userType === 'Agent') {
        agentList = action.payload.data
        agentCount = action.payload.count
      } else {
        userList = action.payload.data
        userCount = action.payload.count
      }

      return {
        ...state,
        isLoading: false,
        userList,
        userCount,
        tutorList,
        tutorCount,
        taList,
        taCount,
        agentList,
        agentCount,
        userPage: action.page
      }
    }

    case Type.GET_USERS_FAILED:
      return {
        ...state,
        isLoading: false
      }

    case Type.GET_AGENT_LIST_SUCCESS:
      return {
        ...state,
        agentList: action.payload.data,
        agentCount: action.payload.count
      }

    case Type.GET_USER_CREDIT:
      return {
        ...state,
        loadingCredit: true
      }

    case Type.GET_USER_CREDIT_SUCCESS: {
      let updatedList = state.userList
      let updatedUserIndex = _.findIndex(state.userList, o => {
        return o._id === action.userId
      })

      if (updatedUserIndex > -1) {
        updatedList[updatedUserIndex]['credit'] = action.payload.data
      }

      return {
        ...state,
        loadingCredit: false,
        userList: updatedList
      }
    }

    case Type.GET_USER_CREDIT_FAILED:
      return {
        ...state,
        loadingCredit: false
      }

    case Type.ADD_USER_CREDIT:
      return {
        ...state,
        addingCredit: true
      }

    case Type.ADD_USER_CREDIT_SUCCESS:
    case Type.ADD_USER_CREDIT_FAILED:
      return {
        ...state,
        addingCredit: false
      }

    case Type.CREATE_USER:
      return {
        ...state,
        isLoading: true,
        userCreated: false,
        error: false
      }
    case Type.CREATE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userCreated: true
      }
    case Type.CREATE_USER_FAILED:
      return {
        ...state,
        isLoading: false,
        error: true
      }
    case Type.UPDATE_USER:
      return {
        ...state,
        isLoading: true,
        userUpdated: false,
        error: false
      }
    case Type.UPDATE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userUpdated: true
      }
    case Type.UPDATE_USER_FAILED:
      return {
        ...state,
        isLoading: false,
        error: true
      }

    default:
      return state
  }
}
