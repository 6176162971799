import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { AppBar, IconButton, Toolbar } from '@material-ui/core'

import * as Type from 'constants/actionTypes'
import LOGO from 'assets/logo-full-white-177-65.png'
import { ROUTE_PROFILE } from 'routes/paths'

import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  sideNavOpened: state.common.sideNavOpened
})

const mapDispatchToProps = dispatch => ({
  toggleCollapsedSideNav: opened => dispatch({ type: Type.TOGGLE_COLLAPSE_SIDE_NAV, opened }),
  logout: () => dispatch({ type: Type.LOGOUT })
})

class Header extends React.Component {
  constructor() {
    super()
    this.state = {
      account: false
    }
  }

  onAccountSelect = () => {
    this.setState({
      account: !this.state.account
    })
  }

  onToggleCollapsedNav = e => {
    const val = !this.props.sideNavOpened
    this.props.toggleCollapsedSideNav(val)
  }

  render() {
    return (
      <AppBar className="app-main-header">
        <Toolbar className="app-toolbar" disableGutters={false}>
          <IconButton
            className="jr-menu-icon d-block d-xl-none"
            aria-label="Menu"
            onClick={this.onToggleCollapsedNav.bind(this)}>
            <span className="menu-icon" />
          </IconButton>

          <Link className="app-logo" to="/">
            <img src={LOGO} alt="Tenopy" title="Tenopy" />
          </Link>

          <ul className="header-notifications list-inline ml-auto">
            <li className="list-inline-item">
              <Dropdown className="quick-menu" isOpen={this.state.account} toggle={this.onAccountSelect.bind(this)}>
                <DropdownToggle className="d-inline-block" tag="span" data-toggle="dropdown">
                  <IconButton className="icon-btn size-30">
                    <i className="zmdi zmdi-account-box" />
                  </IconButton>
                </DropdownToggle>

                <DropdownMenu right>
                  <div className="messages-list">
                    <ul className="list-unstyled">
                      <li
                        className="pointer"
                        onClick={() => {
                          this.props.history.push(ROUTE_PROFILE)
                        }}>
                        <div className="d-flex align-items-center">
                          <i className="zmdi zmdi-account" />
                          <h4 className="mb-0 ml-2">Profile</h4>
                        </div>
                      </li>
                      <li className="pointer" onClick={this.props.logout}>
                        <div className="d-flex align-items-center">
                          <i className="zmdi zmdi-lock" />
                          <h4 className="mb-0 ml-2">Logout</h4>
                        </div>
                      </li>
                    </ul>
                  </div>
                </DropdownMenu>
              </Dropdown>
            </li>
          </ul>
        </Toolbar>
      </AppBar>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
