import * as Type from 'constants/actionTypes'

const INIT_STATE = {
  alertMessage: '',
  showMessage: false,
  loader: true,
  noContentFoundMessage: 'No class found matching that name',
  selectedSectionId: 1, // for side filter
  drawerState: false,
  searchClass: '',
  filterOption: 'All classes',
  classList: [],
  selectedClass: null,
  selectedClassCount: 0,
  addClassState: false
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Type.GET_CLASSES:
    case Type.LOAD_BY_PAGE:
      return {
        ...state,
        loader: true,
        isLoadingClasses: true,
        classList: []
      }

    case Type.GET_CLASSES_SUCCESS:
      return {
        ...state,
        loader: false,
        isLoadingClasses: false,
        classList: action.payload.data,
        classCount: action.payload.count,
        currentPage: 0
      }

    case Type.LOAD_BY_PAGE_SUCCESS:
      return {
        ...state,
        loader: false,
        isLoadingClasses: false,
        classList: action.payload.data,
        classCount: action.payload.count,
        currentPage: action.page
      }

    case Type.GET_CLASSES_FAILED:
    case Type.LOAD_BY_PAGE_FAILED:
      return {
        ...state,
        loader: false,
        isLoadingClasses: false
      }

    case Type.CREATE_CLASS:
      return {
        ...state,
        classCreated: false,
        creatingClass: true
      }
    case Type.CREATE_CLASS_SUCCESS:
      return {
        ...state,
        classCreated: true,
        creatingClass: false
      }
    case Type.CREATE_CLASS_FAILED:
      return {
        ...state,
        classCreated: false,
        creatingClass: false
      }
    case Type.EDIT_CLASS:
      return {
        ...state,
        classEdited: false,
        editingClass: true
      }
    case Type.EDIT_CLASS_SUCCESS:
      return {
        ...state,
        classEdited: true,
        editingClass: false
      }
    case Type.EDIT_CLASS_FAILED:
      return {
        ...state,
        classEdited: false,
        editingClass: false
      }
    default:
      return state
  }
}
