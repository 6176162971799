import React from 'react'
import HomeworkAssignmentStatWidget from './widget-homework-assignment'
import StudentBirthdayWidget from './widget-student-birthday'

class OpsDashboard extends React.Component {
  render() {
    return (
      <div className="app-wrapper">
        <div className="row">
          <div className="col-7 mb-3">
            <HomeworkAssignmentStatWidget />
          </div>
          <div className="col-7 mb-3">
            <StudentBirthdayWidget />
          </div>
        </div>
      </div>
    )
  }
}

export default OpsDashboard
