import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

class DialogAttendeeList extends React.Component {
  render() {
    let { isOpen, attendeeList } = this.props

    return (
      <Dialog maxWidth="md" fullWidth={true} open={isOpen} onClose={this.props.toggleDialog(null)}>
        <DialogTitle>Attendee List</DialogTitle>
        <DialogContent>
          <table className="table table-responsive-md">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone</th>
              </tr>
            </thead>
            <tbody>
              {attendeeList &&
                attendeeList.length > 0 &&
                attendeeList.map(p => (
                  <tr key={p._id}>
                    <td>{p.name}</td>
                    <td>{p.email}</td>
                    <td>
                      {p.phone_country_code} - {p.phone}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </DialogContent>
      </Dialog>
    )
  }
}

export default DialogAttendeeList
