import React from 'react'
import { connect } from 'react-redux'
import agent from '../../agent'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Select from '@material-ui/core/Select'
import Paper from '@material-ui/core/Paper'
import { GET_EVENT_LIST, DELETE_EVENT } from '../../constants/actionTypes'
import moment from 'moment'
import DialogAttendeeList from './DialogAttendeeList'
import DialogCreateModifyEvent from 'pages/tls/DialogCreateModifyEvent'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Pagination from 'components/Pagination/Pagination'
import DialogAlert from 'components/dialog/alert'
import DialogConfirmDelete from 'components/dialog/confirm'
import DialogIntegrationDetails from './DialogIntegrationDetails'

import { ROUTE_TLS } from 'routes/paths'
import permissions, { checkActionPermitted } from 'permissions'

const mapStateToProps = state => ({
  eventList: state.event.eventList,
  eventCount: state.event.eventCount,
  eventPage: state.event.eventPage,
  loadingEventList: state.event.loadingEventList,
  deletingEvent: state.event.deletingEvent,
  deleteError: state.event.deleteError,
  userRole: state.auth.userRole
})

const mapDispatchToProps = dispatch => ({
  getEvents: (filter, perPage, page) =>
    dispatch({ type: GET_EVENT_LIST, payload: agent.Event.getList(filter, perPage, page), page }),
  deleteEvent: id => dispatch({ type: DELETE_EVENT, payload: agent.Event.deleteEvent(id) })
})

class EventManagement extends React.Component {
  state = {
    selectedEvent: null,
    isAttendeeListDialogOpen: false,
    perPage: 20,
    page: 1,
    isCreateModifyDialogOpen: false,
    periodFilter: 'all',
    isAlertDialogOpen: false,
    alert: { title: '', content: '' },
    isConfirmDeleteDialogOpen: false,
    deleteConfirmation: { title: '', content: '' },
    isIntegrationDetailsDialogOpen: false,
    integratioDetails: '',
    canCreate: false,
    canUpdate: false,
    canDelete: false
  }

  componentDidMount() {
    this.getEvents(1)

    const { userRole } = this.props
    this.setState({
      canCreate: checkActionPermitted(permissions, userRole, ROUTE_TLS, 'canCreate'),
      canUpdate: checkActionPermitted(permissions, userRole, ROUTE_TLS, 'canUpdate'),
      canDelete: checkActionPermitted(permissions, userRole, ROUTE_TLS, 'canDelete')
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.deletingEvent && !this.props.deletingEvent) {
      this.setState({ selectedEvent: null })
      this.getEvents()
    }
    if (prevState.page !== this.state.page) {
      this.getEvents()
    }
    if (prevState.periodFilter !== this.state.periodFilter) {
      this.getEvents()
    }
  }

  getEvents = setPage => {
    const { perPage, page } = this.state

    if (!!setPage && page !== setPage) {
      this.setState({ page: setPage })
    } else {
      let filter = {}

      switch (this.state.periodFilter) {
        case 'past':
          filter.scheduled_on = {
            $lt: moment().format()
          }
          break
        case 'upcoming':
          filter.scheduled_on = {
            $gte: moment().format()
          }
          break
        case 'all':
        default:
          break
      }

      this.props.getEvents(filter, perPage, page)
    }
  }

  toggleAttendeeListDialog = selectedEvent => () => {
    this.setState({
      selectedEvent,
      isAttendeeListDialogOpen: !this.state.isAttendeeListDialogOpen
    })
  }

  toggleCreateModifyDialog = selectedEvent => () => {
    this.setState({
      selectedEvent,
      isCreateModifyDialogOpen: !this.state.isCreateModifyDialogOpen
    })
  }

  preDelete = eventData => () => {
    if (eventData.attendee.length > 0) {
      this.setState({
        isAlertDialogOpen: true,
        alert: {
          title: 'Unable to delete',
          content:
            "You're not allowed to delete TLS session with existing attendees. Please contact tech team for further assistance."
        }
      })
    } else {
      this.setState({
        selectedEvent: eventData,
        isConfirmDeleteDialogOpen: true,
        deleteConfirmation: {
          title: 'Attempt to delete',
          content: `You're about to delete <span class="font-weight-semibold">${eventData.name}</span> tenobite session.`
        }
      })
    }
  }

  delete = () => {
    let { selectedEvent } = this.state
    this.props.deleteEvent(selectedEvent._id)
    this.setState({ isConfirmDeleteDialogOpen: false })
  }

  onPeriodFilterChange = evt => {
    this.setState({ periodFilter: evt.target.value })
  }

  onPageChange = page => {
    this.setState({ page })
  }

  toggleAlertDialog = () => {
    this.setState({
      isAlertDialogOpen: !this.state.isAlertDialogOpen
    })
  }

  toggleConfirmDeleteDialog = () => {
    let { isConfirmDeleteDialogOpen } = this.state
    if (isConfirmDeleteDialogOpen) {
      this.setState({ selectedEvent: null })
    }
    this.setState({
      isConfirmDeleteDialogOpen: !isConfirmDeleteDialogOpen
    })
  }

  toggleIntegrationDetailsDialog = integrationDetails => () => {
    this.setState({
      isIntegrationDetailsDialogOpen: !this.state.isIntegrationDetailsDialogOpen,
      integrationDetails
    })
  }

  render() {
    let { loadingEventList, deletingEvent, eventList, eventCount } = this.props
    let {
      selectedEvent,
      isAttendeeListDialogOpen,
      periodFilter,
      perPage,
      page,
      isCreateModifyDialogOpen,
      isAlertDialogOpen,
      alert,
      isConfirmDeleteDialogOpen,
      deleteConfirmation,
      isIntegrationDetailsDialogOpen,
      integrationDetails,
      canCreate,
      canUpdate,
      canDelete
    } = this.state

    return (
      <div className="app-wrapper">
        <h1>TLS List</h1>
        <div className="row">
          <div className="col">
            <FormControl className="mb-3" style={{ width: 200 }}>
              <InputLabel>Filter</InputLabel>
              <Select value={periodFilter} onChange={this.onPeriodFilterChange}>
                <MenuItem value={'all'}>All</MenuItem>
                <MenuItem value={'past'}>Past</MenuItem>
                <MenuItem value={'upcoming'}>Upcoming</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-auto">
            {canCreate && (
              <Button
                className="mb-3"
                variant={'contained'}
                color={'primary'}
                onClick={this.toggleCreateModifyDialog()}>
                Add new event
              </Button>
            )}
          </div>
        </div>
        {loadingEventList && (
          <div className="my-5 text-center">
            <CircularProgress color={'primary'} size={40} />
          </div>
        )}

        {eventList.count > 0 && (
          <>
            <Pagination
              itemCount={eventCount ? eventCount : 0}
              onChangePage={this.onPageChange}
              pageSize={perPage}
              currentPage={page}
              className={'mb-3'}
            />
            <Paper elevation={2}>
              <table className="table table-responsive-md">
                <thead>
                  <tr>
                    <th scope="col">Details</th>
                    <th scope="col">Hosting Tutor</th>
                    <th scope="col">Scheduled On</th>
                    <th scope="col">Attendees</th>
                    <th scope="col" style={{ width: 200 }}></th>
                  </tr>
                </thead>
                <tbody>
                  {eventList.data.map(event => {
                    let deleteInProgress = deletingEvent && selectedEvent._id === event._id

                    return (
                      <tr key={event._id}>
                        <td>
                          <span className="text-muted">Title:</span> {event.name}
                          <br />
                          {event.meta?.series?.name && (
                            <>
                              <span className="text-muted">Series:</span> {event.meta.series.name}, EP{' '}
                              {event.meta.series.order}
                              <br />
                            </>
                          )}
                          {event.meta?.keywords && (
                            <>
                              <span className="text-muted">Keywords:</span> {event.meta.keywords.join(', ')}
                            </>
                          )}
                        </td>
                        <td>{event.host_by ? event.host_by.name : 'N/A'}</td>
                        <td>{moment(event.scheduled_on).format('lll')}</td>
                        <td>
                          <span className="link" onClick={this.toggleAttendeeListDialog(event)}>
                            {event.attendee.length} registered
                          </span>
                        </td>
                        <td style={{ whiteSpace: 'nowrap' }}>
                          <Button
                            variant="outlined"
                            color="primary"
                            className="mr-2"
                            size="small"
                            onClick={this.toggleIntegrationDetailsDialog(event?.platform_integration)}>
                            Zoom
                          </Button>
                          {canUpdate && (
                            <Button
                              variant="outlined"
                              color="primary"
                              className="mr-2"
                              size="small"
                              onClick={this.toggleCreateModifyDialog(event)}>
                              Update
                            </Button>
                          )}
                          {canDelete && (
                            <Button
                              variant="contained"
                              className={deleteInProgress ? 'bg-light text-dark' : 'bg-danger text-white'}
                              size="small"
                              onClick={this.preDelete(event)}
                              disabled={deleteInProgress}>
                              Delete
                              {deleteInProgress && <CircularProgress className="ml-2" color="primary" size={15} />}
                            </Button>
                          )}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </Paper>
            <Pagination
              itemCount={eventCount ? eventCount : 0}
              onChangePage={this.onPageChange}
              pageSize={perPage}
              currentPage={page}
              className="mt-3"
            />
          </>
        )}

        <DialogCreateModifyEvent
          isOpen={isCreateModifyDialogOpen}
          toggle={this.toggleCreateModifyDialog()}
          postSave={this.getEvents}
          eventData={selectedEvent}
        />

        <DialogAttendeeList
          isOpen={isAttendeeListDialogOpen}
          toggleDialog={this.toggleAttendeeListDialog}
          attendeeList={selectedEvent ? selectedEvent.attendee : []}
        />

        <DialogAlert
          isOpen={isAlertDialogOpen}
          toggle={this.toggleAlertDialog}
          title={alert.title}
          content={alert.content}
        />

        <DialogConfirmDelete
          isOpen={isConfirmDeleteDialogOpen}
          toggle={this.toggleConfirmDeleteDialog}
          proceed={this.delete}
          title={deleteConfirmation.title}
          content={deleteConfirmation.content}
        />

        <DialogIntegrationDetails
          isOpen={isIntegrationDetailsDialogOpen}
          toggle={this.toggleIntegrationDetailsDialog}
          details={integrationDetails}
        />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventManagement)
