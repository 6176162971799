import React from 'react'
import { connect } from 'react-redux'
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import {
  Button,
  CircularProgress,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core'
import _ from 'lodash'
import moment from 'moment'

import agent from 'agent'
import { GET_SALES_STAT } from 'constants/actionTypes'

const mapStateToProps = state => ({
  loading: state.report.loadingSalesStat,
  salesStat: state.report.salesStat
})

const mapDispatchToProps = dispatch => ({
  getSalesStat: (startDate, endDate, excludeOxkids) =>
    dispatch({ type: GET_SALES_STAT, payload: agent.Report.getSalesStat(startDate, endDate, excludeOxkids) })
})

class SalesReport extends React.Component {
  state = {
    selectedMonth: moment().format('MM'),
    selectedYear: moment().format('YYYY'),
    excludeOxkids: true,
    chartData: [],
    tableData: [],
    tableTotalRow: {},
    tableHeadConfig: [],
    agentTotal: {},
    totalSales: 0,
    totalRenewal: 0,
    totalVideo: 0,
    grandTotal: 0
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading && !this.props.loading) {
      const { salesStat } = this.props
      if (!salesStat) return

      const { selectedMonth, selectedYear, tableData, chartData, tableHeadConfig } = this.state
      const allAgentName = _.uniq(_.map(salesStat, 'agent.name'))
      const initTableFields = {}
      let tableTotalRow = {}
      let agentTotal = {}
      let totalSales = 0,
        totalRenewal = 0,
        totalVideo = 0,
        grandTotal = 0
      for (let i = 0; i < allAgentName.length; i++) {
        const agentName = allAgentName[i] ? allAgentName[i] : 'N/A'
        initTableFields[`${agentName}-sales`] = 0
        initTableFields[`${agentName}-renewal`] = 0
        tableData.push({
          name: agentName
        })
        agentTotal[agentName] = {
          total: 0,
          sales: 0,
          renewal: 0,
          video: 0
        }
      }
      tableData.push({
        name: 'Total'
      })

      // init data with day 1st
      const startDay = Number(moment(`${selectedYear}-${selectedMonth}-01T00:00:00Z`).startOf('month').format('D'))
      const endDay = Number(moment(`${selectedYear}-${selectedMonth}-01T00:00:00Z`).endOf('month').format('D'))
      for (let i = 0; i < endDay; i++) {
        let dayName = startDay + i
        chartData.push({
          name: dayName.toString(),
          sales: 0,
          renewal: 0,
          video: 0
        })
        for (let j = 0; j < tableData.length; j++) {
          tableData[j][`day-${dayName}-sales`] = 0
          tableData[j][`day-${dayName}-renewal`] = 0
          tableData[j][`day-${dayName}-video`] = 0
        }
        tableTotalRow[`day-${dayName}-total`] = 0
        tableHeadConfig.push(dayName.toString())
      }

      const totalIndex = _.findIndex(tableData, o => {
        return o.name === 'Total'
      })

      for (let i = 0; i < salesStat.length; i++) {
        const salesData = salesStat[i]
        const paymentDay = moment(salesData.payment.completed_on).format('D')
        const agentName = salesData.agent ? salesData.agent.name : 'N/A'

        agentTotal[agentName]['total'] += salesData.actual_price

        const chartIndex = _.findIndex(chartData, o => {
          return o.name === paymentDay
        })

        const tableIndex = _.findIndex(tableData, o => {
          return o.name === agentName
        })

        if (salesData.type === 'PAYMENT') {
          if (salesData.meta?.is_renewal) {
            chartData[chartIndex].renewal += salesData.actual_price
            tableData[tableIndex][`day-${paymentDay}-renewal`] += salesData.actual_price
            tableData[totalIndex][`day-${paymentDay}-renewal`] += salesData.actual_price
            totalRenewal += salesData.actual_price
            agentTotal[agentName]['renewal'] += salesData.actual_price
          } else {
            chartData[chartIndex].sales += salesData.actual_price
            tableData[tableIndex][`day-${paymentDay}-sales`] += salesData.actual_price
            tableData[totalIndex][`day-${paymentDay}-sales`] += salesData.actual_price
            totalSales += salesData.actual_price
            agentTotal[agentName]['sales'] += salesData.actual_price
          }
        } else {
          chartData[chartIndex].video += salesData.actual_price
          tableData[tableIndex][`day-${paymentDay}-video`] += salesData.actual_price
          tableData[totalIndex][`day-${paymentDay}-video`] += salesData.actual_price
          totalVideo += salesData.actual_price
          agentTotal[agentName]['video'] += salesData.actual_price
        }

        tableTotalRow[`day-${paymentDay}-total`] += salesData.actual_price
        grandTotal += salesData.actual_price
      }

      this.setState({
        chartData,
        tableData,
        tableHeadConfig,
        agentTotal,
        totalSales,
        totalRenewal,
        totalVideo,
        grandTotal,
        tableTotalRow
      })
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleCheckboxChange = e => {
    this.setState({ [e.target.name]: e.target.checked })
  }

  clickGenerate = () => {
    const { selectedMonth, selectedYear, excludeOxkids } = this.state
    const startDate = moment(`${selectedYear}-${selectedMonth}-01T00:00:00Z`).startOf('month').toISOString()
    const endDate = moment(`${selectedYear}-${selectedMonth}-01T00:00:00Z`).endOf('month').toISOString()
    this.props.getSalesStat(startDate, endDate, excludeOxkids ? 'Oxkids' : '')
    this.setState({
      chartData: [],
      tableData: [],
      tableTotalRow: {},
      tableHeadConfig: [],
      agentTotal: {},
      totalSales: 0,
      totalRenewal: 0,
      totalVideo: 0,
      grandTotal: 0
    })
  }

  render() {
    const { loading } = this.props
    const {
      selectedMonth,
      selectedYear,
      excludeOxkids,
      chartData,
      tableData,
      tableHeadConfig,
      tableTotalRow,
      agentTotal,
      totalSales,
      totalRenewal,
      totalVideo,
      grandTotal
    } = this.state

    return (
      <div className="app-wrapper">
        <Paper className="p-3 mb-3" elevation={3}>
          <div className="d-flex align-items-center">
            {/* lazy implementation, copy from tutor payment */}
            <FormControl>
              <InputLabel htmlFor="month">Month</InputLabel>
              <Select
                name={'selectedMonth'}
                value={selectedMonth}
                onChange={this.handleChange}
                style={{ minWidth: '200px' }}>
                <MenuItem value="01">January</MenuItem>
                <MenuItem value="02">February</MenuItem>
                <MenuItem value="03">March</MenuItem>
                <MenuItem value="04">April</MenuItem>
                <MenuItem value="05">May</MenuItem>
                <MenuItem value="06">June</MenuItem>
                <MenuItem value="07">July</MenuItem>
                <MenuItem value="08">August</MenuItem>
                <MenuItem value="09">September</MenuItem>
                <MenuItem value="10">October</MenuItem>
                <MenuItem value="11">November</MenuItem>
                <MenuItem value="12">December</MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="month">Year</InputLabel>
              <Select
                name="selectedYear"
                value={selectedYear}
                onChange={this.handleChange}
                style={{ minWidth: '120px' }}>
                <MenuItem value="2020">2020</MenuItem>
                <MenuItem value="2021">2021</MenuItem>
                <MenuItem value="2022">2022</MenuItem>
                <MenuItem value="2023">2023</MenuItem>
              </Select>
            </FormControl>

            <FormControlLabel
              control={
                <Checkbox
                  name="excludeOxkids"
                  checked={excludeOxkids}
                  onChange={this.handleCheckboxChange}
                  color="primary"
                />
              }
              label="Exclude Oxkids"
              className="ml-2"
            />
            <Button variant="contained" color="primary" onClick={this.clickGenerate} className="ml-2">
              Generate
            </Button>
          </div>
        </Paper>

        {loading && (
          <div className="text-center my-5">
            <CircularProgress size={40} color="primary" className="text-center" />
          </div>
        )}

        {chartData.length > 0 && (
          <>
            <Paper className="p-3 mb-3" elevation={3}>
              <div className="row">
                <div className="col-auto">
                  <h1 className="mb-0">{moment(`${selectedYear}-${selectedMonth}-01T00:00:00Z`).format('MMM YYYY')}</h1>
                </div>
                <div className="col text-large text-right">
                  <div className="mb-2">
                    <span className="text-muted">Total Sales:</span> ${totalSales.toFixed(2)}
                  </div>
                  <div className="mb-2">
                    <span className="text-muted">Total Renewal:</span> ${totalRenewal.toFixed(2)}
                  </div>
                  <div className="mb-2">
                    <span className="text-muted">Total Video:</span> ${totalVideo.toFixed(2)}
                  </div>
                  <div className="mb-2">
                    <span className="text-muted">Grand Total:</span> ${grandTotal.toFixed(2)}
                  </div>
                </div>
              </div>
            </Paper>

            <div className="mb-5">
              <h2 className="text-center">Overall Sales</h2>
              <ResponsiveContainer width="100%" height={400}>
                <BarChart data={chartData} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                  <XAxis dataKey="name" />
                  <YAxis />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip labelStyle={{ color: 'black' }} itemStyle={{ color: 'black' }} cursor={false} />
                  <Legend />
                  <defs>
                    <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#1ABBDE" stopOpacity={1} />
                      <stop offset="95%" stopColor="#09BCA7" stopOpacity={1} />
                    </linearGradient>
                  </defs>
                  <defs>
                    <linearGradient id="color2" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#6200EE" stopOpacity={1} />
                      <stop offset="95%" stopColor="#B819D2" stopOpacity={1} />
                    </linearGradient>
                  </defs>
                  <defs>
                    <linearGradient id="color3" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#FFC107" stopOpacity={1} />
                      <stop offset="95%" stopColor="#FF9800" stopOpacity={1} />
                    </linearGradient>
                  </defs>
                  <Bar dataKey="sales" stackId="a" fill="url(#color1)" />
                  <Bar dataKey="renewal" stackId="a" fill="url(#color2)" />
                  <Bar dataKey="video" stackId="a" fill="url(#color3)" />
                </BarChart>
              </ResponsiveContainer>
            </div>

            <h2 className="text-center">Agents Breakdown</h2>
            <Table className="mb-5">
              <TableHead>
                <TableRow>
                  <TableCell>Agent</TableCell>
                  <TableCell>Total</TableCell>
                  <TableCell>Sales</TableCell>
                  <TableCell>Renewal</TableCell>
                  <TableCell>Video</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(agentTotal).map(([key, value]) => (
                  <TableRow key={key}>
                    <TableCell>{key}</TableCell>
                    <TableCell>${value.total.toFixed(2)}</TableCell>
                    <TableCell>${value.sales.toFixed(2)}</TableCell>
                    <TableCell>${value.renewal.toFixed(2)}</TableCell>
                    <TableCell>${value.video.toFixed(2)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <h2 className="text-center">Sales Breakdown</h2>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell rowSpan={2}>Agent Name</TableCell>
                  {tableHeadConfig.map((tHead, index) => (
                    <TableCell colSpan={3} key={index} align="center">
                      Day {tHead}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  {tableHeadConfig.map((tHead, index) => (
                    <React.Fragment key={index}>
                      <TableCell>Sales</TableCell>
                      <TableCell>Renewal</TableCell>
                      <TableCell>Video</TableCell>
                    </React.Fragment>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((rowData, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <span className="font-weight-semibold">{rowData.name}</span>
                    </TableCell>
                    {tableHeadConfig.map((tHead, index) => (
                      <React.Fragment key={index}>
                        <TableCell>{rowData[`day-${tHead}-sales`].toFixed(2)}</TableCell>
                        <TableCell>{rowData[`day-${tHead}-renewal`].toFixed(2)}</TableCell>
                        <TableCell>{rowData[`day-${tHead}-video`].toFixed(2)}</TableCell>
                      </React.Fragment>
                    ))}
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell>&nbsp;</TableCell>
                  {tableHeadConfig.map((tHead, index) => (
                    <TableCell key={index} colSpan={3} align="center">
                      {tableTotalRow[`day-${tHead}-total`]}
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </>
        )}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesReport)
