import React from 'react'

class Home extends React.Component {
  render() {
    return (
      <main role="main" className="col-sm-9 ml-sm-auto col-md-10 pt-3">
        <h1>Dashboard</h1>
        <p>Coming soon</p>
      </main>
    )
  }
}

export default Home
