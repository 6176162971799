import React from 'react'
import Proptypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@material-ui/core'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const Alert = props => {
  return (
    <Dialog open={props.isOpen} onClose={props.toggle} TransitionComponent={Transition}>
      <DialogTitle>
        <span style={{ fontSize: '24px' }}>{props.title}</span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <span dangerouslySetInnerHTML={{ __html: props.content }} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.toggle} color="primary" variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

Alert.propTypes = {
  isOpen: Proptypes.bool.isRequired,
  toggle: Proptypes.func.isRequired,
  title: Proptypes.string.isRequired,
  content: Proptypes.string.isRequired
}

export default Alert
