import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import permissions from 'permissions'

import Home from 'pages/Home'
import DiscountCodeForm from 'pages/DiscountCode/DiscountCodeForm'
import DiscountCode from 'pages/DiscountCode/DiscountCode'
import UserManagement from 'pages/UserManagement'
import LessonPage from 'pages/CourseManagement/LessonManagement/LessonPage'
import LessonManagement from 'pages/CourseManagement/LessonManagement'
import CourseManagement from 'pages/CourseManagement'
import TutorPayments from 'pages/TutorPayments'
import Orders from 'pages/Orders'
import Feedback from 'pages/Feedback/Feedback'
import Report from 'pages/report'
import LessonManagement2 from 'pages/LessonManagement'
import ZoomLessonDetail from 'pages/LessonManagement/details/zoom'
import TenopyClassroomLessonDetail from 'pages/LessonManagement/details/tenopy-classroom'
import TLSManagement from 'pages/tls'
import NumberDashboard from 'pages/dashboard'
import TicketsStatPage from 'pages/ticket/stat'
import TrialTicketsPage from 'pages/ticket/trial'
import RenewalTicketsPage from 'pages/ticket/renewal'
import OpsDashboard from 'pages/ops'
import ProfilePage from 'pages/profile'
import QuestionBankPage from 'pages/question-bank'

import {
  ROUTE_ROOT,
  ROUTE_DISCOUNT_CODE_FORM,
  ROUTE_DISCOUNT_CODE,
  ROUTE_USER_MANAGEMENT,
  ROUTE_LESSON_PAGE,
  ROUTE_LESSONS,
  ROUTE_COURSES,
  ROUTE_TUTOR_PAYMENTS,
  ROUTE_ORDERS,
  ROUTE_FEEDBACK,
  ROUTE_REPORT,
  ROUTE_LESSON_MANAGEMENT,
  ROUTE_LESSON_DETAIL_ZOOM,
  ROUTE_LESSON_DETAIL_TENOPY_CLASSROOM,
  ROUTE_TLS,
  ROUTE_NUMBER_DASHBOARD,
  ROUTE_TICKETS_STAT,
  ROUTE_TICKETS_TRIAL,
  ROUTE_TICKETS_RENEWAL,
  ROUTE_OPS_DASHBOARD,
  ROUTE_PROFILE,
  ROUTE_QUESTION_BANK
} from 'routes/paths'

const mapStateToProps = state => ({
  userRole: state.auth.userRole
})

const GatedRoute = ({ userRole, path, component }) => {
  if (Object.keys(permissions).includes(userRole)) {
    const { allowedPaths } = permissions[userRole]
    if (Object.keys(allowedPaths).includes(path)) {
      return <Route path={path} component={component} />
    } else {
      return <Redirect to={ROUTE_ROOT} />
    }
  }
  return <Route path={path} component={component} />
}

class Routes extends React.Component {
  render() {
    const userRole = this.props.userRole

    return (
      <Switch>
        <Route exact path={ROUTE_ROOT} component={Home} />
        <GatedRoute userRole={userRole} path={ROUTE_DISCOUNT_CODE_FORM} component={DiscountCodeForm} />
        <GatedRoute userRole={userRole} path={ROUTE_DISCOUNT_CODE} component={DiscountCode} />
        <GatedRoute userRole={userRole} path={ROUTE_USER_MANAGEMENT} component={UserManagement} />
        <GatedRoute userRole={userRole} path={ROUTE_LESSON_PAGE} component={LessonPage} />
        <GatedRoute userRole={userRole} path={ROUTE_LESSONS} component={LessonManagement} />
        <GatedRoute userRole={userRole} path={ROUTE_COURSES} component={CourseManagement} />
        <GatedRoute userRole={userRole} path={ROUTE_TUTOR_PAYMENTS} component={TutorPayments} />
        <GatedRoute userRole={userRole} path={ROUTE_ORDERS} component={Orders} />
        <GatedRoute userRole={userRole} path={ROUTE_FEEDBACK} component={Feedback} />
        <GatedRoute userRole={userRole} path={ROUTE_REPORT} component={Report} />
        <GatedRoute userRole={userRole} path={ROUTE_LESSON_DETAIL_ZOOM} component={ZoomLessonDetail} />
        <GatedRoute
          userRole={userRole}
          path={ROUTE_LESSON_DETAIL_TENOPY_CLASSROOM}
          component={TenopyClassroomLessonDetail}
        />
        <GatedRoute userRole={userRole} path={ROUTE_LESSON_MANAGEMENT} component={LessonManagement2} />
        <GatedRoute userRole={userRole} path={ROUTE_TLS} component={TLSManagement} />
        <GatedRoute userRole={userRole} path={ROUTE_NUMBER_DASHBOARD} component={NumberDashboard} />
        <GatedRoute userRole={userRole} path={ROUTE_TICKETS_STAT} component={TicketsStatPage} />
        <GatedRoute userRole={userRole} path={ROUTE_TICKETS_TRIAL} component={TrialTicketsPage} />
        <GatedRoute userRole={userRole} path={ROUTE_TICKETS_RENEWAL} component={RenewalTicketsPage} />
        <GatedRoute userRole={userRole} path={ROUTE_OPS_DASHBOARD} component={OpsDashboard} />
        <GatedRoute userRole={userRole} path={ROUTE_QUESTION_BANK} component={QuestionBankPage} />
        <Route path={ROUTE_PROFILE} component={ProfilePage} />
        <Route path={'*'} component={Home} />
      </Switch>
    )
  }
}

export default connect(mapStateToProps)(Routes)
