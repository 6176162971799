import React from 'react'
import { connect } from 'react-redux'

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField
} from '@material-ui/core'
import Proptypes from 'prop-types'

import agent from 'agent'
import { CANCEL_ORDER } from 'constants/actionTypes'

const mapStateToProps = state => ({
  cancellingOrder: state.orders.cancellingOrder
})

const mapDispatchToProps = dispatch => ({
  cancelOrder: (orderId, reqBody) =>
    dispatch({ type: CANCEL_ORDER, payload: agent.Orders.cancelOrder(orderId, reqBody), orderId })
})

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

class DialogCancelOrder extends React.Component {
  state = {
    remark: '',
    alertMessage: ''
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen && !this.props.isOpen) {
      this.setState({
        remark: '',
        alertMessage: ''
      })
    }

    if (prevProps.cancellingOrder && !this.props.cancellingOrder) {
      this.props.close()
    }
  }

  handleChange = e => {
    this.setState({
      alertMessage: '',
      [e.target.name]: e.target.value
    })
  }

  clickCancelButton = () => {
    const { orderData } = this.props
    const { remark } = this.state

    if (!remark) {
      this.setState({ alertMessage: 'Please enter a reason for canceling the order' })
      return
    }

    this.props.cancelOrder(orderData._id, { remark })
  }

  render() {
    const { isOpen, close, orderData, cancellingOrder } = this.props
    const { remark, alertMessage } = this.state

    return (
      <Dialog open={isOpen} onClose={close} TransitionComponent={Transition} maxWidth="sm" fullWidth>
        <DialogTitle>
          <span style={{ fontSize: '24px' }}>Cancel Order</span>
        </DialogTitle>
        <DialogContent>
          {orderData && (
            <div className="row justify-content-center">
              <div className="col-12 mb-3">
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>Type</TableCell>
                      <TableCell>:</TableCell>
                      <TableCell>{orderData.type}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>User</TableCell>
                      <TableCell>:</TableCell>
                      <TableCell>
                        {orderData.created_by.name} ({orderData.created_by.email})
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Class</TableCell>
                      <TableCell>:</TableCell>
                      <TableCell>{orderData.detail.class_obj.name}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
              <div className="col-12">
                <FormControl className="w-100 mb-2">
                  <TextField
                    label="Remark"
                    name="remark"
                    onChange={this.handleChange}
                    value={remark}
                    variant="outlined"
                    multiline
                    rows={4}
                    error={!!alertMessage}
                    helperText={alertMessage}
                  />
                </FormControl>
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="default" variant="text">
            Close
          </Button>
          <Button
            variant="contained"
            className="text-white bg-danger"
            onClick={this.clickCancelButton}
            disabled={cancellingOrder}>
            Confirm Cancel {cancellingOrder && <CircularProgress size={15} className="text-white ml-2" />}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

DialogCancelOrder.propTypes = {
  isOpen: Proptypes.bool.isRequired,
  close: Proptypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogCancelOrder)
