import React from 'react'
import Proptypes from 'prop-types'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'
import _ from 'lodash'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const DialogTrialStudentDetails = props => {
  const { isOpen, close, classTitle, studentList } = props
  const sortedStudentList = _.orderBy(studentList, 'class_purchased', 'desc')

  return (
    <Dialog open={isOpen} onClose={close} TransitionComponent={Transition} maxWidth="lg">
      <DialogTitle>
        <span style={{ fontSize: '24px' }}>{classTitle}</span>
      </DialogTitle>
      <DialogContent>
        <Table>
          <TableHead style={{ backgroundColor: 'rgba(60, 72, 88, 0.1)' }}>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Attended</TableCell>
              <TableCell>Purchased</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedStudentList.map((student, index) => (
              <TableRow key={index}>
                <TableCell>{student.name}</TableCell>
                <TableCell>{student.email}</TableCell>
                <TableCell>{student.attendance ? 'Yes' : 'No'}</TableCell>
                <TableCell>{student.class_purchased ? 'Yes' : 'No'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary" variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DialogTrialStudentDetails.propTypes = {
  isOpen: Proptypes.bool.isRequired,
  close: Proptypes.func.isRequired,
  studentList: Proptypes.array
}

export default DialogTrialStudentDetails
