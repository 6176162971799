import React from 'react'
import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import { CopyToClipboard } from 'react-copy-to-clipboard' // NOTE: change to use copy to clipboard package bcz react clipboard is giving unknown problem

class DialogIntegrationDetails extends React.Component {
  state = {
    copied: false
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen & !this.props.isOpen) {
      this.setState({ copied: false })
    }
  }

  render() {
    let { isOpen, details } = this.props
    let { copied } = this.state

    return (
      <Dialog open={isOpen} onClose={this.props.toggle(null)}>
        <DialogTitle>Zoom Integration</DialogTitle>
        <DialogContent>
          {details?.id ? (
            <table className="table table-responsive-md">
              <tbody>
                <tr>
                  <td>Meeting ID:</td>
                  <td>{details.id}</td>
                </tr>
                <tr>
                  <td>Registration URL:</td>
                  <td>
                    {details.registration_url}
                    <br />
                    <CopyToClipboard text={details.registration_url} onCopy={() => this.setState({ copied: true })}>
                      <Button color="primary" variant="outlined" size="small" className="mt-2">
                        {copied ? 'Copied!' : 'Copy'}
                      </Button>
                    </CopyToClipboard>
                  </td>
                </tr>
                <tr>
                  <td>Host:</td>
                  <td>{details.host_email}</td>
                </tr>
              </tbody>
            </table>
          ) : (
            <p>No integration details found.</p>
          )}
        </DialogContent>
      </Dialog>
    )
  }
}

export default DialogIntegrationDetails
